import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addLiveExam } from "../../../Network/ExamApi";

const AddExam = ({ exam, FetchExams, setExam, setViewType }) => {
	//redux dispatch
	const dispatch = useDispatch();

	const AllStudents = useSelector((state) => state.cred.creds)
		.filter(
			(student) =>
				student.deleted == "0" &&
				student.student == 1 &&
				(parseInt(student.level) === parseInt(exam.level) ||
					exam.level === "") &&
				(parseInt(student.period) === parseInt(exam.period) ||
					exam.period === "") &&
				(parseInt(student.faculty) === parseInt(exam.faculty) ||
					exam.faculty === "") &&
				(parseInt(student.year) === parseInt(exam.year) || exam.year === "")
		)
		.sort((a, b) => parseInt(b.linker) - parseInt(a.linker))
		.map((student) => ({ ...student, score: "", comment: "", grade: "" }));

	const [Search, setSearch] = useState("");
	const online = useSelector((state) => state.online.online);
	const User = useSelector((state) => state.auth.user);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const [Students, setStudents] = useState(AllStudents);
	const [Loading, setLoading] = useState(false);

	const UpdateInput = (value, index, input) => {
		let students = [...Students];
		students[index][input] = value;
		setStudents([...students]);
	};

	const SearchStudents = (search) => {
		setSearch(search);
		let students = [...AllStudents].filter(
			(student) =>
				student.studentReg.toLowerCase().search(search.toLowerCase()) > -1 ||
				student.firstname.toLowerCase().search(search.toLowerCase()) > -1 ||
				student.lastname.toLowerCase().search(search.toLowerCase()) > -1 ||
				student.surname.toLowerCase().search(search.toLowerCase()) > -1 ||
				student.email.toLowerCase().search(search.toLowerCase()) > -1 ||
				student.contact.toLowerCase().search(search.toLowerCase()) > -1 ||
				search === ""
		);
		setStudents(students);
	};

	const SaveMarks = async () => {
		setLoading(true);
		let linker = Date.now();
		await Students.map(async (student, index) => {
			await addLiveExam(
				{
					activityLinker: exam.activity,
					subjectLinker: exam.subject,
					periodLinker: exam.period,
					levelLinker: exam.level,
					facultyLinker: exam.faculty,
					yearLinker: exam.year,
					studentLinker: student.linker,
					score: student.score,
					maxScore: exam.maxScore,
					comment: student.comment,
					grade: student.grade,
					linker: linker + index,
					instLinker: ActiveCred.linker,
					credLinker: ActiveCred.linker,
					live: 1,
					status: 0,
					trace: linker,
					deleted: 0,
				},
				ActiveCred.instLinker,
				User.token,
				dispatch
			);
		});
		await FetchExams(User.token, ActiveCred.instLinker, dispatch, online);
		setViewType("view");
		setExam({
			year: "",
			level: "",
			faculty: "",
			period: "",
			subject: "",
			activity: "",
			maxScore: "",
		});
		setLoading(false);
	};

	return (
		<div>
			{" "}
			<table className="table">
				<thead>
					<tr>
						<th colSpan={2}>
							<input
								placeholder="search student"
								className="form-control rounded"
								value={Search}
								onChange={(e) => SearchStudents(e.target.value)}
							/>
						</th>
						<th>Score</th>
						<th>Score%</th>
						<th>Comment</th>
						{/*<th>Grade</th>*/}
					</tr>
				</thead>
				<tbody>
					{Students.length > 0 ? (
						Students.map((student, index) => (
							<tr key={index}>
								<td>{student.studentReg}</td>
								<td>
									{student.firstname} {student.lastname} {student.surname}
								</td>
								<td>
									<input
										value={student.score}
										placeholder="Enter score"
										className="form-control rounded"
										type="number"
										onChange={(e) =>
											UpdateInput(e.target.value, index, "score")
										}
									/>
								</td>
								<td>
									{`${parseInt(
										(parseInt(student.score) * 100) / parseInt(exam.maxScore)
									)}%`}
								</td>
								<td>
									<input
										value={student.comment}
										placeholder="Enter comment"
										className="form-control rounded"
										type="text"
										onChange={(e) =>
											UpdateInput(e.target.value, index, "comment")
										}
									/>
								</td>
								{/*<td>
                  <input
                    value={student.grade}
                    placeholder="Enter grade"
                    className="form-control rounded"
                    type="text"
                    onChange={(e) =>
                      UpdateInput(e.target.value, index, "grade")
                    }
                  />
                </td>*/}
							</tr>
						))
					) : (
						<tr>
							<td colSpan={6}>
								<p className="h6">No Students</p>
							</td>
						</tr>
					)}
				</tbody>
			</table>
			<div className="d-flex justify-content-center">
				{Loading ? (
					<span className="spinner-border text-primary"></span>
				) : (
					<button
						className="btn btn-md btn-outline-primary rounded"
						onClick={() => SaveMarks()}
					>
						Save Exam Marks
					</button>
				)}
			</div>
		</div>
	);
};

export default AddExam;

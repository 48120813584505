import { createSlice } from "@reduxjs/toolkit";

export const ItemSlice = createSlice({
  name: "item",
  initialState: {
    items: [],
  },
  reducers: {
    fetchItems: (state, action) => {
      state.items = action.payload;
    },
    addItem: (state, action) => {
      state.items = [action.payload, ...state.items];
    },
    updateItem: (state, action) => {
      let index = state.items.findIndex(
        (item) => parseInt(item.linker) === parseInt(action.payload.linker)
      );

      let prevItems = [...state.items];

      prevItems.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.items = prevItems;
    },
    deleteItem: (state, action) => {
      let newItems = state.items.filter(
        (item) => parseInt(item.linker) !== parseInt(action.payload.linker)
      );
      state.items = newItems;
    },
  },
});

export const { fetchItems, addItem, updateItem, deleteItem } =
  ItemSlice.actions;

export default ItemSlice.reducer;

import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { Bookshelf } from "react-bootstrap-icons";

const LibraryNav = ({ setDrawer }) => {
  const navigate = useNavigate();
  const inst = useSelector((state) => state.inst.activeInst);
  return (
    <>
      <Dropdown style={{ width: "100%" }}>
        <Dropdown.Toggle className="nav-link" variant="transparent">
          <Bookshelf /> Library
        </Dropdown.Toggle>
        <Dropdown.Menu variant="dark">
          <Dropdown.Item>
            <p
              className="nav-link"
              onClick={() => {
                window.scroll(0, 0);
                setDrawer(false);
                navigate(`libcats`);
              }}
            >
              Books Categories
            </p>
          </Dropdown.Item>
          <Dropdown.Item>
            {" "}
            <p
              className="nav-link"
              onClick={() => {
                window.scroll(0, 0);
                setDrawer(false);
                navigate(`books`);
              }}
            >
              Books
            </p>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <hr />
    </>
  );
};

export default LibraryNav;

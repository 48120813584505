import React, { useState, useEffect } from "react";
import BookCrud from "./BookCrud";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircle } from "react-bootstrap-icons";
import NoBook from "./NoBook";
import BookDisp from "./BookDisp";
import IssueBook from "./IssueBook";
import { FetchBooks } from "../../../Network/BookApi";
import { PlainList } from "flatlist-react";
import GeneratePdf from "../../../Utils/GeneratePdf";

const Books = () => {
  useEffect(() => {
    FetchBooks(User.token, ActiveCred.instLinker, dispatch, online);
  }, []);

  const [ShowModal, setShowModal] = useState(false);
  const [BookModal, setBookModal] = useState(false);
  const [Book, setBook] = useState({ name: "" });
  const [Search, setSearch] = useState("");
  const [Cat, setCat] = useState("");
  const [Status, setStatus] = useState("");
  const [Type, setType] = useState("add");

  //redux dispatch
  const dispatch = useDispatch();

  const online = useSelector((state) => state.online.online);
  const User = useSelector((state) => state.auth.user);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Books = useSelector((state) => state.book.books);
  const Cats = useSelector((state) => state.libCat.libCats).filter(
    (cat) => cat.deleted == "0"
  );
  const Students = useSelector((state) => state.cred.creds).filter(
    (student) => student.deleted == "0" && student.student == "1"
  );

  const GetStudent = (linker) => {
    let student = Students.find(
      (student) => parseInt(student.linker) === parseInt(linker)
    );

    return student || {};
  };

  const SearchBook = Books.filter(
    (book) =>
      (book.name.toLowerCase().search(Search.toLowerCase()) > -1 ||
        book.author.toLowerCase().search(Search.toLowerCase()) > -1 ||
        book.details.toLowerCase().search(Search.toLowerCase()) > -1 ||
        Search === "") &&
      (parseInt(book.catLinker) === parseInt(Cat) || Cat === "") &&
      (parseInt(book.bookStatus) === parseInt(Status) || Status === "") &&
      parseInt(book.deleted) === 0
  ).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

  return (
    <div>
      <p className="text-center h6 text-primary text-capitalize">
        {SearchBook.length} Library Books
      </p>
      <table className="table table-sm" id="books">
        <thead className="thead-dark">
          <tr>
            <th scope="column" colSpan={2}>
              <input
                type={"text"}
                placeholder={`Search a Book`}
                value={Search}
                onChange={(e) => setSearch(e.target.value)}
                className="rounded form-control"
              />
            </th>
            <th>Author</th>
            <th>
              <select
                value={Status}
                onChange={(e) => setStatus(e.target.value)}
                className="rounded form-control"
              >
                <option value={""}>All Status</option>
                <option value={"0"}>Available Books</option>
                <option value={"1"}>Issued Books</option>
              </select>
            </th>
            {Cats.length > 0 ? (
              <th>
                <select
                  value={Cat}
                  onChange={(e) => setCat(e.target.value)}
                  className="rounded form-control"
                >
                  <option value={""}>All Categories</option>
                  {Cats.map((cat, index) => (
                    <option key={index} value={cat.linker}>
                      {cat.name}
                    </option>
                  ))}
                </select>
              </th>
            ) : null}
            <th>Details</th>

            <td>
              <button
                className="btn btn-sm btn-primary"
                onClick={() => {
                  setShowModal(true);
                  setType("add");
                  setBook({
                    name: "",
                    details: "",
                    author: "",
                    catLinker: "",
                    bookCode: "",
                  });
                }}
              >
                <PlusCircle /> Add Book
              </button>
            </td>
          </tr>
        </thead>
        {SearchBook.length > 0 ? (
          <tbody>
            <PlainList
              list={SearchBook}
              renderOnScroll
              renderItem={(book) => (
                <BookDisp
                  Cats={Cats}
                  book={book}
                  setShowModal={setShowModal}
                  setType={setType}
                  setBook={setBook}
                  setBookModal={setBookModal}
                  GetStudent={GetStudent}
                  key={book.linker}
                ></BookDisp>
              )}
            />
          </tbody>
        ) : (
          <NoBook></NoBook>
        )}
      </table>
      <GeneratePdf id={"books"} name={"books"}></GeneratePdf>
      <BookCrud
        setShowModal={setShowModal}
        ShowModal={ShowModal}
        book={Book}
        setBook={setBook}
        type={Type}
        Cats={Cats}
      ></BookCrud>
      <IssueBook
        book={Book}
        setBook={setBook}
        BookModal={BookModal}
        setBookModal={setBookModal}
        students={Students}
        GetStudent={GetStudent}
      ></IssueBook>
    </div>
  );
};

export default Books;

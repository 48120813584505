import React from "react";

const NoInvoices = () => {
	return (
		<tbody>
			<tr>
				<th>No Invoices</th>

				<td>
					Invoices are financial obligations that students owe the institution
					eg tuition fee, accomodation fee etc
				</td>
				<td>
					<p className="text-primary">
						To add invoices go to students, student update
					</p>
				</td>
			</tr>
		</tbody>
	);
};

export default NoInvoices;

import React from "react";
import { useSelector } from "react-redux";
import { Trash, PencilSquare } from "react-bootstrap-icons";

const PaymentDisp = ({ payment, setShowModal, setType, setPayment }) => {
  const Creds = useSelector((state) => state.cred.creds);
  const Modes = useSelector((state) => state.mode.modes).filter(
    (mode) => parseInt(mode.deleted) === 0
  );

  const FindUser = (linker) => {
    let cred = Creds.find((cred) => parseInt(cred.linker) == parseInt(linker));

    return cred || { firstname: "unknown" };
  };

  const FindMode = (linker) => {
    let mode = Modes.find((mode) => parseInt(mode.linker) === parseInt(linker));

    return mode || { name: "unknown" };
  };

  return (
    <tr className="item">
      <td>{payment.name}</td>
      <td>{FindMode(payment.mode).name}</td>
      <td>
        {payment.code} {payment.details}
      </td>
      <td>{payment.amount}</td>
      <td>
        {FindUser(payment.studentLinker).studentReg}{" "}
        {FindUser(payment.studentLinker).firstname}{" "}
        {FindUser(payment.studentLinker).lastname}
      </td>
      <td>{new Date(parseInt(payment.linker)).toLocaleDateString("en-US")}</td>
      <td>{FindUser(payment.credLinker).email}</td>
      <td>
        <PencilSquare
          onClick={() => {
            setShowModal(true);
            setType("edit");
            setPayment({ ...payment });
          }}
          style={{ cursor: "pointer" }}
          className="text-info mx-2"
        />

        <Trash
          onClick={() => {
            setShowModal(true);
            setType("delete");
            setPayment({ ...payment });
          }}
          style={{ cursor: "pointer" }}
          className="text-danger mx-2"
        />
      </td>
    </tr>
  );
};

export default PaymentDisp;

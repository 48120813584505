import { createSlice } from "@reduxjs/toolkit";

export const MessageSlice = createSlice({
	name: "message",
	initialState: {
		messages: [],
	},
	reducers: {
		fetchMessages: (state, action) => {
			state.messages = action.payload;
		},
		updateMessage: (state, action) => {
			let index = state.messages.findIndex(
				(message) =>
					parseInt(message.linker) === parseInt(action.payload.linker)
			);

			let prevMessages = [...state.messages];

			prevMessages.splice(
				index < 0 ? 1 : index,
				index < 0 ? 0 : 1,
				action.payload
			);

			state.messages = prevMessages;
		},
	},
});

export const { fetchMessages, addMessage, updateMessage, deleteMessage } =
	MessageSlice.actions;

export default MessageSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

export const LessonSlice = createSlice({
	name: "lesson",
	initialState: {
		lessons: [],
	},
	reducers: {
		fetchLessons: (state, action) => {
			state.lessons = action.payload;
		},
		addLesson: (state, action) => {
			state.lessons = [action.payload, ...state.lessons];
		},
		updateLesson: (state, action) => {
			let index = state.lessons.findIndex(
				(lesson) => parseInt(lesson.linker) === parseInt(action.payload.linker)
			);

			let prevLessons = [...state.lessons];

			prevLessons.splice(
				index < 0 ? 1 : index,
				index < 0 ? 0 : 1,
				action.payload
			);

			state.lessons = prevLessons;
		},
		deleteLesson: (state, action) => {
			let newLessons = state.lessons.filter(
				(lesson) => parseInt(lesson.linker) !== parseInt(action.payload.linker)
			);
			state.lessons = newLessons;
		},
	},
});

export const { fetchLessons, addLesson, updateLesson, deleteLesson } =
	LessonSlice.actions;

export default LessonSlice.reducer;

import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { Bell, PersonAdd, PersonGear } from "react-bootstrap-icons";
import MpesaNav from "./OnlinePay/Mpesa/MpesaNav";

const AdminNav = ({ setDrawer }) => {
	const navigate = useNavigate();
	const inst = useSelector((state) => state.inst.activeInst);
	return (
		<>
			<Dropdown style={{ width: "100%" }}>
				<Dropdown.Toggle variant="transparent" className="nav-link">
					<PersonGear /> Admin{" "}
				</Dropdown.Toggle>
				<Dropdown.Menu variant="dark">
					<Dropdown.Item>
						<p
							className="nav-link"
							onClick={() => {
								window.scroll(0, 0);
								setDrawer(false);
								navigate(`structure/${"academic-year"}`);
							}}
						>
							Academic Years
						</p>
					</Dropdown.Item>
					{parseInt(inst.higher) === 1 ? (
						<Dropdown.Item>
							<p
								className="nav-link"
								onClick={() => {
									window.scroll(0, 0);
									setDrawer(false);
									navigate(`structure/${"faculty"}`);
								}}
							>
								Faculties
							</p>
						</Dropdown.Item>
					) : null}
					<Dropdown.Item>
						<p
							className="nav-link"
							onClick={() => {
								window.scroll(0, 0);
								setDrawer(false);
								navigate(`structure/${"level"}`);
							}}
						>
							Levels
						</p>
					</Dropdown.Item>
					<Dropdown.Item>
						<p
							className="nav-link"
							onClick={() => {
								window.scroll(0, 0);
								setDrawer(false);
								navigate(`structure/${"period"}`);
							}}
						>
							Periods
						</p>
					</Dropdown.Item>
					<Dropdown.Item>
						<p
							className="nav-link"
							onClick={() => {
								window.scroll(0, 0);
								setDrawer(false);
								navigate(`subjects`);
							}}
						>
							Subjects
						</p>
					</Dropdown.Item>
					<Dropdown.Item>
						<p
							className="nav-link"
							onClick={() => {
								window.scroll(0, 0);
								setDrawer(false);
								navigate(`activities`);
							}}
						>
							Assessment
						</p>
					</Dropdown.Item>
					<Dropdown.Item>
						<p
							className="nav-link"
							onClick={() => {
								window.scroll(0, 0);
								setDrawer(false);
								navigate(`grading`);
							}}
						>
							Grading
						</p>
					</Dropdown.Item>{" "}
					<Dropdown.Item>
						<p
							className="nav-link"
							onClick={() => {
								window.scroll(0, 0);
								setDrawer(false);
								navigate(`modes`);
							}}
						>
							Payment Modes
						</p>
					</Dropdown.Item>
					<MpesaNav setDrawer={setDrawer} navigate={navigate}></MpesaNav>
					<Dropdown.Item>
						<p
							className="nav-link"
							onClick={() => {
								window.scroll(0, 0);
								setDrawer(false);
								navigate(`edit`);
							}}
						>
							Edit Institution
						</p>
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
			<hr />
			<Dropdown style={{ width: "100%" }}>
				<Dropdown.Toggle variant="transparent" className="nav-link">
					<Bell /> Notifications
				</Dropdown.Toggle>
				<Dropdown.Menu variant="dark">
					{inst.sub === "Kenya" ? (
						<Dropdown.Item>
							<p
								className="nav-link"
								onClick={() => {
									window.scroll(0, 0);
									setDrawer(false);
									navigate(`bulk-sms`);
								}}
							>
								Bulk Sms
							</p>
						</Dropdown.Item>
					) : null}
					<Dropdown.Item>
						<p
							className="nav-link"
							onClick={() => {
								window.scroll(0, 0);
								setDrawer(false);
								navigate(`notifications`);
							}}
						>
							Notifications
						</p>
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
			<hr />
			<Dropdown style={{ width: "100%" }}>
				<Dropdown.Toggle variant="transparent" className="nav-link">
					<PersonAdd /> H.R. Manager
				</Dropdown.Toggle>
				<Dropdown.Menu variant="dark">
					<Dropdown.Item>
						<p
							className="nav-link"
							onClick={() => {
								window.scroll(0, 0);
								setDrawer(false);
								navigate(`staffs`);
							}}
						>
							Staffs
						</p>
					</Dropdown.Item>
					<Dropdown.Item>
						<p
							className="nav-link"
							onClick={() => {
								window.scroll(0, 0);
								setDrawer(false);
								navigate(`payroll-items`);
							}}
						>
							Payroll Items
						</p>
					</Dropdown.Item>{" "}
					<Dropdown.Item>
						<p
							className="nav-link"
							onClick={() => {
								window.scroll(0, 0);
								setDrawer(false);
								navigate(`payroll-summary`);
							}}
						>
							Payroll Summary
						</p>
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
			<hr />
		</>
	);
};

export default AdminNav;

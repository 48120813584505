export const VerifySub = (subs, instLinker) => {
	let subTimes = subs
		.filter(
			(sub) =>
				sub.softwareId ==
				parseInt(parseInt(`${instLinker}`.split("").reverse().join("")) / 1000)
		)
		.sort((a, b) => a.linker - b.linker)
		.reduce((accumulator, current) => {
			let Exists = accumulator.some((obj) => obj.code == current.code);
			if (!Exists) {
				accumulator.push(current);
			}
			return accumulator;
		}, [])
		.map((sub, index) => {
			let softwareId = parseInt(
				parseInt(`${instLinker}`.split("").reverse().join("")) / 1000
			);

			let subTime = 0;

			if (softwareId == sub.softwareId) {
				let amt = (sub.code - softwareId) / (index + 2);

				subTime =
					(amt === 99 && index === 0) || (amt === 29 && index > 0)
						? 366 * 24 * 60 * 60
						: //To verify for v2 where amount represents time allocated
						amt === 366 * 24 * 60 * 60 ||
						  amt === (366 * 24 * 60 * 60) / 2 ||
						  amt === (366 * 24 * 60 * 60) / 4 ||
						  amt === (366 * 24 * 60 * 60) / 12
						? amt
						: amt === 366 * 24 * 60 * 60 * 2
						? 366 * 24 * 60 * 60 * 2000
						: 0;
			}

			return subTime;
		});

	return (
		subTimes.reduce((a, b) => +a + +b, 0) +
		parseInt(instLinker / 1000) +
		30 * 24 * 60 * 60 -
		parseInt(Date.now() / 1000)
	);
};

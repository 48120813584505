import React, { useState, useEffect } from "react";
import ItemCrud from "./ItemCrud";
import { useDispatch, useSelector } from "react-redux";
import NoItem from "./NoItem";
import ItemDisp from "./ItemDisp";
import { FetchItems } from "../../../Network/ItemApi";
import { PlainList } from "flatlist-react";
import ItemEntry from "./ItemEntry";
import GeneratePdf from "../../../Utils/GeneratePdf";

const Items = () => {
  useEffect(() => {
    FetchItems(User.token, ActiveCred.instLinker, dispatch, online);
  }, []);

  const [ShowModal, setShowModal] = useState(false);
  const [Item, setItem] = useState({ name: "" });
  const [Search, setSearch] = useState("");
  const [Type, setType] = useState("add");
  const [Cat, setCat] = useState("all");
  const [Entry, setEntry] = useState({});
  const [EntryModal, setEntryModal] = useState(false);
  const [EntryType, setEntryType] = useState(false);

  //redux dispatch
  const dispatch = useDispatch();

  const online = useSelector((state) => state.online.online);
  const User = useSelector((state) => state.auth.user);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Items = useSelector((state) => state.item.items);
  const Entries = useSelector((state) => state.storeEntry.storeEntries);

  const Cats = useSelector((state) => state.storeCat.storeCats).filter(
    (cat) => parseInt(cat.deleted) === 0
  );

  const SearchItem = Items.filter(
    (item) =>
      (item.name?.toLowerCase().search(Search.toLowerCase()) > -1 ||
        item.details?.toLowerCase().search(Search.toLowerCase()) > -1 ||
        Search === "") &&
      parseInt(item.deleted) === 0 &&
      (parseInt(item.catLinker) === parseInt(Cat) || Cat === "all")
  ).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

  return (
    <div>
      <div className="d-flex justify-content-around mt-2">
        <p className="text-center h6 text-primary text-capitalize">
          {SearchItem.length} Items
        </p>{" "}
        <button
          className="btn btn-sm btn-outline-secondary"
          onClick={() => {
            setType("add");
            setShowModal(true);
            setItem({
              linker: Date.now(),
              trace: Date.now(),
              name: "",
              details: "",
              catLinker: "",
              deleted: "0",
            });
          }}
        >
          Add Item
        </button>
      </div>

      <table className="table table-sm" id="storeitems">
        <thead className="thead-dark">
          <tr>
            <th scope="column">
              <input
                type={"text"}
                placeholder={`Search Item`}
                value={Search}
                onChange={(e) => setSearch(e.target.value)}
                className="rounded form-control"
              />
            </th>
            <th>Details</th>
            <th scope="column">QTY</th>
            <th scope="column">
              <select
                className="form-control rounded"
                value={Cat}
                onChange={(e) => setCat(e.target.value)}
              >
                <option value={"all"}>All Categories</option>
                {Cats.map((cat, index) => (
                  <option key={index} value={cat.linker}>
                    {cat.name}
                  </option>
                ))}
              </select>
            </th>
          </tr>
        </thead>
        {SearchItem.length > 0 ? (
          <tbody>
            <PlainList
              list={SearchItem}
              renderOnScroll
              renderItem={(item) => (
                <ItemDisp
                  setShowModal={setShowModal}
                  ShowModal={ShowModal}
                  item={item}
                  setItem={setItem}
                  type={Type}
                  setType={setType}
                  setEntryType={setEntryType}
                  key={item.linker}
                  Cats={Cats}
                  setEntry={setEntry}
                  setEntryModal={setEntryModal}
                  Entries={Entries.filter(
                    (entry) =>
                      parseInt(entry.itemLinker) === parseInt(item.linker) &&
                      parseInt(entry.deleted) === 0
                  )}
                ></ItemDisp>
              )}
            />
          </tbody>
        ) : (
          <NoItem></NoItem>
        )}
      </table>
      <GeneratePdf id={"storeitems"} name={"Store-Items"}></GeneratePdf>
      <ItemCrud
        setShowModal={setShowModal}
        ShowModal={ShowModal}
        item={Item}
        setItem={setItem}
        type={Type}
        Cats={Cats}
      ></ItemCrud>
      <ItemEntry
        setShowModal={setEntryModal}
        ShowModal={EntryModal}
        type={EntryType}
        storeEntry={Entry}
        setStoreEntry={setEntry}
        item={Item}
      ></ItemEntry>
    </div>
  );
};

export default Items;

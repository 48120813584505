import React from "react";
import Lesson from "./Lesson";
const TimeTableClass = ({
	day,
	level,
	faculty,
	period,
	SortIntervals,
	setType,
	setActiveLesson,
	setLessonModal,
	Lessons,
}) => {
	return (
		<tr>
			<th>{day}</th>

			{SortIntervals.map((interval, index) => (
				<th key={index}>
					<Lesson
						intervalLinker={interval.linker}
						Day={day}
						setType={setType}
						setShowModal={setLessonModal}
						setActiveLesson={setActiveLesson}
						level={level}
						period={period}
						faculty={faculty}
						Lessons={Lessons}
					></Lesson>
				</th>
			))}
		</tr>
	);
};

export default TimeTableClass;

import React, { useState, useEffect } from "react";
import AddExamModal from "./AddExamModal";
import ViewExam from "./ViewExam";
import { useSelector, useDispatch } from "react-redux";
import AddExam from "./AddExam";
import ClassRankingModal from "./ClassRankingModal";
import RankingView from "./RankingView";
import { FetchExams } from "../../../Network/ExamApi";

const Exams = () => {
  useEffect(() => {
    FetchExams(User.token, ActiveCred.instLinker, dispatch, online);
  }, []);

  //redux dispatch
  const dispatch = useDispatch();

  const [ViewType, setViewType] = useState("view");
  const [ShowModal, setShowModal] = useState(false);
  const [RankModal, setRankModal] = useState(false);
  const [Exam, setExam] = useState({
    year: "",
    level: "",
    faculty: "",
    period: "",
    subject: "",
    activity: "",
    maxScore: "",
  });

  const online = useSelector((state) => state.online.online);
  const User = useSelector((state) => state.auth.user);
  const Structures = useSelector((state) => state.structure.structures);
  const ActiveCred = useSelector((state) => state.cred.activeCred);

  const Levels = Structures.filter(
    (structure) =>
      structure.type === "level" && parseInt(structure.deleted) === 0
  );

  const Periods = Structures.filter(
    (structure) =>
      structure.type === "period" && parseInt(structure.deleted) === 0
  );

  const Faculties = Structures.filter(
    (structure) =>
      structure.type === "faculty" && parseInt(structure.deleted) === 0
  );

  const Years = Structures.filter(
    (structure) =>
      structure.type === "academic-year" && parseInt(structure.deleted) === 0
  );

  const Subjects = useSelector((state) => state.subject.subjects).filter(
    (subject) =>
      (parseInt(subject.levelLinker) == Exam.level || Exam.level == "") &&
      (parseInt(subject.periodLinker) == Exam.period || Exam.period == "") &&
      (parseInt(subject.facultyLinker) == Exam.faculty || Exam.faculty == "") &&
      parseInt(subject.deleted) === 0
  );

  const Activities = useSelector((state) => state.activity.activities).filter(
    (activity) =>
      (parseInt(activity.levelLinker) == Exam.level || Exam.level == "") &&
      (parseInt(activity.periodLinker) == Exam.period || Exam.period == "") &&
      (parseInt(activity.facultyLinker) == Exam.faculty ||
        Exam.faculty == "") &&
      (parseInt(activity.subjectLinker) == Exam.subject ||
        Exam.subject == "") &&
      parseInt(activity.deleted) === 0
  );

  return (
    <div>
      <div className="d-flex justify-content-around">
        <p className="text-center h5 text-primary text-capitalize">Scores</p>
        <button
          className="btn btn-sm btn-outline-secondary mt-2"
          onClick={() => {
            setShowModal(true);
            setViewType("view");
            setExam({
              year: "",
              level: "",
              faculty: "",
              period: "",
              subject: "",
              activity: "",
              maxScore: "",
            });
          }}
        >
          Add Score
        </button>

        <button
          className="btn btn-sm btn-outline-secondary mt-2"
          onClick={() => {
            setExam({
              year: "",
              level: "",
              faculty: "",
              period: "",
              subject: "",
              activity: "",
              maxScore: "",
            });
            if (
              Years.length < 1 &&
              Faculties.length < 1 &&
              Levels.length < 1 &&
              Periods.length < 1
            ) {
              setViewType("rank");
            } else {
              setRankModal(true);
            }
          }}
        >
          Class Ranking
        </button>
      </div>

      {ViewType === "view" ? (
        <ViewExam
          Years={Years}
          Faculties={Faculties}
          Levels={Levels}
          Periods={Periods}
          Subjects={Subjects}
          Activities={Activities}
          setExam={setExam}
          exam={Exam}
        ></ViewExam>
      ) : ViewType == "ranking" ? (
        <RankingView
          Years={Years}
          Faculties={Faculties}
          Levels={Levels}
          Periods={Periods}
          exam={Exam}
        ></RankingView>
      ) : (
        <AddExam
          exam={Exam}
          setViewType={setViewType}
          FetchExams={FetchExams}
          setExam={setExam}
        ></AddExam>
      )}

      <AddExamModal
        exam={Exam}
        setExam={setExam}
        ShowModal={ShowModal}
        setShowModal={setShowModal}
        setViewType={setViewType}
        Years={Years}
        Faculties={Faculties}
        Levels={Levels}
        Periods={Periods}
        Subjects={Subjects}
        Activities={Activities}
      ></AddExamModal>

      <ClassRankingModal
        exam={Exam}
        setExam={setExam}
        ShowModal={RankModal}
        setShowModal={setRankModal}
        setViewType={setViewType}
        Years={Years}
        Faculties={Faculties}
        Levels={Levels}
        Periods={Periods}
      ></ClassRankingModal>
    </div>
  );
};

export default Exams;

import React from "react";

const NoNotification = () => {
  return (
    <div className="jumbotron">
      <p className="text-center h6 text-primary">No Notifications</p>
    </div>
  );
};

export default NoNotification;

import React from "react";
import { useSelector } from "react-redux";
import {
	Trash,
	PencilSquare,
	Mortarboard,
	Book,
	CashCoin,
	Envelope,
} from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
const StudentDisp = ({
	student,
	setShowModal,
	setType,
	setStudent,
	setDetailsModal,
	setSmsModal,
}) => {
	const Navigate = useNavigate();
	const ActiveInst = useSelector((state) => state.inst.activeInst);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const Structures = useSelector((state) => state.structure.structures);
	const Invoices = useSelector((state) => state.invoice.invoices).filter(
		(record) =>
			parseInt(record.studentLinker) === parseInt(student.linker) &&
			record.deleted == 0
	);
	const Payments = useSelector((state) => state.payment.payments).filter(
		(record) =>
			parseInt(record.studentLinker) === parseInt(student.linker) &&
			record.deleted == 0
	);

	const Levels = Structures.filter(
		(structure) =>
			structure.type === "level" && parseInt(structure.deleted) === 0
	);

	const Periods = Structures.filter(
		(structure) =>
			structure.type === "period" && parseInt(structure.deleted) === 0
	);

	const Faculties = Structures.filter(
		(structure) =>
			structure.type === "faculty" && parseInt(structure.deleted) === 0
	);

	const Years = Structures.filter(
		(structure) =>
			structure.type === "academic-year" && parseInt(structure.deleted) === 0
	);

	const Creds = useSelector((state) => state.cred.creds);

	const GetGuardians = () => {
		let guardians = Creds.filter((cred) => cred.refLinker == student.linker);
		let guardianOne = guardians[0] || { firstname: "", email: "", contact: "" };
		let guardianTwo = guardians[1] || { firstname: "", email: "", contact: "" };
		return {
			guardianOneName: guardianOne.firstname,
			guardianOneEmail: guardianOne.email,
			guardianOneContact: guardianOne.contact,
			guardianOneLinker: guardianOne.linker,
			guardianTwoName: guardianTwo.firstname,
			guardianTwoEmail: guardianTwo.email,
			guardianTwoContact: guardianTwo.contact,
			guardianTwoLinker: guardianTwo.linker,
			guardianOneLive: guardianOne.live,
			guardianTwoLive: guardianTwo.live,
			guardianOneId: guardianOne.id,
			guardianTwoId: guardianTwo.id,
		};
	};

	return (
		<>
			<tr>
				<td>{student.studentReg}</td>
				<td>
					{student.firstname} {student.lastname} {student.surname}
				</td>
				{Years.length > 0 ? (
					<td>
						{
							(
								Years.find((year) => year.linker == student.year) || {
									name: "none",
								}
							).name
						}
					</td>
				) : null}
				{Faculties.length > 0 ? (
					<td>
						{
							(
								Faculties.find(
									(faculty) => faculty.linker == student.faculty
								) || {
									name: "none",
								}
							).name
						}
					</td>
				) : null}
				{Levels.length > 0 ? (
					<td>
						{
							(
								Levels.find((level) => level.linker == student.level) || {
									name: "none",
								}
							).name
						}
					</td>
				) : null}
				{Periods.length > 0 ? (
					<td>
						{
							(
								Periods.find((period) => period.linker == student.period) || {
									name: "none",
								}
							).name
						}
					</td>
				) : null}
				<td>
					{Invoices.reduce((a, b) => +a + +b.amount, 0) -
						Payments.reduce((a, b) => +a + +b.amount, 0)}
				</td>
				<td>
					<Dropdown className="m-0 p-0">
						<Dropdown.Toggle
							variant="transparent"
							className="m-0 p-0 text-start text-primary btn-link text-wrap"
						>
							<small>Update Student...</small>
						</Dropdown.Toggle>
						<Dropdown.Menu>
							{parseInt(ActiveCred.admin) === 1 ||
							parseInt(ActiveCred.tutor) === 1 ? (
								<>
									{" "}
									<Dropdown.Item
										onClick={() => {
											setShowModal(true);
											setType("edit");
											setStudent({ ...student, ...GetGuardians() });
										}}
										style={{ cursor: "pointer" }}
										className="text-info mx-1"
									>
										<span>
											<PencilSquare />
											Edit Student
										</span>{" "}
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() => {
											setShowModal(true);
											setType("delete");
											setStudent({ ...student, ...GetGuardians() });
										}}
										style={{ cursor: "pointer" }}
										className="text-danger mx-1"
									>
										<span>
											<Trash />
											Delete Student
										</span>
									</Dropdown.Item>
									<Dropdown.Item
										className="mx-1 text-primary btn-link"
										style={{ cursor: "pointer" }}
										onClick={() => {
											window.scroll(0, 0);
											Navigate(`student/academics/${student.linker}`);
										}}
									>
										<span>
											<Mortarboard />
											Student Scores/Marks
										</span>
									</Dropdown.Item>
								</>
							) : null}
							{parseInt(ActiveCred.admin) === 1 ||
							parseInt(ActiveCred.finance) === 1 ? (
								<Dropdown.Item
									className="mx-1 btn-link text-primary"
									style={{ cursor: "pointer" }}
									onClick={() => {
										window.scroll(0, 0);
										Navigate(`student/finance/${student.linker}`);
									}}
								>
									<span>
										<CashCoin />
										Fee Invoice & Pay
									</span>
								</Dropdown.Item>
							) : null}
							{parseInt(ActiveCred.admin) === 1 ||
							parseInt(ActiveCred.tutor) === 1 ||
							parseInt(ActiveCred.library) === 1 ? (
								<Dropdown.Item
									className="mx-1 text-primary btn-link"
									style={{ cursor: "pointer" }}
									onClick={() => {
										window.scroll(0, 0);
										Navigate(`student/books/${student.linker}`);
									}}
								>
									<span>
										<Book />
										Library Books
									</span>
								</Dropdown.Item>
							) : null}
							{ActiveInst.sub === "Kenya" ? (
								<Dropdown.Item
									className="text-info mx-1 btn-link"
									style={{ cursor: "pointer" }}
									onClick={() => {
										setSmsModal(true);
										setStudent({ ...student, ...GetGuardians() });
									}}
								>
									<span>
										<Envelope />
										Sms Student
									</span>
								</Dropdown.Item>
							) : null}
							<Dropdown.Item
								onClick={() => {
									setDetailsModal(true);
									setStudent({ ...student, ...GetGuardians() });
								}}
								style={{ cursor: "pointer" }}
								className="mx-1 text-primary btn-link"
							>
								<span>Student Details</span>
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</td>
			</tr>
		</>
	);
};

export default StudentDisp;

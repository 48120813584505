import { createSlice } from "@reduxjs/toolkit";

export const NotificationSlice = createSlice({
  name: "notification",
  initialState: {
    notifications: [],
  },
  reducers: {
    fetchNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    addNotification: (state, action) => {
      state.notifications = [action.payload, ...state.notifications];
    },
    updateNotification: (state, action) => {
      let index = state.notifications.findIndex(
        (notification) =>
          parseInt(notification.linker) === parseInt(action.payload.linker)
      );

      let prevNotifications = [...state.notifications];

      prevNotifications.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.notifications = prevNotifications;
    },
    deleteNotification: (state, action) => {
      let newNotifications = state.notifications.filter(
        (notification) =>
          parseInt(notification.linker) !== parseInt(action.payload.linker)
      );
      state.notifications = newNotifications;
    },
  },
});

export const {
  fetchNotifications,
  addNotification,
  updateNotification,
  deleteNotification,
} = NotificationSlice.actions;

export default NotificationSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

export const StoreCatSlice = createSlice({
  name: "storeCat",
  initialState: {
    storeCats: [],
  },
  reducers: {
    fetchStoreCats: (state, action) => {
      state.storeCats = action.payload;
    },
    addStoreCat: (state, action) => {
      state.storeCats = [action.payload, ...state.storeCats];
    },
    updateStoreCat: (state, action) => {
      let index = state.storeCats.findIndex(
        (storeCat) =>
          parseInt(storeCat.linker) === parseInt(action.payload.linker)
      );

      let prevStoreCats = [...state.storeCats];

      prevStoreCats.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.storeCats = prevStoreCats;
    },
    deleteStoreCat: (state, action) => {
      let newStoreCats = state.storeCats.filter(
        (storeCat) =>
          parseInt(storeCat.linker) !== parseInt(action.payload.linker)
      );
      state.storeCats = newStoreCats;
    },
  },
});

export const { fetchStoreCats, addStoreCat, updateStoreCat, deleteStoreCat } =
  StoreCatSlice.actions;

export default StoreCatSlice.reducer;

import React from "react";
import { Trash, PencilSquare } from "react-bootstrap-icons";
import { useSelector } from "react-redux";

const ExamDisp = ({ exam, setExamCrudModal, setType, setActiveExam }) => {
	const Grades = useSelector((state) => state.grade.grades);
	const GetGrade = (score) => {
		for (const grade of Grades) {
			if (score >= grade.min && score <= grade.max) {
				return grade.grade;
			}
		}
		return "";
	};
	return (
		<>
			<tr>
				<td>{exam.student.studentReg}</td>
				<td>
					{exam.student.firstname} {exam.student.lastname}{" "}
					{exam.student.surname}
				</td>
				<td>{exam.score}</td>
				<td>{exam.maxScore}</td>
				<td>{`${parseInt((exam.score * 100) / exam.maxScore)}%`}</td>
				<td>{GetGrade((exam.score * 100) / exam.maxScore)}</td>
				<td>{exam.comment}</td>
				<td>
					<PencilSquare
						onClick={() => {
							setExamCrudModal(true);
							setType("edit");
							setActiveExam({ ...exam });
						}}
						style={{ cursor: "pointer" }}
						className="text-info m-1"
					/>
					<Trash
						onClick={() => {
							setExamCrudModal(true);
							setType("delete");
							setActiveExam({ ...exam });
						}}
						style={{ cursor: "pointer" }}
						className="text-danger m-1"
					/>
				</td>
			</tr>
		</>
	);
};

export default ExamDisp;

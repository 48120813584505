import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
const ClassRankingModal = ({
  setShowModal,
  ShowModal,
  setViewType,
  Years,
  Faculties,
  Periods,
  Levels,
  exam,
  setExam,
}) => {
  return (
    <Modal
      show={ShowModal}
      onHide={() => {
        setShowModal(false);
        setExam({
          year: "",
          level: "",
          faculty: "",
          period: "",
          subject: "",
          activity: "",
          maxScore: "",
        });
      }}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="text-capitalize text-center">Select Class To Rank</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            setViewType("ranking");
            setShowModal(false);
          }}
        >
          <div>
            {Years.length > 0 ? (
              <div className="col-md-12">
                <label>Academic Year :</label>
                <select
                  className="form-control rounded"
                  onChange={(e) =>
                    setExam({
                      ...exam,
                      year: e.target.value,
                    })
                  }
                  required
                  value={exam.year}
                >
                  <option value={""}>Select AcYear</option>
                  {Years.map((Year, index) => (
                    <option key={index} value={Year.linker}>
                      {Year.name}
                    </option>
                  ))}
                </select>
                <hr />
              </div>
            ) : null}
            {Faculties.length > 0 ? (
              <div className="col-md-12">
                <label>Faculty :</label>
                <select
                  className="form-control rounded"
                  onChange={(e) =>
                    setExam({
                      ...exam,
                      faculty: e.target.value,
                    })
                  }
                  value={exam.faculty}
                  required
                >
                  <option value={""}>Select Faculty</option>
                  {Faculties.map((faculty, index) => (
                    <option key={index} value={faculty.linker}>
                      {faculty.name}
                    </option>
                  ))}
                </select>
                <hr />
              </div>
            ) : null}

            {Levels.length > 0 ? (
              <div className="col-md-12">
                <label>Level :</label>
                <select
                  className="form-control rounded"
                  onChange={(e) =>
                    setExam({
                      ...exam,
                      level: e.target.value,
                    })
                  }
                  value={exam.level}
                  required
                >
                  <option value={""}>Select Level</option>
                  {Levels.map((level, index) => (
                    <option key={index} value={level.linker}>
                      {level.name}
                    </option>
                  ))}
                </select>
                <hr />
              </div>
            ) : null}
            {Periods.length > 0 ? (
              <div className="col-md-12">
                <label>Period :</label>
                <select
                  className="form-control rounded"
                  onChange={(e) =>
                    setExam({
                      ...exam,
                      period: e.target.value,
                    })
                  }
                  value={exam.period}
                  required
                >
                  <option value={""}>Select Period</option>
                  {Periods.map((period, index) => (
                    <option key={index} value={period.linker}>
                      {period.name}
                    </option>
                  ))}
                </select>
                <hr />
              </div>
            ) : null}
          </div>

          <div className="d-flex justify-content-around mb-2">
            <Button variant="primary" type="submit">
              Okay
            </Button>

            <Button
              variant="secondary"
              type="button"
              onClick={() => {
                setShowModal(false);
                setExam({
                  year: "",
                  level: "",
                  faculty: "",
                  period: "",
                  subject: "",
                  activity: "",
                  maxScore: "",
                });
              }}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ClassRankingModal;

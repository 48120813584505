import React, { useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { FilePdf } from "react-bootstrap-icons";

const GeneratePdf = ({ id, name }) => {
	const [loading, setLoading] = useState(false);

	const generatePdf = async () => {
		setLoading(true);
		const element = document.getElementById(id);

		await html2canvas(element, {
			useCORS: true,
			backgroundColor: null,
		}).then(async (canvas) => {
			const pdf = new jsPDF("p", "pt", "letter");
			const pageWidth = 612;
			const pageHeight = 791;
			const canvasWidth = canvas.width;
			const canvasHeight = canvas.height;
			const scaleFactor = pageWidth / canvasWidth;

			for (let i = 0; i <= canvasHeight / (pageHeight / scaleFactor); i++) {
				const onePageCanvas = document.createElement("canvas");
				onePageCanvas.width = canvasWidth;
				onePageCanvas.height = pageHeight / scaleFactor;

				const ctx = onePageCanvas.getContext("2d");
				ctx.drawImage(
					canvas,
					0,
					i * (pageHeight / scaleFactor),
					canvasWidth,
					pageHeight / scaleFactor,
					0,
					0,
					canvasWidth,
					pageHeight / scaleFactor
				);

				const canvasDataUrl = onePageCanvas.toDataURL("image/png", 1.0);

				if (i > 0) {
					pdf.addPage();
				}
				pdf.addImage(canvasDataUrl, "PNG", 0, 0, pageWidth, pageHeight);
			}

			pdf.save(`${name}-${Date.now()}.pdf`);
		});
		setLoading(false);
	};

	return (
		<div className="d-flex justify-content-center">
			{loading ? (
				<span className="spinner-border text-primary"></span>
			) : (
				<button
					onClick={generatePdf}
					className="btn btn-sm btn-outline-primary rounded"
				>
					<FilePdf /> Download Pdf
				</button>
			)}
		</div>
	);
};

export default GeneratePdf;

import { configureStore } from "@reduxjs/toolkit";
import { LoadState, SaveState } from "./Utils/LocalStorage";
import ActivitySlice from "./reducers/ActivitySlice";
import AuthSlice from "./reducers/AuthSlice";
import CredSlice from "./reducers/CredSlice";
import InstSlice from "./reducers/InstSlice";
import StructureSlice from "./reducers/StructureSlice";
import ModeSlice from "./reducers/ModeSlice";
import SubjectSlice from "./reducers/SubjectSlice";
import InvoiceSlice from "./reducers/InvoiceSlice";
import PaymentSlice from "./reducers/PaymentSlice";
import ExamSlice from "./reducers/ExamSlice";
import LibCatSlice from "./reducers/LibCatSlice";
import BookSlice from "./reducers/BookSlice";
import ExpenseCatSlice from "./reducers/ExpenseCatSlice";
import ExpenseSlice from "./reducers/ExpenseSlice";
import IntervalSlice from "./reducers/IntervalSlice";
import LessonSlice from "./reducers/LessonSlice";
import StoreCatSlice from "./reducers/StoreCatSlice";
import ItemSlice from "./reducers/ItemSlice";
import StoreEntrySlice from "./reducers/StoreEntrySlice";
import SubSlice from "./reducers/SubSlice";
import OnlineSlice from "./reducers/OnlineSlice";
import PwaSlice from "./reducers/PwaSlice";
import ActiveSlice from "./reducers/ActiveSlice";
import GradeSlice from "./reducers/GradeSlice";
import PayrollEntrySlice from "./reducers/PayrollEntrySlice";
import PayrollCatSlice from "./reducers/PayrollCatSlice";
import PayrollItemSlice from "./reducers/PayrollItemSlice";
import StaffPayItemSlice from "./reducers/StaffPayItemSlice";
import StaffPayEntrySlice from "./reducers/StaffPayEntrySlice";
import NotificationSlice from "./reducers/NotificationSlice";
import OnlinePaySlice from "./reducers/OnlinePaySlice";
import MpesaPaymentsSlice from "./reducers/MpesaPaymentsSlice";
import MessageSlice from "./reducers/MessageSlice";

const persistedState = LoadState();

const store = configureStore({
	reducer: {
		auth: AuthSlice,
		inst: InstSlice,
		cred: CredSlice,
		structure: StructureSlice,
		mode: ModeSlice,
		subject: SubjectSlice,
		activity: ActivitySlice,
		grade: GradeSlice,
		invoice: InvoiceSlice,
		payment: PaymentSlice,
		mpesaPayment: MpesaPaymentsSlice,
		exam: ExamSlice,
		libCat: LibCatSlice,
		book: BookSlice,
		expenseCat: ExpenseCatSlice,
		expense: ExpenseSlice,
		interval: IntervalSlice,
		lesson: LessonSlice,
		storeEntry: StoreEntrySlice,
		storeCat: StoreCatSlice,
		item: ItemSlice,
		payrollEntry: PayrollEntrySlice,
		payrollCat: PayrollCatSlice,
		payrollItem: PayrollItemSlice,
		staffPayItem: StaffPayItemSlice,
		staffPayEntry: StaffPayEntrySlice,
		notification: NotificationSlice,
		message: MessageSlice,
		onlinePay: OnlinePaySlice,
		sub: SubSlice,
		online: OnlineSlice,
		pwa: PwaSlice,
		active: ActiveSlice,
	},
	preloadedState: persistedState,
});

store.subscribe(() => {
	SaveState(store.getState());
});

export default store;

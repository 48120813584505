import React from "react";
import { Trash, PencilSquare } from "react-bootstrap-icons";
import { Dropdown } from "react-bootstrap";
import swal from "sweetalert";
import { updateBook } from "../../../reducers/BookSlice";
import { useDispatch, useSelector } from "react-redux";
import { addLiveBook, updateLiveBook } from "../../../Network/BookApi";
const BookDisp = ({
  Cats,
  book,
  setShowModal,
  setType,
  setBook,
  setBookModal,
  GetStudent,
}) => {
  //redux dispatch
  const dispatch = useDispatch();

  const online = useSelector((state) => state.online.online);
  const User = useSelector((state) => state.auth.user);
  const ActiveCred = useSelector((state) => state.cred.activeCred);

  const GetCategory = (linker) => {
    let Category = Cats.find(
      (cat) => parseInt(cat.linker) === parseInt(linker)
    );
    return Category || { name: "none" };
  };

  const UpdateBook = async () => {
    let {
      credLinker,
      bookStatus,

      trace,

      deleted,
    } = book;

    trace = Date.now();
    credLinker = ActiveCred.linker;
    bookStatus = 0;

    updateLiveBook(
      { ...book, trace, credLinker, deleted, bookStatus },
      User.token,
      dispatch
    );
  };

  const ReturnBook = () => {
    swal({
      title: `Return ${book.bookCode} ${book.name}`,
      text: `${GetStudent(book.studentLinker).studentReg} ${
        GetStudent(book.studentLinker).firstname
      } ${GetStudent(book.studentLinker).lastname} ${
        GetStudent(book.studentLinker).surname
      }`,
      icon: "success",
      buttons: true,
    })
      .then((willReturn) => {
        if (willReturn) {
          UpdateBook();
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <tr className="item">
      <td>{book.bookCode}</td>
      <td>{book.name}</td>
      <td>{book.author}</td>
      <td>
        {parseInt(book.bookStatus) === 0 ? (
          <span className="text-success">Available</span>
        ) : (
          <Dropdown>
            <Dropdown.Toggle variant="transparent">Issued</Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <strong>Student :</strong>{" "}
                {GetStudent(book.studentLinker).studentReg}{" "}
                {GetStudent(book.studentLinker).firstname}{" "}
                {GetStudent(book.studentLinker).lastname}{" "}
                {GetStudent(book.studentLinker).surname}
              </Dropdown.Item>
              <Dropdown.Item>
                <strong>Date :</strong>{" "}
                {new Date(parseInt(book.trace)).toLocaleDateString()}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </td>
      {Cats.length > 0 ? <td>{GetCategory(book.catLinker).name}</td> : null}
      <td>{book.details}</td>
      {parseInt(ActiveCred.admin) === 1 ||
      parseInt(ActiveCred.library) === 1 ||
      parseInt(ActiveCred.tutor) === 1 ? (
        <td>
          {parseInt(book.bookStatus) === 0 ? (
            <small
              className="text-primary btn-link mx-1"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setBook({ ...book, studentLinker: "" });
                setBookModal(true);
              }}
            >
              Issue
            </small>
          ) : (
            <small
              className="text-primary btn-link mx-1"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setBook(book);
                ReturnBook();
              }}
            >
              Return
            </small>
          )}
          <PencilSquare
            onClick={() => {
              setShowModal(true);
              setType("edit");
              setBook({ ...book });
            }}
            className="text-info mx-1"
            style={{ cursor: "pointer" }}
          />

          <Trash
            onClick={() => {
              setShowModal(true);
              setType("delete");
              setBook({ ...book });
            }}
            className="text-danger mx-1"
            style={{ cursor: "pointer" }}
          />
        </td>
      ) : null}
    </tr>
  );
};

export default BookDisp;

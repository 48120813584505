import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { CashCoin } from "react-bootstrap-icons";

const FinanceNav = ({ setDrawer }) => {
	const navigate = useNavigate();
	const inst = useSelector((state) => state.inst.activeInst);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	return (
		<>
			<Dropdown style={{ width: "100%" }}>
				<Dropdown.Toggle className="nav-link" variant="transparent">
					<CashCoin /> Finance
				</Dropdown.Toggle>
				<Dropdown.Menu variant="dark">
					<Dropdown.Item>
						<p
							className="nav-link"
							onClick={() => {
								window.scroll(0, 0);
								setDrawer(false);
								navigate(`invoices`);
							}}
						>
							Invoices
						</p>
					</Dropdown.Item>
					<Dropdown.Item>
						<p
							className="nav-link"
							onClick={() => {
								window.scroll(0, 0);
								setDrawer(false);
								navigate(`payments`);
							}}
						>
							Payments
						</p>
					</Dropdown.Item>
					<Dropdown.Item>
						<p
							className="nav-link"
							onClick={() => {
								window.scroll(0, 0);
								setDrawer(false);
								navigate(`expensecats`);
							}}
						>
							Expense Categories
						</p>
					</Dropdown.Item>
					<Dropdown.Item>
						<p
							className="nav-link"
							onClick={() => {
								window.scroll(0, 0);
								setDrawer(false);
								navigate(`expenses`);
							}}
						>
							Expenses
						</p>
					</Dropdown.Item>
					{parseInt(ActiveCred.admin) === 1 ? (
						<Dropdown.Item>
							<p
								className="nav-link"
								onClick={() => {
									window.scroll(0, 0);
									setDrawer(false);
									navigate(`income-statement`);
								}}
							>
								Profit $ Loss
							</p>
						</Dropdown.Item>
					) : null}
				</Dropdown.Menu>
			</Dropdown>
			<hr />
		</>
	);
};

export default FinanceNav;

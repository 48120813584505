import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Trash, PencilSquare } from "react-bootstrap-icons";
import ExamCrud from "./ExamCrud";
import { PlainList } from "flatlist-react";
import GeneratePdf from "../../../Utils/GeneratePdf";

const RankingView = ({ exam }) => {
  const Activities = useSelector((state) => state.activity.activities);
  const Structures = useSelector((state) => state.structure.structures);

  const Students = useSelector((state) => state.cred.creds);
  const Subjects = useSelector((state) => state.subject.subjects);
  const Grades = useSelector((state) => state.grade.grades);
  const [ActiveExam, setActiveExam] = useState({ student: {} });
  const [ExamCrudModal, setExamCrudModal] = useState(false);
  const [Type, setType] = useState("edit");

  const Exams = useSelector((state) => state.exam.exams).filter(
    (Exam) =>
      Exam.deleted == "0" &&
      (parseInt(Exam.levelLinker) === parseInt(exam.level) ||
        exam.level === "") &&
      (parseInt(Exam.periodLinker) === parseInt(exam.period) ||
        exam.period === "") &&
      (parseInt(Exam.facultyLinker) === parseInt(exam.faculty) ||
        exam.faculty === "") &&
      (parseInt(Exam.yearLinker) === parseInt(exam.year) || exam.year === "")
  );

  const SubjectLinkers = [...new Set(Exams.map((Exam) => Exam.subjectLinker))];

  const GetStudent = (linker) => {
    let student = Students.find(
      (student) => parseInt(student.linker) === parseInt(linker)
    );
    return student || { studentReg: "unknown" };
  };

  const GetGrade = (score) => {
    for (const grade of Grades) {
      if (score >= grade.min && score <= grade.max) {
        return grade.grade;
      }
    }
    return "";
  };

  const StudentLinkers = [...new Set(Exams.map((Exam) => Exam.studentLinker))]
    .map((linker) => {
      let StudentExams = Exams.filter(
        (Exam) => parseInt(Exam.studentLinker) == parseInt(linker)
      );
      let TotalScore = StudentExams.reduce((a, b) => +a + +b.score, 0);
      let MaxScore = StudentExams.reduce((a, b) => +a + +b.maxScore, 0);
      let PercentScore = (TotalScore * 100) / MaxScore;

      return {
        studentLinker: linker,
        totalScore: TotalScore,
        maxScore: MaxScore,
        percentScore: PercentScore,
        exams: StudentExams,
        grade: GetGrade(PercentScore),
        student: GetStudent(linker),
      };
    })
    .sort((a, b) => b.percentScore - a.percentScore);

  const GetSubject = (linker) => {
    let subject = Subjects.find(
      (subject) => parseInt(subject.linker) === parseInt(linker)
    );

    return subject || { name: "unknown" };
  };

  const GetStudentSubject = (studentExams, subjectLinker) => {
    let Exams = studentExams.filter(
      (exam) => parseInt(exam.subjectLinker) === parseInt(subjectLinker)
    );

    let totalScore = Exams.reduce((a, b) => +a + +b.score, 0);
    let maxScore = Exams.reduce((a, b) => +a + +b.maxScore, 0);
    let percentScore = (totalScore * 100) / maxScore;

    return {
      exams: Exams,
      totalScore,
      maxScore,
      percentScore,
      grade: GetGrade(percentScore),
    };
  };

  const GetActivity = (linker) => {
    let Activity = Activities.find(
      (activity) => parseInt(activity.linker) === parseInt(linker)
    );

    return Activity || { name: "unknown" };
  };

  const GetStructure = (linker) => {
    let Structure = Structures.find(
      (structure) => parseInt(structure.linker) === parseInt(linker)
    );

    return Structure || {};
  };

  return (
    <div>
      <div id="ranking-view">
        <p className="text-center h6 text-capitalize">
          {GetStructure(exam.year).name} {GetStructure(exam.faculty).name}{" "}
          {GetStructure(exam.level).name} {GetStructure(exam.period).name}
        </p>
        <table className="table table-sm table-striped">
          <thead>
            <tr>
              <th>{StudentLinkers.length}</th>
              <th>Reg/Adm No</th>
              <th>Name</th>
              {SubjectLinkers.map((subject, index) => (
                <th key={index}>{GetSubject(subject).name}</th>
              ))}
              <th>AVG</th>
            </tr>
          </thead>
          <tbody>
            <PlainList
              list={StudentLinkers}
              renderOnScroll
              renderItem={(student, index) => (
                <tr key={student.student.linker}>
                  <th>{parseInt(index) + 1}.</th>
                  <td>{student.student.studentReg}</td>
                  <td>
                    {student.student.firstname} {student.student.lastname}{" "}
                    {student.student.surname}
                  </td>
                  {SubjectLinkers.map((subject, subIndex) => (
                    <td key={subIndex}>
                      <Dropdown>
                        <Dropdown.Toggle variant="transparent">
                          {`${parseInt(
                            GetStudentSubject(student.exams, subject)
                              .percentScore
                          )}`}{" "}
                          <strong>{`${
                            GetStudentSubject(student.exams, subject).grade
                          }`}</strong>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <table className="table table-sm table-bordered">
                            <thead>
                              <tr>
                                <td>Name</td>
                                <td>Score</td>
                                <td>Max</td>
                                <td>Comment</td>
                                <td>Grade</td>
                              </tr>
                            </thead>
                            <tbody>
                              {GetStudentSubject(
                                student.exams,
                                subject
                              ).exams.map((exam, examIndex) => (
                                <tr key={examIndex}>
                                  <td>
                                    {GetActivity(exam.activityLinker).name}
                                  </td>
                                  <td>{exam.score}</td>
                                  <td>{exam.maxScore}</td>
                                  <td>{exam.comment}</td>
                                  <td>{exam.grade}</td>
                                  <td>
                                    {" "}
                                    <PencilSquare
                                      onClick={() => {
                                        setExamCrudModal(true);
                                        setType("edit");
                                        setActiveExam({
                                          ...exam,
                                          student: student.student,
                                        });
                                      }}
                                      style={{ cursor: "pointer" }}
                                      className="text-info m-1"
                                    />
                                    <Trash
                                      onClick={() => {
                                        setExamCrudModal(true);
                                        setType("delete");
                                        setActiveExam({
                                          ...exam,
                                          student: student.student,
                                        });
                                      }}
                                      style={{ cursor: "pointer" }}
                                      className="text-danger m-1"
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  ))}
                  <td>
                    {parseInt(student.percentScore)}{" "}
                    <strong>{student.grade}</strong>
                  </td>
                </tr>
              )}
            />
            {}
          </tbody>
        </table>
      </div>
      <GeneratePdf
        id="ranking-view"
        name={`${GetStructure(exam.year).name} - ${
          GetStructure(exam.faculty).name
        }-
          ${GetStructure(exam.level).name} - ${GetStructure(exam.period).name}`}
      ></GeneratePdf>
      <ExamCrud
        type={Type}
        ExamCrudModal={ExamCrudModal}
        setExamCrudModal={setExamCrudModal}
        Exam={ActiveExam}
        setExam={setActiveExam}
      ></ExamCrud>
    </div>
  );
};

export default RankingView;

import React, { useState, useEffect } from "react";
import {
	PersonAdd,
	CardHeading,
	ArrowRepeat,
	Envelope,
} from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import StudentCrud from "./StudentCrud";
import StudentDisp from "./StudentDisp";
import TransitionStudents from "./TransitionStudents";
import BulkInvoice from "../Finance/Invoices/BulkInvoice";
import StudentDetails from "./StudentDetails";
import { FetchStudents } from "../../Network/CredApi";
import { PlainList } from "flatlist-react";
import GeneratePdf from "../../Utils/GeneratePdf";
import SingleSms from "../Admin/Sms/SingleSms";
import { useNavigate } from "react-router-dom";
const Students = () => {
	useEffect(() => {
		FetchStudents(ActiveCred.instLinker, User.token, dispatch, online);
	}, []);

	//redux dispatch
	const dispatch = useDispatch();
	const Navigate = useNavigate();

	const [ShowModal, setShowModal] = useState(false);
	const [SmsModal, setSmsModal] = useState(false);
	const [DetailsModal, setDetailsModal] = useState(false);
	const [TransitionModal, setTransitionModal] = useState(false);
	const [InvoiceModal, setInvoiceModal] = useState(false);
	const [Search, setSearch] = useState("");
	const [Student, setStudent] = useState({
		firstname: "",
		lastname: "",
		surname: "",
		studentReg: "",
		contact: "",
		email: "",
		level: "",
		year: "",
		faculty: "",
		period: "",
		guardianOneName: "",
		guardianOneEmail: "",
		guardianOneContact: "",
		guardianTwoName: "",
		guardianTwoEmail: "",
		guardianTwoContact: "",
	});
	const [Level, setLevel] = useState("all");
	const [Period, setPeriod] = useState("all");
	const [Faculty, setFaculty] = useState("all");
	const [Year, setYear] = useState("all");
	const [Type, setType] = useState("add");

	const online = useSelector((state) => state.online.online);
	const User = useSelector((state) => state.auth.user);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const ActiveInst = useSelector((state) => state.inst.activeInst);
	const Structures = useSelector((state) => state.structure.structures);
	const Invoices = useSelector((state) => state.invoice.invoices).filter(
		(record) => record.deleted == 0
	);
	const Payments = useSelector((state) => state.payment.payments).filter(
		(record) => record.deleted == 0
	);

	const Levels = Structures.filter(
		(structure) =>
			structure.type === "level" && parseInt(structure.deleted) === 0
	);

	const Periods = Structures.filter(
		(structure) =>
			structure.type === "period" && parseInt(structure.deleted) === 0
	);

	const Faculties = Structures.filter(
		(structure) =>
			structure.type === "faculty" && parseInt(structure.deleted) === 0
	);

	const Years = Structures.filter(
		(structure) =>
			structure.type === "academic-year" && parseInt(structure.deleted) === 0
	);

	const Students = useSelector((state) => state.cred.creds)
		.filter(
			(student) =>
				student.deleted == "0" &&
				student.student == 1 &&
				(student.studentReg.toLowerCase().search(Search.toLowerCase()) > -1 ||
					student.firstname.toLowerCase().search(Search.toLowerCase()) > -1 ||
					student.lastname.toLowerCase().search(Search.toLowerCase()) > -1 ||
					student.surname.toLowerCase().search(Search.toLowerCase()) > -1 ||
					student.email.toLowerCase().search(Search.toLowerCase()) > -1 ||
					student.contact.toLowerCase().search(Search.toLowerCase()) > -1 ||
					Search === "") &&
				(parseInt(student.level) === parseInt(Level) || Level === "all") &&
				(parseInt(student.period) === parseInt(Period) || Period === "all") &&
				(parseInt(student.faculty) === parseInt(Faculty) ||
					Faculty === "all") &&
				(parseInt(student.year) === parseInt(Year) || Year === "all")
		)
		.sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

	return (
		<div>
			<div id="students">
				<p className="text-center h6 text-primary text-capitalize">
					{Students.length} Students
				</p>
				<table className="table table-sm">
					<thead className="thead-dark">
						<tr>
							<td colSpan={2}>
								<input
									type={"text"}
									placeholder={`Search Student`}
									value={Search}
									onChange={(e) => setSearch(e.target.value)}
									className="rounded form-control"
								/>
							</td>
							<td colSpan={2}>
								{parseInt(ActiveCred.admin) === 1 ||
								parseInt(ActiveCred.tutor) === 1 ? (
									<button
										className="btn btn-sm btn-outline-secondary"
										onClick={() => {
											setShowModal(true);
											setType("add");
											setStudent({
												firstname: "",
												lastname: "",
												surname: "",
												studentReg: "",
												contact: "",
												email: "",
												level: "",
												year: "",
												faculty: "",
												period: "",
												guardianOneName: "",
												guardianOneEmail: "",
												guardianOneContact: "",
												guardianTwoName: "",
												guardianTwoEmail: "",
												guardianTwoContact: "",
											});
										}}
									>
										<PersonAdd /> Add Student
									</button>
								) : null}
							</td>
							<td>
								{parseInt(ActiveCred.admin) === 1 ||
								parseInt(ActiveCred.finance) === 1 ? (
									<button
										className="btn btn-sm btn-outline-secondary"
										onClick={() => {
											setInvoiceModal(true);
										}}
									>
										<CardHeading /> Bulk Invoice
									</button>
								) : null}
							</td>
							<td>
								{parseInt(ActiveCred.admin) === 1 &&
								ActiveInst.sub === "Kenya" ? (
									<button
										className="btn btn-sm btn-outline-secondary"
										onClick={() => {
											Navigate(`/${ActiveInst.name}/bulk-sms`);
										}}
									>
										<Envelope /> Bulk Sms
									</button>
								) : null}
							</td>
							<td colSpan={2}>
								{parseInt(ActiveCred.admin) === 1 ||
								parseInt(ActiveCred.tutor) === 1 ? (
									<button
										className="btn btn-sm btn-outline-secondary"
										onClick={() => {
											setTransitionModal(true);
										}}
									>
										<ArrowRepeat /> Transition
									</button>
								) : null}
							</td>
						</tr>
						<tr>
							<th>
								<small>Reg/Adm</small>
							</th>
							<th>Name</th>

							{Years.length > 0 ? (
								<th>
									<select
										className="form-control rounded"
										onChange={(e) => setYear(e.target.value)}
									>
										<option value={"all"}>All AcYears</option>
										{Years.map((Year, index) => (
											<option key={index} value={Year.linker}>
												{Year.name}
											</option>
										))}
									</select>
								</th>
							) : null}
							{Faculties.length > 0 ? (
								<td>
									<select
										className="form-control rounded"
										onChange={(e) => setFaculty(e.target.value)}
									>
										<option value={"all"}>All Faculties</option>
										{Faculties.map((faculty, index) => (
											<option key={index} value={faculty.linker}>
												{faculty.name}
											</option>
										))}
									</select>
								</td>
							) : null}

							{Levels.length > 0 ? (
								<td>
									<select
										className="form-control rounded"
										onChange={(e) => setLevel(e.target.value)}
									>
										<option value={"all"}>All Levels</option>
										{Levels.map((level, index) => (
											<option key={index} value={level.linker}>
												{level.name}
											</option>
										))}
									</select>
								</td>
							) : null}
							{Periods.length > 0 ? (
								<td>
									<select
										className="form-control rounded"
										onChange={(e) => setPeriod(e.target.value)}
									>
										<option value={"all"}>All Periods</option>
										{Periods.map((period, index) => (
											<option key={index} value={period.linker}>
												{period.name}
											</option>
										))}
									</select>
								</td>
							) : null}
							<th>
								<small>
									{" "}
									Bal:
									{Invoices.filter((invoice) =>
										Students.some(
											(student) =>
												parseInt(student.linker) ===
												parseInt(invoice.studentLinker)
										)
									).reduce((a, b) => +a + +b.amount, 0) -
										Payments.filter((payment) =>
											Students.some(
												(student) =>
													parseInt(student.linker) ===
													parseInt(payment.studentLinker)
											)
										).reduce((a, b) => +a + +b.amount, 0)}
								</small>
							</th>
						</tr>
					</thead>
					<tbody>
						<PlainList
							list={Students}
							renderOnScroll
							renderItem={(student) => (
								<StudentDisp
									key={student.linker}
									student={student}
									setStudent={setStudent}
									setShowModal={setShowModal}
									setType={setType}
									setDetailsModal={setDetailsModal}
									setSmsModal={setSmsModal}
								></StudentDisp>
							)}
						/>
					</tbody>
				</table>{" "}
			</div>
			<GeneratePdf id={"students"} name={`students`}></GeneratePdf>
			<StudentCrud
				setShowModal={setShowModal}
				ShowModal={ShowModal}
				student={Student}
				setStudent={setStudent}
				type={Type}
			></StudentCrud>
			<SingleSms
				Recipients={[
					{
						contact: Student.contact,
						name: `${Student.firstname} ${Student.lastname}`,
						student: 1,
					},
					{
						contact: Student.guardianOneContact,
						name: Student.guardianOneName,
						guardianOne: 1,
					},
					{
						contact: Student.guardianTwoContact,
						name: Student.guardianTwoName,
						guardianTwo: 1,
					},
				]}
				SmsModal={SmsModal}
				setSmsModal={setSmsModal}
			></SingleSms>
			<TransitionStudents
				setTransitionModal={setTransitionModal}
				TransitionModal={TransitionModal}
			></TransitionStudents>
			<BulkInvoice
				setInvoiceModal={setInvoiceModal}
				InvoiceModal={InvoiceModal}
			></BulkInvoice>
			<StudentDetails
				setDetailsModal={setDetailsModal}
				DetailsModal={DetailsModal}
				student={Student}
			></StudentDetails>
		</div>
	);
};

export default Students;

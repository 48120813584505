import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { transitionLiveStudents } from "../../Network/CredApi";

const TransitionStudents = (props) => {
  const [FromLevel, setFromLevel] = useState("");
  const [FromPeriod, setFromPeriod] = useState("");
  const [FromFaculty, setFromFaculty] = useState("");
  const [FromYear, setFromYear] = useState("");
  const [ToLevel, setToLevel] = useState("");
  const [ToPeriod, setToPeriod] = useState("");
  const [ToFaculty, setToFaculty] = useState("");
  const [ToYear, setToYear] = useState("");

  const Structures = useSelector((state) => state.structure.structures);

  const Levels = Structures.filter(
    (structure) =>
      structure.type === "level" && parseInt(structure.deleted) === 0
  );

  const Periods = Structures.filter(
    (structure) =>
      structure.type === "period" && parseInt(structure.deleted) === 0
  );

  const Faculties = Structures.filter(
    (structure) =>
      structure.type === "faculty" && parseInt(structure.deleted) === 0
  );

  const Years = Structures.filter(
    (structure) =>
      structure.type === "academic-year" && parseInt(structure.deleted) === 0
  );
  //redux dispatch
  const dispatch = useDispatch();

  const online = useSelector((state) => state.online.online);
  const User = useSelector((state) => state.auth.user);
  const ActiveCred = useSelector((state) => state.cred.activeCred);

  const ResetInputs = () => {
    setFromFaculty("");
    setToFaculty("");
    setFromLevel("");
    setToLevel("");
    setFromYear("");
    setToYear("");
    setFromPeriod("");
    setToPeriod("");
  };

  const UpdateStudents = async () => {
    let trace = Date.now();

    transitionLiveStudents(
      {
        FromYear,
        FromFaculty,
        FromLevel,
        FromPeriod,
        ToYear,
        ToFaculty,
        ToLevel,
        ToPeriod,
        trace,
        instLinker: ActiveCred.instLinker,
      },
      "student/transition",
      User.token,
      dispatch
    );

    ResetInputs();
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    await UpdateStudents();
    props.setTransitionModal(false);
  };
  return (
    <div>
      <Modal
        show={props.TransitionModal}
        onHide={() => {
          props.setTransitionModal(false);
          ResetInputs();
        }}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Transition Students</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={HandleSubmit}>
            <div className="row">
              {Years.length > 0 ? (
                <div className="col-md-6">
                  <label>From AcYear : </label>
                  <select
                    className="form-control rounded"
                    onChange={(e) => setFromYear(e.target.value)}
                    required
                    value={FromYear}
                  >
                    <option value={""}>Select From AcYear</option>
                    {Years.map((Year, index) => (
                      <option key={index} value={Year.linker}>
                        {Year.name}
                      </option>
                    ))}
                  </select>
                  <hr />
                </div>
              ) : null}
              {FromYear != "" ? (
                <div className="col-md-6">
                  <label>To AcYear : </label>
                  <select
                    className="form-control rounded"
                    onChange={(e) => setToYear(e.target.value)}
                    required
                    value={ToYear}
                  >
                    <option value={""}>Select New AcYear</option>
                    {Years.map((Year, index) => (
                      <option key={index} value={Year.linker}>
                        {Year.name}
                      </option>
                    ))}
                  </select>
                  <hr />
                </div>
              ) : null}
              {Faculties.length > 0 ? (
                <div className="col-md-6">
                  <label>From Faculty : </label>
                  <select
                    className="form-control rounded"
                    onChange={(e) => setFromFaculty(e.target.value)}
                    value={FromFaculty}
                    required
                  >
                    <option value={""}>Select From Faculty</option>
                    {Faculties.map((faculty, index) => (
                      <option key={index} value={faculty.linker}>
                        {faculty.name}
                      </option>
                    ))}
                  </select>
                  <hr />
                </div>
              ) : null}
              {FromFaculty != "" ? (
                <div className="col-md-6">
                  <label>To Faculty : </label>
                  <select
                    className="form-control rounded"
                    onChange={(e) => setToFaculty(e.target.value)}
                    value={ToFaculty}
                    required
                  >
                    <option value={""}>Select New Faculty</option>
                    {Faculties.map((faculty, index) => (
                      <option key={index} value={faculty.linker}>
                        {faculty.name}
                      </option>
                    ))}
                  </select>
                  <hr />
                </div>
              ) : null}

              {Levels.length > 0 ? (
                <div className="col-md-6">
                  <label>From Level : </label>
                  <select
                    className="form-control rounded"
                    onChange={(e) => setFromLevel(e.target.value)}
                    value={FromLevel}
                    required
                  >
                    <option value={""}>Select From Level</option>
                    {Levels.map((level, index) => (
                      <option key={index} value={level.linker}>
                        {level.name}
                      </option>
                    ))}
                  </select>
                  <hr />
                </div>
              ) : null}
              {FromLevel != "" ? (
                <div className="col-md-6">
                  <label>To Level : </label>
                  <select
                    className="form-control rounded"
                    onChange={(e) => setToLevel(e.target.value)}
                    value={ToLevel}
                    required
                  >
                    <option value={""}>Select New Level</option>
                    {Levels.map((level, index) => (
                      <option key={index} value={level.linker}>
                        {level.name}
                      </option>
                    ))}
                  </select>
                  <hr />
                </div>
              ) : null}
              {Periods.length > 0 ? (
                <div className="col-md-6">
                  <label>From Period : </label>
                  <select
                    className="form-control rounded"
                    onChange={(e) => setFromPeriod(e.target.value)}
                    value={FromPeriod}
                    required
                  >
                    <option value={""}>Select From Period</option>
                    {Periods.map((period, index) => (
                      <option key={index} value={period.linker}>
                        {period.name}
                      </option>
                    ))}
                  </select>
                  <hr />
                </div>
              ) : null}
              {FromPeriod != "" ? (
                <div className="col-md-6">
                  <label>To Period : </label>
                  <select
                    className="form-control rounded"
                    onChange={(e) => setToPeriod(e.target.value)}
                    value={ToPeriod}
                    required
                  >
                    <option value={""}>Select New Period</option>
                    {Periods.map((period, index) => (
                      <option key={index} value={period.linker}>
                        {period.name}
                      </option>
                    ))}
                  </select>
                  <hr />
                </div>
              ) : null}
            </div>

            <div className="d-flex justify-content-around mb-2">
              <Button variant="primary" type="submit">
                Save
              </Button>

              <Button
                variant="secondary"
                type="button"
                onClick={() => {
                  props.setTransitionModal(false);
                  ResetInputs();
                }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>{" "}
    </div>
  );
};

export default TransitionStudents;

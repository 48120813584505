import swal from "sweetalert";
import { fetchMessages, updateMessage } from "../reducers/MessageSlice";
import { ApiUrl } from "./Urls";

export const FetchMessages = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	if (from === "fetch") {
		FetchLiveMessages(token, instLinker, online, dispatch);
	} else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
		dispatch(updateMessage(payload));
	}
};

export const SendMessages = (
	token,
	instLinker,
	messages,
	dispatch,
	setLoading
) => {
	setLoading(true);
	fetch(`${ApiUrl}/sms/send`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			instLinker,
			messages,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			setLoading(false);
			if (res.status === 200) {
				swal({
					icon: "success",
					title: "Message Sent",
					text: "Message Sent Successfully",
					timer: 3000,
				});
				dispatch(updateMessage(res.newsms));
			}
		})
		.catch(() => {
			setLoading(false);
		});
};

export const FetchLiveMessages = (token, instLinker, online, dispatch) => {
	fetch(`${ApiUrl}/sms/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(fetchMessages(res.data));
			}
		})
		.catch(() => {});
};

export const PurchaseMessages = (
	token,
	instLinker,
	number,
	amount,
	setLoading
) => {
	setLoading(true);
	fetch(`${ApiUrl}/sms/purchase`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			instLinker,
			number,
			amount,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			setLoading(false);
			if (res.status === 200) {
				swal({
					title: "Transaction Initiated",
					text: `Check your phone to complete the transaction to DAZILA SYSTEMS account ${parseInt(
						parseInt(`${instLinker}`.split("").reverse().join("")) / 1000
					)}}`,
					icon: "success",
				});
			} else {
				swal({
					title: "Failed to connect",
					text: "Confirm your phone number is correct and try again",
					icon: "warning",
				});
			}
		})
		.catch(() => {
			setLoading(false);
			swal({
				title: "Failed to connect",
				icon: "warning",
				text: "Check your network and try again",
			});
		});
};

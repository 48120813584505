import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { Shop } from "react-bootstrap-icons";

const StoreNav = ({ setDrawer }) => {
  const navigate = useNavigate();
  const inst = useSelector((state) => state.inst.activeInst);
  return (
    <>
      <Dropdown style={{ width: "100%" }}>
        <Dropdown.Toggle className="nav-link" variant="transparent">
          <Shop /> Inventory
        </Dropdown.Toggle>
        <Dropdown.Menu variant="dark">
          <Dropdown.Item>
            <p
              className="nav-link"
              onClick={() => {
                window.scroll(0, 0);
                setDrawer(false);
                navigate(`store-categories`);
              }}
            >
              Categories
            </p>
          </Dropdown.Item>
          <Dropdown.Item>
            <p
              className="nav-link"
              onClick={() => {
                window.scroll(0, 0);
                setDrawer(false);
                navigate(`store-items`);
              }}
            >
              Items
            </p>
          </Dropdown.Item>
          <Dropdown.Item>
            <p
              className="nav-link"
              onClick={() => {
                window.scroll(0, 0);
                setDrawer(false);
                navigate(`store-entries`);
              }}
            >
              Entries
            </p>
          </Dropdown.Item>{" "}
        </Dropdown.Menu>
      </Dropdown>
      <hr />
    </>
  );
};

export default StoreNav;

import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RegisterLiveCred, updateLiveCred } from "../../../Network/CredApi";

const StaffCrud = (props) => {
  //redux dispatch
  const dispatch = useDispatch();

  const online = useSelector((state) => state.online.online);
  const User = useSelector((state) => state.auth.user);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const [Loading, setLoading] = useState(false);

  //add staff
  const AddStaff = async () => {
    let linker = Date.now();

    await RegisterLiveCred(
      {
        ...props.staff,
        linker,
        instLinker: ActiveCred.linker,
        credLinker: ActiveCred.linker,
        live: 1,
        status: 0,
        trace: linker,
        deleted: 0,
      },
      "staff/add",
      User.token,
      dispatch
    );
  };

  //edit staff
  const EditStaff = async () => {
    let { credLinker, trace, deleted } = props.staff;

    trace = Date.now();
    credLinker = ActiveCred.linker;
    deleted = props.type === "delete" ? 1 : deleted;

    await updateLiveCred(
      { ...props.staff, trace, credLinker, deleted },
      "staff/edit",
      User.token,
      dispatch
    );
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (props.type === "add") {
      await AddStaff();
    } else {
      await EditStaff();
    }
    setLoading(false);
    props.setShowModal(false);
  };

  return (
    <div>
      <Modal
        show={props.ShowModal}
        onHide={() => props.setShowModal(false)}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <strong className="text-capitalize text-center">
            {props.type} Staff:{" "}
          </strong>
          <br />
          {props.type === "delete" ? null : (
            <small style={{ fontSize: "16" }}>Fill Whats Applicable</small>
          )}
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={HandleSubmit}>
            <div className="form-group">
              <div className="row">
                <div className="col-md-6">
                  <label className="mb-2 text-capitalize">Staff Reg No:</label>
                  <input
                    className="rounded form-control"
                    placeholder={`Enter Stafff Reg No`}
                    value={props.staff.staffReg}
                    onChange={(e) =>
                      props.setStaff({
                        ...props.staff,
                        staffReg: e.target.value,
                      })
                    }
                    readOnly={props.type === "delete" ? true : false}
                  />
                  <hr />
                </div>
                <div className="col-md-6">
                  <label className="mb-2 text-capitalize">Surname:</label>
                  <input
                    className="rounded form-control"
                    placeholder={`Enter Surname`}
                    value={props.staff.surname}
                    onChange={(e) =>
                      props.setStaff({
                        ...props.staff,
                        surname: e.target.value,
                      })
                    }
                    readOnly={props.type === "delete" ? true : false}
                  />
                  <hr />
                </div>
                <div className="col-md-6">
                  <label className="mb-2 text-capitalize">FirstName:</label>
                  <input
                    className="rounded form-control"
                    placeholder={`Enter firstname`}
                    value={props.staff.firstname}
                    onChange={(e) =>
                      props.setStaff({
                        ...props.staff,
                        firstname: e.target.value,
                      })
                    }
                    readOnly={props.type === "delete" ? true : false}
                  />
                  <hr />
                </div>
                <div className="col-md-6">
                  <label className="mb-2 text-capitalize">LastName:</label>
                  <input
                    className="rounded form-control"
                    placeholder={`Enter lastname`}
                    value={props.staff.lastname}
                    onChange={(e) =>
                      props.setStaff({
                        ...props.staff,
                        lastname: e.target.value,
                      })
                    }
                    readOnly={props.type === "delete" ? true : false}
                  />
                  <hr />
                </div>
                <div className="col-md-6">
                  <label className="mb-2 text-capitalize">Email:</label>
                  <input
                    className="rounded form-control"
                    placeholder={`Enter email`}
                    value={props.staff.email}
                    onChange={(e) =>
                      props.setStaff({
                        ...props.staff,
                        email: e.target.value.toLowerCase(),
                      })
                    }
                    type="email"
                    readOnly={props.type === "delete" ? true : false}
                  />
                  <hr />
                </div>
                <div className="col-md-6">
                  <label className="mb-2 text-capitalize">Contact:</label>
                  <input
                    className="rounded form-control"
                    placeholder={`Enter contact`}
                    value={props.staff.contact}
                    onChange={(e) =>
                      props.setStaff({
                        ...props.staff,
                        contact: e.target.value,
                      })
                    }
                    readOnly={props.type === "delete" ? true : false}
                  />
                  <hr />
                </div>

                <div className="col-md-12">
                  <label className="mb-2 text-capitalize">Credentials:</label>
                  <div className="row">
                    <div className="col-md-6 d-flex justify-content-around">
                      <span>Admin</span>{" "}
                      <input
                        type="checkbox"
                        onChange={() =>
                          props.setStaff({
                            ...props.staff,
                            admin: props.staff.admin == 1 ? "0" : 1,
                          })
                        }
                        checked={props.staff.admin == 1 ? true : false}
                        readOnly={props.type === "delete" ? true : false}
                      />
                    </div>
                    <div className="col-md-6 d-flex justify-content-around">
                      <span>Academics</span>{" "}
                      <input
                        type="checkbox"
                        onChange={() =>
                          props.setStaff({
                            ...props.staff,
                            tutor: props.staff.tutor == 1 ? "0" : 1,
                          })
                        }
                        checked={props.staff.tutor == 1 ? true : false}
                        readOnly={props.type === "delete" ? true : false}
                      />
                    </div>
                    <div className="col-md-6 d-flex justify-content-around">
                      <span>Finance</span>{" "}
                      <input
                        type="checkbox"
                        onChange={() =>
                          props.setStaff({
                            ...props.staff,
                            finance: props.staff.finance == 1 ? "0" : 1,
                          })
                        }
                        checked={props.staff.finance == 1 ? true : false}
                        readOnly={props.type === "delete" ? true : false}
                      />
                    </div>
                    <div className="col-md-6 d-flex justify-content-around">
                      <span>Library</span>{" "}
                      <input
                        type="checkbox"
                        onChange={() =>
                          props.setStaff({
                            ...props.staff,
                            library: props.staff.library == 1 ? "0" : 1,
                          })
                        }
                        checked={props.staff.library == 1 ? true : false}
                        readOnly={props.type === "delete" ? true : false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-around mb-2">
              {Loading ? (
                <span className="spinner-border text-primary"></span>
              ) : props.type === "delete" ? (
                <Button variant="danger" type="submit">
                  Delete
                </Button>
              ) : (
                <Button variant="primary" type="submit">
                  Save
                </Button>
              )}
              <Button
                variant="secondary"
                type="button"
                onClick={() => props.setShowModal(false)}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default StaffCrud;

import React from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { Mortarboard } from "react-bootstrap-icons";

const AcademicsNav = ({ setDrawer }) => {
	const navigate = useNavigate();
	return (
		<>
			<Dropdown style={{ width: "100%" }}>
				<Dropdown.Toggle className="nav-link" variant="transparent">
					<Mortarboard /> Academics
				</Dropdown.Toggle>
				<Dropdown.Menu variant="dark">
					<Dropdown.Item>
						<p
							className="nav-link"
							onClick={() => {
								window.scroll(0, 0);
								setDrawer(false);
								navigate(`exams`);
							}}
						>
							Scores
						</p>
					</Dropdown.Item>
					<Dropdown.Item>
						<p
							className="nav-link"
							onClick={() => {
								window.scroll(0, 0);
								setDrawer(false);
								navigate(`timetable`);
							}}
						>
							TimeTable
						</p>
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
			<hr />
		</>
	);
};

export default AcademicsNav;

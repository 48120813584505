import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addLiveActivity,
  updateLiveActivity,
} from "../../../Network/ActivityApi";
import { fetchActivities } from "../../../reducers/ActivitySlice";

const ActivityCrud = (props) => {
  //redux dispatch
  const dispatch = useDispatch();

  const online = useSelector((state) => state.online.online);
  const User = useSelector((state) => state.auth.user);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Activities = useSelector((state) => state.activity.activities);
  const Structures = useSelector((state) => state.structure.structures);
  const [Loading, setLoading] = useState(false);

  const Levels = Structures.filter(
    (structure) =>
      structure.type === "level" && parseInt(structure.deleted) === 0
  );
  const Periods = Structures.filter(
    (structure) =>
      structure.type === "period" && parseInt(structure.deleted) === 0
  );
  const Faculties = Structures.filter(
    (structure) =>
      structure.type === "faculty" && parseInt(structure.deleted) === 0
  );

  const [Level, setLevel] = useState("all");
  const [Period, setPeriod] = useState("all");
  const [Faculty, setFaculty] = useState("all");
  const [Subject, setSubject] = useState("all");
  const [Entries, setEntries] = useState([]);

  const Subjects = useSelector((state) => state.subject.subjects).filter(
    (subject) =>
      (parseInt(subject.levelLinker) == Level || Level == "all") &&
      (parseInt(subject.periodLinker) == Period || Period == "all") &&
      (parseInt(subject.facultyLinker) == Faculty || Faculty == "all") &&
      parseInt(subject.deleted) === 0
  );

  //add activity
  const AddActivity = async (entry) => {
    let linker = Date.now();

    await addLiveActivity(
      {
        linker: entry.linker,
        name: props.activity.name,
        subjectLinker: entry.subjectLinker,
        periodLinker: entry.periodLinker,
        levelLinker: entry.levelLinker,
        facultyLinker: entry.facultyLinker,
        instLinker: ActiveCred.linker,
        credLinker: ActiveCred.linker,
        live: 1,
        status: 0,
        trace: linker,
        deleted: 0,
      },
      ActiveCred.instLinker,
      User.token,
      dispatch
    );
  };

  //edit activity
  const EditActivity = async () => {
    let {
      credLinker,

      trace,

      deleted,
    } = props.activity;

    trace = Date.now();
    credLinker = ActiveCred.linker;
    deleted = props.type === "delete" ? 1 : deleted;

    await updateLiveActivity(
      { ...props.activity, trace, credLinker, deleted },
      User.token,
      dispatch
    );
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (props.type === "add") {
      await Entries.map(async (entry) => await AddActivity(entry));

      setEntries([]);
    } else {
      await EditActivity();
    }
    setLoading(false);
    props.setActivity({
      ...props.activity,
      name: "",
    });
    setFaculty("all");
    setPeriod("all");
    setSubject("all");
    setLevel("all");
    props.setShowModal(false);
  };

  return (
    <div>
      <Modal
        show={props.ShowModal}
        onHide={() => {
          setEntries([]);
          props.setShowModal(false);
        }}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <p className="text-capitalize text-center">
              {props.type} Subject/Unit Assessment
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={HandleSubmit}>
            <div className="form-group">
              <label className="mb-2 text-capitalize">Assessment Name:</label>
              <input
                className="rounded form-control"
                placeholder={`enter assessment name`}
                value={props.activity.name}
                onChange={(e) =>
                  props.setActivity({
                    ...props.activity,
                    name: e.target.value,
                  })
                }
                required
                readOnly={props.type === "delete" ? true : false}
              />
              <hr />
            </div>
            {props.type === "add" ? (
              <div>
                <table className="table">
                  <thead>
                    <tr>
                      {Levels.length > 0 ? (
                        <td>
                          <select
                            className="form-control rounded"
                            onChange={(e) => setLevel(e.target.value)}
                          >
                            <option value={"all"}>All Levels</option>
                            {Levels.map((level, index) => (
                              <option key={index} value={level.linker}>
                                {level.name}
                              </option>
                            ))}
                          </select>
                        </td>
                      ) : null}
                      {Periods.length > 0 ? (
                        <td>
                          <select
                            className="form-control rounded"
                            onChange={(e) => setPeriod(e.target.value)}
                          >
                            <option value={"all"}>All Periods</option>
                            {Periods.map((period, index) => (
                              <option key={index} value={period.linker}>
                                {period.name}
                              </option>
                            ))}
                          </select>
                        </td>
                      ) : null}
                      {Faculties.length > 0 ? (
                        <td>
                          <select
                            className="form-control rounded"
                            onChange={(e) => setFaculty(e.target.value)}
                          >
                            <option value={"all"}>All Faculties</option>
                            {Faculties.map((faculty, index) => (
                              <option key={index} value={faculty.linker}>
                                {faculty.name}
                              </option>
                            ))}
                          </select>
                        </td>
                      ) : null}
                      {Subjects.length > 0 ? (
                        <td>
                          <select
                            className="form-control rounded"
                            onChange={(e) => setSubject(e.target.value)}
                          >
                            <option value={"all"}>All Subjects</option>
                            {Subjects.map((subject, index) => (
                              <option key={index} value={subject.linker}>
                                {subject.name}
                              </option>
                            ))}
                          </select>
                        </td>
                      ) : null}

                      <td>Check</td>
                    </tr>
                  </thead>
                  <tbody>
                    {Subjects.filter(
                      (subject) =>
                        Subject == "all" ||
                        parseInt(subject.linker) == parseInt(Subject)
                    ).map((subject, index) => (
                      <tr key={index}>
                        {Levels.length > 0 ? (
                          <td>
                            {
                              (
                                Levels.find(
                                  (level) =>
                                    parseInt(level.linker) ==
                                    parseInt(subject.levelLinker)
                                ) || { name: "none" }
                              ).name
                            }
                          </td>
                        ) : null}
                        {Periods.length > 0 ? (
                          <td>
                            {
                              (
                                Periods.find(
                                  (period) =>
                                    parseInt(period.linker) ==
                                    parseInt(subject.periodLinker)
                                ) || { name: "none" }
                              ).name
                            }
                          </td>
                        ) : null}
                        {Faculties.length > 0 ? (
                          <td>
                            {
                              (
                                Faculties.find(
                                  (faculty) =>
                                    parseInt(faculty.linker) ==
                                    parseInt(subject.facultyLinker)
                                ) || { name: "none" }
                              ).name
                            }
                          </td>
                        ) : null}
                        <td>{subject.name}</td>
                        <td>
                          <input
                            type="checkbox"
                            value={{
                              levelLinker: subject.levelLinker,
                              periodLinker: subject.periodLinker,
                              facultyLinker: subject.facultyLinker,
                              subjectLinker: subject.linker,
                            }}
                            onChange={() =>
                              Entries.findIndex(
                                (entry) =>
                                  entry.subjectLinker === subject.linker
                              ) === -1
                                ? setEntries([
                                    ...Entries,
                                    {
                                      subjectLinker: subject.linker,
                                      levelLinker: subject.levelLinker,
                                      periodLinker: subject.periodLinker,
                                      facultyLinker: subject.facultyLinker,
                                      linker: Date.now(),
                                      name: props.activity.name,
                                      deleted: 0,
                                      instLinker: ActiveCred.instLinker,
                                      credLinker: ActiveCred.linker,
                                      id: 0,
                                      createdAt: 0,
                                      updatedAt: 0,
                                    },
                                  ])
                                : setEntries(
                                    Entries.filter(
                                      (entry) =>
                                        entry.subjectLinker !== subject.linker
                                    )
                                  )
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="form-group">
                {Levels.length > 0 ? (
                  <>
                    {" "}
                    <div>
                      <label>Select Level</label>
                      <select
                        className="form-control rounded"
                        onChange={(e) =>
                          props.setActivity({
                            ...props.activity,
                            levelLinker: e.target.value,
                          })
                        }
                        readOnly={props.type === "delete" ? true : false}
                        value={props.activity.levelLinker}
                      >
                        {Levels.map((level, index) => (
                          <option key={index} value={level.linker}>
                            {level.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <hr />
                  </>
                ) : null}
                {Periods.length > 0 ? (
                  <>
                    {" "}
                    <div>
                      <label>Select Period</label>
                      <select
                        className="form-control rounded"
                        onChange={(e) =>
                          props.setActivity({
                            ...props.activity,
                            periodLinker: e.target.value,
                          })
                        }
                        readOnly={props.type === "delete" ? true : false}
                        value={props.activity.periodLinker}
                      >
                        {Periods.map((period, index) => (
                          <option key={index} value={period.linker}>
                            {period.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <hr />
                  </>
                ) : null}
                {Faculties.length > 0 ? (
                  <>
                    {" "}
                    <div>
                      <label>Select Faculty</label>
                      <select
                        className="form-control rounded"
                        onChange={(e) =>
                          props.setActivity({
                            ...props.activity,
                            facultyLinker: e.target.value,
                          })
                        }
                        readOnly={props.type === "delete" ? true : false}
                        value={props.activity.facultyLinker}
                      >
                        {Faculties.map((faculty, index) => (
                          <option key={index} value={faculty.linker}>
                            {faculty.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <hr />
                  </>
                ) : null}
                {Subjects.length > 0 ? (
                  <>
                    {" "}
                    <div>
                      <label>Select Subject</label>
                      <select
                        className="form-control rounded"
                        onChange={(e) =>
                          props.setActivity({
                            ...props.activity,
                            subjectLinker: e.target.value,
                          })
                        }
                        readOnly={props.type === "delete" ? true : false}
                        value={props.activity.subjectLinker}
                      >
                        {Subjects.map((subject, index) => (
                          <option key={index} value={subject.linker}>
                            {subject.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <hr />
                  </>
                ) : null}
              </div>
            )}
            <div className="d-flex justify-content-around mb-2">
              {Loading ? (
                <span className="spinner-border text-primary"></span>
              ) : props.type === "delete" ? (
                <Button variant="danger" type="submit">
                  Delete
                </Button>
              ) : (
                <Button variant="primary" type="submit">
                  Save
                </Button>
              )}
              <Button
                variant="secondary"
                type="button"
                onClick={() => {
                  setEntries([]);
                  props.setShowModal(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ActivityCrud;

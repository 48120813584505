import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import IntervalCrud from "./IntervalCrud";
import Interval from "./Interval";

import LessonCrud from "./LessonCrud";
import { FetchIntervals } from "../../Network/IntervalApi";
import {
	FetchLessons,
	addLiveLesson,
	updateLiveLesson,
} from "../../Network/LessonApi";
import GeneratePdf from "../../Utils/GeneratePdf";
import TimeTableClass from "./TimeTableClass";

const TimeTable = () => {
	//redux dispatch
	const dispatch = useDispatch();

	useEffect(() => {
		FetchIntervals(User.token, ActiveCred.instLinker, dispatch, online);
		FetchLessons(User.token, ActiveCred.instLinker, dispatch, online);
	}, []);
	const Structures = useSelector((state) => state.structure.structures);
	const AllLevels = Structures.filter(
		(structure) =>
			structure.type === "level" && parseInt(structure.deleted) === 0
	);
	const AllPeriods = Structures.filter(
		(structure) =>
			structure.type === "period" && parseInt(structure.deleted) === 0
	);
	const AllFaculties = Structures.filter(
		(structure) =>
			structure.type === "faculty" && parseInt(structure.deleted) === 0
	);
	const [Day, setDay] = useState("");
	const [Level, setLevel] = useState(
		AllLevels.length > 0 ? AllLevels[0].linker : ""
	);
	const [Faculty, setFaculty] = useState(
		AllFaculties.length > 0 ? AllFaculties[0].linker : ""
	);
	const [Period, setPeriod] = useState(
		AllPeriods.length > 0 ? AllPeriods[0].linker : ""
	);
	const online = useSelector((state) => state.online.online);
	const User = useSelector((state) => state.auth.user);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const Subjects = useSelector((state) => state.subject.subjects).filter(
		(structure) => parseInt(structure.deleted) === 0
	);
	const Lessons = useSelector((state) => state.lesson.lessons)
		.filter((lesson) => parseInt(lesson.deleted) === 0)
		.sort((a, b) => b.trace - a.trace);

	const SortIntervals = useSelector((state) => state.interval.intervals)
		.filter((interval) => interval.deleted == 0)
		.sort((a, b) => {
			let [hours1, mins1] = a.startAt.slice(0, 5).split(":");
			let [hours2, mins2] = b.startAt.slice(0, 5).split(":");

			let time1 = hours1 + (a.startAt.endsWith("PM") ? 12 : 0);
			let time2 = hours2 + (b.startAt.endsWith("PM") ? 12 : 0);
			return time1 + mins1 - (time2 + mins2);
		});

	const [ActiveInterval, setActiveInterval] = useState({
		startAt: "00:00",
		endAt: "00:00",
	});

	const [ActiveLesson, setActiveLesson] = useState({
		tutor: "",
		venue: "",
		subjectLinker: "",
		level: {},
		faculty: {},
		period: {},
	});
	const [Type, setType] = useState("add");
	const [Loading, setLoading] = useState(false);
	const [IntervalModal, setIntervalModal] = useState(false);
	const [LessonModal, setLessonModal] = useState(false);

	const Levels = AllLevels.filter(
		(structure) =>
			structure.type === "level" &&
			parseInt(structure.deleted) === 0 &&
			(parseInt(structure.linker) === parseInt(Level) || Level === "")
	);

	const Periods = AllPeriods.filter(
		(structure) =>
			structure.type === "period" &&
			parseInt(structure.deleted) === 0 &&
			(parseInt(structure.linker) === parseInt(Period) || Period === "")
	);

	const Faculties = AllFaculties.filter(
		(structure) =>
			structure.type === "faculty" &&
			parseInt(structure.deleted) === 0 &&
			(parseInt(structure.linker) === parseInt(Faculty) || Faculty === "")
	);

	const Days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].filter(
		(day) => Day === day || Day === ""
	);

	const ShuffleArray = (array, segmentSize) => {
		if (array.length < 2) return array;

		let shuffled = array.slice();
		let isValidShuffle = false;

		while (!isValidShuffle) {
			// Shuffle using Fisher-Yates algorithm
			for (let i = shuffled.length - 1; i > 0; i--) {
				const j = Math.floor(Math.random() * (i + 1));
				[shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
			}

			// Check for duplicates in segments
			isValidShuffle = true;
			for (let start = 0; start < shuffled.length; start += segmentSize) {
				const end = Math.min(start + segmentSize, shuffled.length);
				const segment = shuffled.slice(start, end);
				const segmentSet = new Set(segment);
				if (segmentSet.size !== segment.length) {
					isValidShuffle = false;
					break;
				}
			}
		}

		return shuffled;
	};

	const CountOccurrences = (array) => {
		const countMap = new Map();
		array.forEach((value) => {
			countMap.set(value, (countMap.get(value) || 0) + 1);
		});
		return countMap;
	};

	const GetElementsByCount = (countMap, targetCount) => {
		return [...countMap.entries()]
			.filter(([_, count]) => count === targetCount)
			.map(([value, _]) => value);
	};

	const CreateTimeTable = (subjects, intervals, days) => {
		if (subjects.length === 0) {
			return [];
		}
		// Calculate total Number weekly lessons
		let WeeklyLessonsNo = intervals.length * days.length;

		let WeeklySubjects = [];
		while (WeeklySubjects.length < WeeklyLessonsNo) {
			const shuffledShortArray = ShuffleArray([...subjects], subjects.length);
			WeeklySubjects.push(...shuffledShortArray);
		}

		const countMap = CountOccurrences(WeeklySubjects);

		if (WeeklySubjects.length > WeeklyLessonsNo) {
			// Calculate how many elements to remove
			const excessLength = WeeklySubjects.length - WeeklyLessonsNo;
			// Remove excess elements randomly
			for (let i = 0; i < excessLength; i++) {
				const maxCount = Math.max(...countMap.values());
				const maxElements = GetElementsByCount(countMap, maxCount);
				const elementToRemove =
					maxElements[Math.floor(Math.random() * maxElements.length)];
				const indexToRemove = WeeklySubjects.indexOf(elementToRemove);
				WeeklySubjects.splice(indexToRemove, 1);
				countMap.set(elementToRemove, countMap.get(elementToRemove) - 1);
			}
		} else {
			const shortfallLength = WeeklyLessonsNo - WeeklySubjects.length;
			const minCount = Math.min(...countMap.values());
			const minElements = GetElementsByCount(countMap, minCount);
			for (let i = 0; i < shortfallLength; i++) {
				const elementToAdd =
					minElements[Math.floor(Math.random() * minElements.length)];
				WeeklySubjects.push(elementToAdd);
				countMap.set(elementToAdd, countMap.get(elementToAdd) + 1);
			}
		}

		// weekly intervals array
		let WeeklyIntervals = [];

		// Iterate through each day
		days.forEach((day) => {
			// Iterate through each interva;
			intervals.forEach((obj) => {
				// Create a new object by copying the original object
				const newObj = { ...obj, day };
				// Push the new object to the result array
				WeeklyIntervals.push(newObj);
			});
		});

		//by combining weekly subjects with weekly intervals
		let WeeklyLessons = WeeklySubjects.map((subject, index) => {
			const interval = WeeklyIntervals[index];
			return {
				...subject,
				day: interval.day,
				intervalLinker: interval.linker,
				subjectLinker: subject.linker,
				deleted: 0,
			};
		});

		return WeeklyLessons;
	};

	const ClassData = (LevelsData, FacultyData, PeriodData) => {
		const combinedClasses = [];

		// Generate classes based on combinations of year, faculty, and semester
		for (const level of LevelsData) {
			for (const faculty of FacultyData) {
				for (const period of PeriodData) {
					let subjects = Subjects.filter(
						(subject) =>
							parseInt(subject.levelLinker) === parseInt(level.linker) &&
							parseInt(subject.facultyLinker) === parseInt(faculty.linker) &&
							parseInt(subject.periodLinker) === parseInt(period.linker)
					);

					let ClassTimeTables = CreateTimeTable(subjects, SortIntervals, Days);

					combinedClasses.push({
						linker: `${level.linker}-${faculty.linker}-${period.linker}`, // Unique
						level: level.linker,
						faculty: faculty.linker,
						period: period.linker,
						subjects: ClassTimeTables,
					});
				}
			}
		}

		return combinedClasses;
	};

	const GenerateAutoTimeTable = async () => {
		const TimeTableLessons = ClassData(
			Levels.length < 1 ? [{}] : Levels,
			Faculties.length < 1 ? [{}] : Faculties,
			Periods.length < 1 ? [{}] : Periods
		)
			.map((lesson) => [...lesson.subjects])
			.flat(Infinity);

		let trace = Date.now();
		setLoading(true);

		const promises = TimeTableLessons.sort((a, b) => b.trace - a.trace).map(
			(lesson, index) => {
				let PrevLesson = Lessons.find(
					(prevLesson) =>
						parseInt(prevLesson.deleted) === 0 &&
						parseInt(prevLesson.subjectLinker) ===
							parseInt(lesson.subjectLinker) &&
						parseInt(prevLesson.intervalLinker) ===
							parseInt(lesson.intervalLinker) &&
						prevLesson.day === lesson.day
				);

				trace += index;

				if (PrevLesson) {
					updateLiveLesson(
						{
							...PrevLesson,
							trace,
							subjectLinker: lesson.subjectLinker,
							tutor: "",
							venue: "",
						},
						User.token,
						dispatch
					);
				} else {
					addLiveLesson(
						{ ...lesson, trace },
						ActiveCred.instLinker,
						User.token,
						dispatch
					);
				}
			}
		);

		await Promise.all(promises);

		await FetchLessons(User.token, ActiveCred.instLinker, dispatch, online);
		setLoading(false);
	};

	return (
		<div>
			<div id="timetable">
				<div>
					<table className="table table-sm table-bordered">
						<thead>
							<tr>
								{/*	<th colSpan={parseInt((SortIntervals.length + 1) / 4)}>
									{" "}
									<select
										className="form-control rounded"
										onChange={(e) => setDay(e.target.value)}
									>
										<option value={""}>All Days</option>
										{["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map(
											(day, index) => (
												<option key={index} value={day}>
													{day}
												</option>
											)
										)}
									</select>
								</th>*/}
								{Levels.length > 0 ? (
									<th colSpan={parseInt((SortIntervals.length + 1) / 4)}>
										<select
											className="form-control rounded"
											onChange={(e) => setLevel(e.target.value)}
										>
											{Structures.filter(
												(structure) =>
													structure.type === "level" &&
													parseInt(structure.deleted) === 0
											).map((level, index) => (
												<option key={index} value={level.linker}>
													{level.name}
												</option>
											))}
										</select>
									</th>
								) : null}
								{Faculties.length > 0 ? (
									<th colSpan={parseInt((SortIntervals.length + 1) / 4)}>
										{" "}
										<select
											className="form-control rounded"
											onChange={(e) => setFaculty(e.target.value)}
										>
											{Structures.filter(
												(structure) =>
													structure.type === "faculty" &&
													parseInt(structure.deleted) === 0
											).map((faculty, index) => (
												<option key={index} value={faculty.linker}>
													{faculty.name}
												</option>
											))}
										</select>
									</th>
								) : null}
								{Periods.length > 0 ? (
									<th colSpan={parseInt((SortIntervals.length + 1) / 4)}>
										{" "}
										<select
											className="form-control rounded"
											onChange={(e) => setPeriod(e.target.value)}
										>
											{Structures.filter(
												(structure) =>
													structure.type === "period" &&
													parseInt(structure.deleted) === 0
											).map((period, index) => (
												<option key={index} value={period.linker}>
													{period.name}
												</option>
											))}
										</select>
									</th>
								) : null}{" "}
								<th>
									{parseInt(ActiveCred.admin) === 1 ? (
										<button
											onClick={() => {
												setActiveInterval({ startAt: "00:00", endAt: "00:00" });
												setIntervalModal(true);
												setType("add");
											}}
											className="btn btn-primary rounded"
										>
											Add Time
										</button>
									) : null}
								</th>
								<th>
									{Loading ? (
										<span className="text-primary spinner-border"></span>
									) : parseInt(ActiveCred.admin) === 1 ? (
										<button
											onClick={() => {
												GenerateAutoTimeTable();
											}}
											className="btn btn-success rounded"
										>
											Automatic
										</button>
									) : null}
								</th>
							</tr>

							<tr>
								<th></th>

								{SortIntervals.map((interval, index) => (
									<th key={index}>
										<Interval
											interval={interval}
											setType={setType}
											setShowModal={setIntervalModal}
											setInterval={setActiveInterval}
										></Interval>
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{(Levels.length > 0 ? Levels : [{}]).map((level, levelIndex) =>
								(Faculties.length > 0 ? Faculties : [{}]).map(
									(faculty, facultyIndex) =>
										(Periods.length > 0 ? Periods : [{}]).map(
											(period, periodIndex) => (
												<>
													{Days.map((day, dayIndex) => (
														<TimeTableClass
															key={`${dayIndex}-${levelIndex}-${facultyIndex}-${periodIndex}`}
															levelIndex={levelIndex}
															facultyIndex={facultyIndex}
															periodIndex={periodIndex}
															day={day}
															Levels={Levels}
															level={level}
															Faculties={Faculties}
															faculty={faculty}
															Periods={Periods}
															period={period}
															SortIntervals={SortIntervals}
															setType={setType}
															setActiveLesson={setActiveLesson}
															setLessonModal={setLessonModal}
															Lessons={Lessons}
														></TimeTableClass>
													))}
												</>
											)
										)
								)
							)}
						</tbody>
					</table>
					<IntervalCrud
						interval={ActiveInterval}
						setInterval={setActiveInterval}
						type={Type}
						ShowModal={IntervalModal}
						setShowModal={setIntervalModal}
					></IntervalCrud>
					<LessonCrud
						lesson={ActiveLesson}
						setLesson={setActiveLesson}
						type={Type}
						ShowModal={LessonModal}
						setShowModal={setLessonModal}
						Subjects={Subjects.filter(
							(subject) =>
								parseInt(subject.deleted) === 0 &&
								parseInt(subject.facultyLinker) ===
									parseInt(ActiveLesson.faculty?.linker) &&
								parseInt(subject.levelLinker) ===
									parseInt(ActiveLesson.level?.linker) &&
								parseInt(subject.periodLinker) ===
									parseInt(ActiveLesson.period?.linker)
						)}
					></LessonCrud>
				</div>
			</div>
			<GeneratePdf
				id={"timetable"}
				name={`Timetable-For-${
					(
						AllFaculties.find(
							(faculty) => parseInt(faculty.linker) === parseInt(Faculty)
						) || {}
					).name
				}-${
					(
						AllLevels.find(
							(level) => parseInt(level.linker) === parseInt(Level)
						) || {}
					).name
				}-${
					(
						AllPeriods.find(
							(period) => parseInt(period.linker) === parseInt(Period)
						) || {}
					).name
				}`}
			></GeneratePdf>
		</div>
	);
};

export default TimeTable;

import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
	FetchLessons,
	addLiveLesson,
	updateLiveLesson,
} from "../../Network/LessonApi";

const LessonCrud = (props) => {
	useEffect(() => {
		setSearch(GetSubject().name);
	}, [props.lesson.subjectLinker]);

	//redux dispatch
	const dispatch = useDispatch();

	const online = useSelector((state) => state.online.online);
	const User = useSelector((state) => state.auth.user);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const [Loading, setLoading] = useState(false);

	const GetSubject = () =>
		props.Subjects.find(
			(subject) =>
				parseInt(subject.linker) === parseInt(props.lesson.subjectLinker)
		) || { name: "" };

	const [Search, setSearch] = useState(GetSubject().name);
	const [Error, setError] = useState(false);

	const SearchedSubjects = props.Subjects.filter(
		(subject) =>
			subject.name.toLowerCase().search(Search.toLowerCase()) > -1 ||
			Search === ""
	);

	//add lesson
	const AddLesson = async () => {
		await addLiveLesson(
			{
				...props.lesson,
				instLinker: ActiveCred.linker,
				credLinker: ActiveCred.linker,
				live: 1,
				status: 0,
				trace: props.lesson.linker,
				deleted: 0,
			},
			ActiveCred.instLinker,
			User.token,
			dispatch
		);
		await FetchLessons(User.token, ActiveCred.instLinker, dispatch, online);
		props.setLesson({
			...props.lesson,
			startAt: "",
			endAt: "",
		});
	};

	//edit lesson
	const EditLesson = async () => {
		let {
			credLinker,
			trace,

			deleted,
		} = props.lesson;

		trace = Date.now();
		credLinker = ActiveCred.linker;
		deleted = props.type === "delete" ? 1 : deleted;

		await updateLiveLesson(
			{ ...props.lesson, trace, credLinker, deleted },
			User.token,
			dispatch
		);
		await FetchLessons(User.token, ActiveCred.instLinker, dispatch, online);
	};

	const HandleSubmit = async (e) => {
		e.preventDefault();
		if (props.lesson.subjectLinker === "") {
			setError(true);
			return;
		}
		setLoading(true);
		if (props.type === "add") {
			await AddLesson();
		} else {
			await EditLesson();
		}
		setLoading(false);
		props.setShowModal(false);
	};

	return (
		<Modal
			show={props.ShowModal}
			onHide={() => props.setShowModal(false)}
			backdrop="static"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<p className="text-capitalize text-center">
						{props.type} {props.lesson.level.name} {props.lesson.faculty.name}{" "}
						Lesson:
					</p>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={HandleSubmit}>
					<div className="form-group">
						<label className="mb-2 text-capitalize">Select Subject :</label>
						<small>{GetSubject().name} </small>
						{Error ? (
							<small className="text-danger">No Subject is Selected</small>
						) : null}
						<div className="d-flex justify-contect-center">
							<Dropdown>
								<Dropdown.Toggle variant="transparent">
									<input
										className="rounded mb-0"
										placeholder={`Search Subject`}
										value={Search}
										onChange={(e) => setSearch(e.target.value)}
									/>
								</Dropdown.Toggle>
								<Dropdown.Menu>
									{SearchedSubjects.map((subject, index) => (
										<Dropdown.Item
											key={index}
											onClick={() => {
												props.setLesson({
													...props.lesson,
													subjectLinker: subject.linker,
												});
												setError(false);
												setSearch(subject.name);
											}}
										>
											{subject.name}
										</Dropdown.Item>
									))}
								</Dropdown.Menu>
							</Dropdown>
						</div>
						<hr />
					</div>
					<div className="form-group">
						<label className="mb-2 text-capitalize">Teacher/Lecturer :</label>
						<input
							className="rounded form-control"
							placeholder={`enter teacher/lecturer`}
							value={props.lesson.tutor}
							onChange={(e) =>
								props.setLesson({
									...props.lesson,
									tutor: e.target.value,
								})
							}
							readOnly={props.type === "delete" ? true : false}
						/>
						<hr />
					</div>
					<div className="form-group">
						<label className="mb-2 text-capitalize">Venue :</label>
						<input
							className="rounded form-control"
							placeholder={`enter venue`}
							value={props.lesson.venue}
							onChange={(e) =>
								props.setLesson({
									...props.lesson,
									venue: e.target.value,
								})
							}
							readOnly={props.type === "delete" ? true : false}
						/>
						<hr />
					</div>
					<div className="d-flex justify-content-around mb-2">
						{Loading ? (
							<span className="spinner-border text-primary"></span>
						) : props.type === "delete" ? (
							<Button variant="danger" type="submit">
								Delete
							</Button>
						) : (
							<Button variant="primary" type="submit">
								Save
							</Button>
						)}
						<Button
							variant="secondary"
							type="button"
							onClick={() => props.setShowModal(false)}
						>
							Cancel
						</Button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default LessonCrud;

import swal from "sweetalert";
import { addLesson, fetchLessons, updateLesson } from "../reducers/LessonSlice";
import { ApiUrl } from "./Urls";

export const FetchLessons = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	if (from === "fetch") {
		FetchLiveLessons(token, instLinker, online, dispatch);
	} else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
		dispatch(updateLesson(payload));
	}
};

export const addLiveLesson = (lesson, instLinker, token, dispatch) => {
	return fetch(`${ApiUrl}/lesson/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...lesson,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateLesson(res.lesson));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const updateLiveLesson = (lesson, token, dispatch) => {
	return fetch(`${ApiUrl}/lesson/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...lesson,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateLesson(res.lesson));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const FetchLiveLessons = (token, instLinker, online, dispatch) => {
	fetch(`${ApiUrl}/lesson/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(fetchLessons(res.lessons));
			}
		})
		.catch(() => {});
};

import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../reducers/AuthSlice";
import { useNavigate } from "react-router-dom";
import {
	CheckLiveUser,
	ForgotPassword,
	LiveLogIn,
	RegisterUser,
} from "../../Network/AuthApi";
import swal from "sweetalert";

const AuthModal = () => {
	const User = useSelector((state) => state.auth.user);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const ActiveInst = useSelector((state) => state.inst.activeInst);
	const navigate = useNavigate();

	const [showModal, setShowModal] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [Auth, setAuth] = useState("Login");
	const [LoginError, setLoginError] = useState(false);
	const [RegisterError, setRegisterError] = useState(false);
	const [Loading, setLoading] = useState(false);

	useEffect(() => {
		CheckLogger();
		setRegisterError(false);
		setLoginError(false);
	}, [ActiveCred, ActiveInst, User, showModal]);

	//redux dispatch
	const dispatch = useDispatch();

	const handleModalOpen = (newAuth) => {
		setShowModal(true);
		setAuth(newAuth);
		setLoginError(false);
		setLoading(false);
		setEmail("");
		setPassword("");
		setConfirmPassword("");
	};

	const CheckLogger = () => {
		if (ActiveCred.instLinker && ActiveInst.linker && User.token) {
			navigate(`/${ActiveInst.name}`);
			return;
		}
		if (User.token) {
			navigate("/inst");
			return;
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (confirmPassword !== password && Auth === "Register") {
			return;
		}
		setLoading(true);

		let liveUser = await CheckLiveUser(email);

		if (Auth === "Register") {
			if (liveUser === "register" || liveUser === 0) {
				Register();
			} else {
				setRegisterError(true);
			}
		} else {
			LoginUser();
		}
		setLoading(false);
	};

	const Register = async () => {
		let linker = Date.now();

		let UserLive = await RegisterUser(email, password, linker, linker);
		if (UserLive.token && UserLive.user) {
			dispatch(
				login({
					email,
					//  password,
					linker: UserLive.user.linker,
					live: 1,
					token: UserLive.token,
					logger: UserLive.user.logger,
				})
			);
			setEmail("");
			setPassword("");
			setConfirmPassword("");
			setShowModal(false);
			navigate("/inst");
		}
	};

	const LoginUser = async () => {
		let UserLive = await LiveLogIn(email, password);
		if (UserLive.token && UserLive.user) {
			dispatch(
				login({
					email,
					//   password,
					linker: UserLive.user.linker,
					name: UserLive.user.name,
					live: 1,
					token: UserLive.token,
					logger: UserLive.user.logger,
				})
			);
			navigate("/inst");
			return;
		} else {
			setLoginError(true);
		}
	};

	const PasswordForgot = async () => {
		if (email === "") {
			swal({
				title: "Enter Email",
				icon: "warning",
				timer: 3000,
			});
			return;
		}
		setLoading(true);
		let res = await ForgotPassword(email);
		if (res.status == 200) {
			swal({
				title: `Reset link has been sent to ${email}`,
				icon: "success",
			});
		} else {
			swal({
				title: `${email} not found`,
				icon: "warning",
				timer: 3000,
			});
		}
		setLoading(false);
	};

	return (
		<>
			<div className="d-flex justify-content-around">
				<Button
					variant="outline-primary"
					size="lg"
					onClick={() => handleModalOpen("Login")}
				>
					Login
				</Button>
				<Button
					variant="outline-info"
					size="lg"
					onClick={() => handleModalOpen("Register")}
				>
					Register
				</Button>
			</div>

			<Modal
				show={showModal}
				onHide={() => setShowModal(false)}
				centered
				backdrop="static"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{Auth}{" "}
						{LoginError ? (
							<small className="text-danger">
								<em style={{ fontSize: 12 }}>Invalid Login Details</em>
							</small>
						) : null}
						{RegisterError ? (
							<small className="text-danger">
								<em style={{ fontSize: 12 }}>Details Already Used</em>
							</small>
						) : null}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleSubmit}>
						<Form.Group controlId="formEmail">
							<Form.Label>Email address : </Form.Label>
							<Form.Control
								type="email"
								placeholder="Enter email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								required
							/>
						</Form.Group>
						<hr />
						<Form.Group controlId="formPassword">
							<Form.Label>Password :</Form.Label>
							<Form.Control
								type="password"
								placeholder="Password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								required
							/>
						</Form.Group>
						<hr />
						{Auth === "Register" ? (
							<>
								<Form.Group controlId="formConfirmPassword">
									<Form.Label>
										Confirm Password :{" "}
										<small className="text-danger">
											{confirmPassword != "" && confirmPassword != password
												? "Not Matching"
												: ""}
										</small>
									</Form.Label>
									<Form.Control
										type="password"
										placeholder="Confirm Password"
										value={confirmPassword}
										onChange={(e) => setConfirmPassword(e.target.value)}
										required
									/>
								</Form.Group>
								<hr />
							</>
						) : null}
						<div className="d-flex justify-content-around mb-2">
							{!Loading ? (
								<>
									<Button variant="primary" type="submit">
										Submit
									</Button>
									<span onClick={() => PasswordForgot()}>
										<small>
											<em
												className="text-primary btn-link"
												style={{ cursor: "pointer" }}
											>
												forgot password
											</em>
										</small>
									</span>
								</>
							) : (
								<Spinner variant="secondary" />
							)}

							<Button
								variant="secondary"
								type="button"
								onClick={() => setShowModal(false)}
							>
								Cancel
							</Button>
						</div>
					</Form>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default AuthModal;

import React, { useState, useEffect } from "react";
import SubjectCrud from "./SubjectCrud";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircle, Trash, PencilSquare } from "react-bootstrap-icons";
import NoSubject from "./NoSubjects";
import { FetchSubjects } from "../../../Network/SubjectApi";
import { PlainList } from "flatlist-react/lib";
import GeneratePdf from "../../../Utils/GeneratePdf";

const Subjects = () => {
  useEffect(() => {
    FetchSubjects(ActiveCred.instLinker, User.token, dispatch, online);
  }, []);

  const [ShowModal, setShowModal] = useState(false);
  const [Subject, setSubject] = useState({ name: "" });
  const [Search, setSearch] = useState("");
  const [Type, setType] = useState("add");

  //redux dispatch
  const dispatch = useDispatch();

  const online = useSelector((state) => state.online.online);
  const User = useSelector((state) => state.auth.user);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const [Level, setLevel] = useState("all");
  const [Period, setPeriod] = useState("all");
  const [Faculty, setFaculty] = useState("all");
  const Subjects = useSelector((state) => state.subject.subjects);
  const Structures = useSelector((state) => state.structure.structures);
  const Levels = Structures.filter(
    (structure) =>
      structure.type === "level" && parseInt(structure.deleted) === 0
  );
  const Periods = Structures.filter(
    (structure) =>
      structure.type === "period" && parseInt(structure.deleted) === 0
  );
  const Faculties = Structures.filter(
    (structure) =>
      structure.type === "faculty" && parseInt(structure.deleted) === 0
  );

  const SearchSubject = Subjects.filter(
    (subject) =>
      (subject.name.toLowerCase().search(Search.toLowerCase()) > -1 ||
        Search === "") &&
      parseInt(subject.deleted) === 0 &&
      (parseInt(subject.levelLinker) === parseInt(Level) || Level === "all") &&
      (parseInt(subject.periodLinker) === parseInt(Period) ||
        Period === "all") &&
      (parseInt(subject.facultyLinker) === parseInt(Faculty) ||
        Faculty === "all")
  ).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

  return (
    <div>
      <p className="text-center h6 text-primary text-capitalize">
        {SearchSubject.length} Subjects/Units
      </p>
      <table className="table table-sm" id="units">
        <thead className="thead-dark">
          <tr>
            <th scope="column">
              <input
                type={"text"}
                placeholder={`Search Subjects/Units`}
                value={Search}
                onChange={(e) => setSearch(e.target.value)}
                className="rounded form-control"
              />
            </th>
            {Levels.length > 0 ? (
              <td>
                <select
                  className="form-control rounded"
                  onChange={(e) => setLevel(e.target.value)}
                >
                  <option value={"all"}>All Levels</option>
                  {Levels.map((level, index) => (
                    <option key={index} value={level.linker}>
                      {level.name}
                    </option>
                  ))}
                </select>
              </td>
            ) : null}
            {Periods.length > 0 ? (
              <td>
                <select
                  className="form-control rounded"
                  onChange={(e) => setPeriod(e.target.value)}
                >
                  <option value={"all"}>All Periods</option>
                  {Periods.map((period, index) => (
                    <option key={index} value={period.linker}>
                      {period.name}
                    </option>
                  ))}
                </select>
              </td>
            ) : null}
            {Faculties.length > 0 ? (
              <td>
                <select
                  className="form-control rounded"
                  onChange={(e) => setFaculty(e.target.value)}
                >
                  <option value={"all"}>All Faculties</option>
                  {Faculties.map((faculty, index) => (
                    <option key={index} value={faculty.linker}>
                      {faculty.name}
                    </option>
                  ))}
                </select>
              </td>
            ) : null}

            <td>
              <button
                className="btn btn-sm btn-primary"
                onClick={() => {
                  setShowModal(true);
                  setType("add");
                  setSubject({ name: "" });
                }}
              >
                <PlusCircle /> Add Subject/Unit
              </button>
            </td>
          </tr>
        </thead>
        {SearchSubject.length > 0 ? (
          <tbody>
            <PlainList
              renderOnScroll
              list={SearchSubject}
              renderItem={(subject) => (
                <tr key={subject.linker} className="item">
                  <td>{subject.name}</td>
                  {Levels.length > 0 ? (
                    <td>
                      {
                        (
                          Levels.find(
                            (level) => level.linker == subject.levelLinker
                          ) || { name: "none" }
                        ).name
                      }
                    </td>
                  ) : null}
                  {Periods.length > 0 ? (
                    <td>
                      {
                        (
                          Periods.find(
                            (period) => period.linker == subject.periodLinker
                          ) || { name: "none" }
                        ).name
                      }
                    </td>
                  ) : null}
                  {Faculties.length > 0 ? (
                    <td>
                      {
                        (
                          Faculties.find(
                            (faculty) => faculty.linker == subject.facultyLinker
                          ) || { name: "none" }
                        ).name
                      }
                    </td>
                  ) : null}
                  <td>
                    <button
                      className="btn btn-xs btn-outline-info mx-1"
                      onClick={() => {
                        setShowModal(true);
                        setType("edit");
                        setSubject({ ...subject });
                      }}
                    >
                      {" "}
                      <PencilSquare style={{ cursor: "pointer" }} />
                    </button>
                    <button
                      className="btn btn-xs btn-outline-danger mx-1"
                      onClick={() => {
                        setShowModal(true);
                        setType("delete");
                        setSubject({ ...subject });
                      }}
                    >
                      {" "}
                      <Trash style={{ cursor: "pointer" }} />
                    </button>
                  </td>
                </tr>
              )}
            />
          </tbody>
        ) : (
          <NoSubject></NoSubject>
        )}
      </table>
      <GeneratePdf id={"units"} name={"subjects-or-units"}></GeneratePdf>
      <SubjectCrud
        setShowModal={setShowModal}
        ShowModal={ShowModal}
        subject={Subject}
        setSubject={setSubject}
        type={Type}
      ></SubjectCrud>
    </div>
  );
};

export default Subjects;

import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBarLeft } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import NoBook from "../Library/Books/NoBook";
import BookDisp from "../Library/Books/BookDisp";
import BookCrud from "../Library/Books/BookCrud";

const StudentBooks = () => {
  const params = useParams();
  const Navigate = useNavigate();

  const [ShowModal, setShowModal] = useState(false);
  const [BookModal, setBookModal] = useState(false);
  const [Book, setBook] = useState({ name: "" });
  const [Type, setType] = useState("add");

  const Student = useSelector((state) => state.cred.creds).find(
    (student) => parseInt(params.studentLinker) === parseInt(student.linker)
  );

  const Cats = useSelector((state) => state.libCat.libCats).filter(
    (cat) => cat.deleted == "0"
  );

  const GetStudent = (linker) => {
    return Student || { linker: params.studentLinker };
  };

  const Books = useSelector((state) => state.book.books).filter(
    (book) =>
      parseInt(book.deleted) === 0 &&
      parseInt(book.bookStatus) === 1 &&
      parseInt(book.studentLinker) === parseInt(params.studentLinker)
  );

  return (
    <div>
      <div className="d-flex justify-content-around mt-2">
        <button
          className="btn btn-sm btn-outline-secondary"
          onClick={() => Navigate(-1)}
        >
          <ArrowBarLeft></ArrowBarLeft> Go Back
        </button>{" "}
        <p className="h6 text-center">
          {Student.studentReg} {Student.firstname} {Student.lastname}{" "}
          {Student.surname} Library Books
        </p>
      </div>
      <table className="table table-sm">
        <thead className="thead-dark">
          <tr>
            <th scope="column">Code</th>
            <th scope="column">Name</th>
            <th>Author</th>
            <th>Status</th>
            <th>Category</th>
            <th>Details</th>

            <td></td>
          </tr>
        </thead>
        {Books.length > 0 ? (
          <tbody>
            {Books.map((book, index) => (
              <BookDisp
                Cats={Cats}
                book={book}
                setShowModal={setShowModal}
                setType={setType}
                setBook={setBook}
                setBookModal={setBookModal}
                GetStudent={GetStudent}
                key={index}
              ></BookDisp>
            ))}
          </tbody>
        ) : (
          <NoBook></NoBook>
        )}
      </table>
      <BookCrud
        setShowModal={setShowModal}
        ShowModal={ShowModal}
        book={Book}
        setBook={setBook}
        type={Type}
        Cats={Cats}
      ></BookCrud>
    </div>
  );
};

export default StudentBooks;

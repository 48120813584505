import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import AcademicsNav from "../Academics/AcademicsNav";
import AdminNav from "../Admin/AdminNav";
import FinanceNav from "../Finance/FinanceNav";
import LibraryNav from "../Library/LibraryNav";
import StudentNav from "../Students/StudentNav";
import { useSelector } from "react-redux";
import { Bank, Globe, Mailbox, PersonCircle } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import ClientNav from "../Client/ClientNav";
import StoreNav from "../Store/StoreNav";
import { VerifySub } from "../../Utils/VerifySub";
import SocketMessages from "../../Utils/SocketMessages";
import FetchData from "../../Utils/FetchData";
import Online from "../../Utils/Online";
import Header from "../../components/Header";

const Dashboard = () => {
	const navigate = useNavigate();
	const inst = useSelector((state) => state.inst.activeInst);
	const online = useSelector((state) => state.online.online);
	const User = useSelector((state) => state.auth.user);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const subs = useSelector((state) => state.sub.subs);
	const Sub = VerifySub(subs, ActiveCred.instLinker);

	const [Drawer, setDrawer] = useState(false);

	const ToggleDrawer = () => setDrawer(!Drawer);

	useEffect(() => {
		if (!ActiveCred.linker) {
			navigate("/login");
		}
	}, []);

	return (
		<>
			<Header ToggleDrawer={ToggleDrawer}></Header>
			<div style={{ marginTop: "40px" }}>
				<SocketMessages></SocketMessages>
				<FetchData></FetchData>
				<Online></Online>

				<div className="d-flex">
					<div
						className={`bg-secondary ${
							Drawer ? "side-menu-open" : "side-menu"
						}`}
						style={{ zIndex: "5000" }}
					>
						<div>
							<p
								onClick={() => {
									window.scroll(0, 0);
									setDrawer(false);
									navigate(`/${inst.name}`);
								}}
							>
								<span className="nav-link">
									{" "}
									<p className="text-truncate text-capitalize m-0 p-0">
										<Bank /> {inst.name}
									</p>{" "}
									<small>
										{parseInt(
											parseInt(`${inst.linker}`.split("").reverse().join("")) /
												1000
										)}
									</small>
								</span>
							</p>
							<hr />
							<p
								onClick={() => {
									window.scroll(0, 0);
									setDrawer(false);
									navigate(`profile`);
								}}
							>
								<strong className="nav-link text-truncate">
									<PersonCircle /> {User.name || User.logger}
								</strong>
							</p>
							<hr />
							{parseInt(ActiveCred.admin) === 1 && Sub > 0 ? (
								<AdminNav setDrawer={setDrawer}></AdminNav>
							) : null}
							{(parseInt(ActiveCred.admin) === 1 ||
								parseInt(ActiveCred.finance) === 1) &&
							Sub > 0 ? (
								<FinanceNav setDrawer={setDrawer}></FinanceNav>
							) : null}
							{(parseInt(ActiveCred.admin) === 1 ||
								parseInt(ActiveCred.finance) === 1 ||
								parseInt(ActiveCred.library) === 1 ||
								parseInt(ActiveCred.tutor) === 1) &&
							Sub > 0 ? (
								<>
									<AcademicsNav setDrawer={setDrawer}></AcademicsNav>
									<LibraryNav setDrawer={setDrawer}></LibraryNav>
									<StudentNav setDrawer={setDrawer}></StudentNav>
									<StoreNav setDrawer={setDrawer}></StoreNav>
								</>
							) : null}
							{(parseInt(ActiveCred.student) === 1 ||
								parseInt(ActiveCred.guardian) === 1) &&
							Sub > 0 ? (
								<ClientNav setDrawer={setDrawer}></ClientNav>
							) : null}

							<p>
								{" "}
								{Sub - 30 * 24 * 60 * 60 < 0 &&
								parseInt(ActiveCred.admin) === 1 ? (
									<>
										<span
											onClick={() => {
												window.scroll(0, 0);
												navigate(
													!inst.sub ||
														inst.sub == 0 ||
														inst.sub == "" ||
														!isNaN(parseInt(inst.sub))
														? `/${inst.name}/activate/${inst.name}/${inst.clientId}`
														: `/${inst.name}/activate-web/${inst.name}/${inst.clientId}`
												);

												ToggleDrawer();
											}}
											className="text-center"
											style={{
												color: "hotpink",
												cursor: "pointer",
											}}
										>
											<small>Activate </small>
										</span>
										<br />
									</>
								) : null}{" "}
								<span
									onClick={() => {
										window.scroll(0, 0);
										navigate(`/${inst.name}/terms`);
										ToggleDrawer();
									}}
									className="text-center text-info"
									style={{
										cursor: "pointer",
										fontSize: "12px",
									}}
								>
									<small>Terms And Conditions </small>
								</span>{" "}
								<br />
								<span
									onClick={() => {
										window.scroll(0, 0);
										navigate(`/${inst.name}/privacy`);
										ToggleDrawer();
									}}
									className="text-center text-info"
									style={{
										cursor: "pointer",
										fontSize: "12px",
									}}
								>
									<small>Privacy Policy </small>
								</span>{" "}
								<br />
								<span
									className="text-center text-light"
									style={{
										cursor: "pointer",
										fontSize: "12px",
									}}
								>
									<small>
										<Globe /> www.techsystems.world
									</small>
								</span>
								<br />
								<span
									className="text-center text-light"
									style={{
										cursor: "pointer",
										fontSize: "12px",
									}}
								>
									<small>
										<Mailbox /> contact.techsystems@gmail.com
									</small>
								</span>
								<br />
								<span
									className="text-center text-light"
									style={{
										cursor: "pointer",
										fontSize: "12px",
									}}
								>
									<small>&copy; {new Date().getFullYear()} Techsystems</small>
								</span>
							</p>
						</div>
					</div>
					<div
						className={`${
							Drawer ? "main-page-drawer-open" : "main-page-drawer-closed"
						}`}
					>
						{Sub < 0 ? (
							<>
								{subs.length > 0 ? (
									<p className="text-danger text-center h6">
										Subscription Period Lapsed{" "}
										{parseInt(ActiveCred.admin) === 1 ? (
											<span
												className="text-primary btn-link"
												style={{ cursor: "pointer" }}
												onClick={() => {
													window.scroll(0, 0);
													navigate(
														!inst.sub ||
															inst.sub == 0 ||
															inst.sub == "" ||
															!isNaN(parseInt(inst.sub))
															? `/${inst.name}/activate/${inst.name}/${inst.clientId}`
															: `/${inst.name}/activate-web/${inst.name}/${inst.clientId}`
													);
												}}
											>
												<em>Click To Activate</em>
											</span>
										) : (
											"Contact Your Institution Admin to Activate"
										)}
									</p>
								) : (
									<p className="text-danger text-center h6">
										Trial Period Lapsed{" "}
										{parseInt(ActiveCred.admin) === 1 ? (
											<span
												className="text-primary btn-link"
												style={{ cursor: "pointer" }}
												onClick={() => {
													window.scroll(0, 0);
													navigate(
														!inst.sub ||
															inst.sub == 0 ||
															inst.sub == "" ||
															!isNaN(parseInt(inst.sub))
															? `/${inst.name}/activate/${inst.name}/${inst.clientId}`
															: `/${inst.name}/activate-web/${inst.name}/${inst.clientId}`
													);
												}}
											>
												<em> Click To Activate</em>
											</span>
										) : (
											"Contact Your Institution Admin to Activate"
										)}
									</p>
								)}
							</>
						) : Sub < 5 * 24 * 60 * 60 ? (
							<>
								{subs.length > 0 ? (
									<p className="text-danger text-center h6">
										Subscription Period Lapsing in{" "}
										{parseInt(Sub / (24 * 60 * 60))} Days
										{parseInt(ActiveCred.admin) === 1 ? (
											<span
												className="text-primary btn-link"
												style={{ cursor: "pointer" }}
												onClick={() => {
													window.scroll(0, 0);
													navigate(
														!inst.sub ||
															inst.sub == 0 ||
															inst.sub == "" ||
															!isNaN(parseInt(inst.sub))
															? `/${inst.name}/activate/${inst.name}/${inst.clientId}`
															: `/${inst.name}/activate-web/${inst.name}/${inst.clientId}`
													);
												}}
											>
												<em> Click To Activate</em>
											</span>
										) : (
											"Contact Your Institution Admin to Activate"
										)}
									</p>
								) : (
									<p className="text-danger text-center h6">
										Trial Period Lapsing In {parseInt(Sub / (24 * 60 * 60))}{" "}
										Days
										{parseInt(ActiveCred.admin) === 1 ? (
											<span
												className="text-primary btn-link"
												style={{ cursor: "pointer" }}
												onClick={() => {
													window.scroll(0, 0);
													navigate(
														!inst.sub ||
															inst.sub == 0 ||
															inst.sub == "" ||
															!isNaN(parseInt(inst.sub))
															? `/${inst.name}/activate/${inst.name}/${inst.clientId}`
															: `/${inst.name}/activate-web/${inst.name}/${inst.clientId}`
													);
												}}
											>
												<em> Click To Activate</em>
											</span>
										) : (
											"Contact Your Institution Admin to Activate"
										)}
									</p>
								)}
							</>
						) : null}
						<div className="bg-light">
							<Outlet></Outlet>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Dashboard;

import { createSlice } from "@reduxjs/toolkit";

export const InvoiceSlice = createSlice({
  name: "invoice",
  initialState: {
    invoices: [],
  },
  reducers: {
    fetchInvoices: (state, action) => {
      state.invoices = action.payload;
    },
    addInvoice: (state, action) => {
      state.invoices = [action.payload, ...state.invoices];
    },
    updateInvoice: (state, action) => {
      let index = state.invoices.findIndex(
        (invoice) =>
          parseInt(invoice.linker) === parseInt(action.payload.linker)
      );

      let prevInvoices = [...state.invoices];

      prevInvoices.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.invoices = prevInvoices;
    },
    deleteInvoice: (state, action) => {
      let newInvoices = state.invoices.filter(
        (invoice) =>
          parseInt(invoice.linker) !== parseInt(action.payload.linker)
      );
      state.invoices = newInvoices;
    },
  },
});

export const { fetchInvoices, addInvoice, updateInvoice, deleteInvoice } =
  InvoiceSlice.actions;

export default InvoiceSlice.reducer;

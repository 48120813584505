import { createSlice } from "@reduxjs/toolkit";

export const GradeSlice = createSlice({
  name: "grade",
  initialState: {
    grades: [],
  },
  reducers: {
    fetchGrades: (state, action) => {
      state.grades = action.payload;
    },
    addGrade: (state, action) => {
      state.grades = [action.payload, ...state.grades];
    },
    updateGrade: (state, action) => {
      let index = state.grades.findIndex(
        (grade) => parseInt(grade.linker) === parseInt(action.payload.linker)
      );

      let prevGrades = [...state.grades];

      prevGrades.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.grades = prevGrades;
    },
    deleteGrade: (state, action) => {
      let newGrades = state.grades.filter(
        (grade) => parseInt(grade.linker) !== parseInt(action.payload.linker)
      );
      state.grades = newGrades;
    },
  },
});

export const { fetchGrades, addGrade, updateGrade, deleteGrade } =
  GradeSlice.actions;

export default GradeSlice.reducer;

import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { People } from "react-bootstrap-icons";

const StudentNav = ({ setDrawer }) => {
  const navigate = useNavigate();
  const inst = useSelector((state) => state.inst.activeInst);
  return (
    <>
      <p
        className="nav-link"
        onClick={() => {
          window.scroll(0, 0);
          setDrawer(false);
          navigate(`students`);
        }}
      >
        <People /> Students
      </p>
      <hr />
    </>
  );
};

export default StudentNav;

import React, { useState } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addLiveSubject, updateLiveSubject } from "../../../Network/SubjectApi";
import { fetchSubjects } from "../../../reducers/SubjectSlice";

const SubjectCrud = (props) => {
  //redux dispatch
  const dispatch = useDispatch();

  const online = useSelector((state) => state.online.online);
  const User = useSelector((state) => state.auth.user);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Subjects = useSelector((state) => state.subject.subjects);
  const Structures = useSelector((state) => state.structure.structures);
  const Levels = Structures.filter(
    (structure) =>
      structure.type === "level" && parseInt(structure.deleted) === 0
  );
  const Periods = Structures.filter(
    (structure) =>
      structure.type === "period" && parseInt(structure.deleted) === 0
  );
  const Faculties = Structures.filter(
    (structure) =>
      structure.type === "faculty" && parseInt(structure.deleted) === 0
  );

  const [Level, setLevel] = useState("all");
  const [Period, setPeriod] = useState("all");
  const [Faculty, setFaculty] = useState("all");
  const [Entries, setEntries] = useState([]);
  const [Loading, setLoading] = useState(false);

  //add subject
  const AddSubject = async (entry) => {
    setLoading(true);
    let linker = Date.now();
    await addLiveSubject(
      {
        linker: entry.linker,
        name: props.subject.name,
        periodLinker: entry.periodLinker,
        levelLinker: entry.levelLinker,
        facultyLinker: entry.facultyLinker,
        instLinker: ActiveCred.linker,
        credLinker: ActiveCred.linker,
        live: 1,
        status: 0,
        trace: linker,
        deleted: 0,
      },
      ActiveCred.instLinker,
      User.token,
      dispatch
    );
  };

  //edit subject
  const EditSubject = async () => {
    let { credLinker, trace, deleted } = props.subject;

    trace = Date.now();
    credLinker = ActiveCred.linker;
    deleted = props.type === "delete" ? 1 : deleted;

    await updateLiveSubject(
      { ...props.subject, trace, credLinker, deleted },
      User.token,
      dispatch
    );
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (props.type === "add") {
      await Entries.map(async (entry) => await AddSubject(entry));

      setEntries([]);
    } else {
      await EditSubject();
    }
    props.setSubject({
      name: "",
    });
    setFaculty("all");
    setPeriod("all");
    setLevel("all");
    props.setShowModal(false);
    setLoading(false);
  };

  return (
    <div>
      <Modal
        show={props.ShowModal}
        onHide={() => {
          setEntries([]);
          props.setShowModal(false);
        }}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <p className="text-capitalize text-center">
              {props.type} Subject/Unit
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={HandleSubmit}>
            <div className="form-group">
              <label className="mb-2 text-capitalize">Subject/Unit Name:</label>
              <input
                className="rounded form-control"
                placeholder={`Subject name`}
                value={props.subject.name}
                onChange={(e) =>
                  props.setSubject({
                    ...props.subject,
                    name: e.target.value,
                  })
                }
                required
                readOnly={props.type === "delete" ? true : false}
              />
              <hr />
            </div>
            {props.type === "add" ? (
              <div>
                <table className="table">
                  <thead>
                    <tr>
                      {Levels.length > 0 ? (
                        <td>
                          <select
                            className="form-control rounded"
                            onChange={(e) => setLevel(e.target.value)}
                          >
                            <option value={"all"}>All Levels</option>
                            {Levels.map((level, index) => (
                              <option key={index} value={level.linker}>
                                {level.name}
                              </option>
                            ))}
                          </select>
                        </td>
                      ) : null}
                      {Periods.length > 0 ? (
                        <td>
                          <select
                            className="form-control rounded"
                            onChange={(e) => setPeriod(e.target.value)}
                          >
                            <option value={"all"}>All Periods</option>
                            {Periods.map((period, index) => (
                              <option key={index} value={period.linker}>
                                {period.name}
                              </option>
                            ))}
                          </select>
                        </td>
                      ) : null}
                      {Faculties.length > 0 ? (
                        <td>
                          <select
                            className="form-control rounded"
                            onChange={(e) => setFaculty(e.target.value)}
                          >
                            <option value={"all"}>All Faculties</option>
                            {Faculties.map((faculty, index) => (
                              <option key={index} value={faculty.linker}>
                                {faculty.name}
                              </option>
                            ))}
                          </select>
                        </td>
                      ) : null}

                      <td>Check</td>
                    </tr>
                  </thead>
                  <tbody>
                    {(Levels.length > 0 ? Levels : [{}])
                      .filter(
                        (level) =>
                          Level === "all" ||
                          parseInt(level.linker) === parseInt(Level)
                      )
                      .map((level) =>
                        (Periods.length > 0 ? Periods : [{}])
                          .filter(
                            (period) =>
                              Period === "all" ||
                              parseInt(period.linker) === parseInt(Period)
                          )
                          .map((period) =>
                            (Faculties.length > 0 ? Faculties : [{}])
                              .filter(
                                (faculty) =>
                                  Faculty === "all" ||
                                  parseInt(faculty.linker) === parseInt(Faculty)
                              )
                              .map((faculty) => (
                                <tr
                                  key={`${level.linker}-${period.linker}-${faculty.linker}`}
                                >
                                  {Levels.length > 0 ? (
                                    <td>{level.name}</td>
                                  ) : null}
                                  {Periods.length > 0 ? (
                                    <td>{period.name}</td>
                                  ) : null}
                                  {Faculties.length > 0 ? (
                                    <td>{faculty.name}</td>
                                  ) : null}

                                  <td>
                                    <input
                                      type="checkbox"
                                      value={{
                                        levelLinker: level.linker,
                                        periodLinker: period.linker,
                                        faculty: faculty.linker,
                                      }}
                                      onChange={() =>
                                        Entries.findIndex(
                                          (entry) =>
                                            entry.levelLinker ===
                                              level.linker &&
                                            entry.periodLinker ===
                                              period.linker &&
                                            entry.facultyLinker ===
                                              faculty.linker
                                        ) === -1
                                          ? setEntries([
                                              ...Entries,
                                              {
                                                levelLinker: level.linker,
                                                periodLinker: period.linker,
                                                facultyLinker: faculty.linker,
                                                linker: Date.now(),
                                                name: props.subject.name,
                                                deleted: 0,
                                                instLinker:
                                                  ActiveCred.instLinker,
                                                credLinker: ActiveCred.linker,
                                                id: 0,
                                                live: 0,
                                                createdAt: 0,
                                                updatedAt: 0,
                                              },
                                            ])
                                          : setEntries(
                                              Entries.filter(
                                                (entry) =>
                                                  entry.levelLinker !==
                                                    level.linker ||
                                                  entry.periodLinker !==
                                                    period.linker ||
                                                  entry.facultyLinker !==
                                                    faculty.linker
                                              )
                                            )
                                      }
                                    />
                                  </td>
                                </tr>
                              ))
                          )
                      )}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="form-group">
                {Levels.length > 0 ? (
                  <>
                    {" "}
                    <div>
                      <label>Select Level</label>
                      <select
                        className="form-control rounded"
                        onChange={(e) =>
                          props.setSubject({
                            ...props.subject,
                            levelLinker: e.target.value,
                          })
                        }
                        readOnly={props.type === "delete" ? true : false}
                        value={props.subject.levelLinker}
                      >
                        {Levels.map((level, index) => (
                          <option key={index} value={level.linker}>
                            {level.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <hr />
                  </>
                ) : null}
                {Periods.length > 0 ? (
                  <>
                    {" "}
                    <div>
                      <label>Select Period</label>
                      <select
                        className="form-control rounded"
                        onChange={(e) =>
                          props.setSubject({
                            ...props.subject,
                            periodLinker: e.target.value,
                          })
                        }
                        readOnly={props.type === "delete" ? true : false}
                        value={props.subject.periodLinker}
                      >
                        {Periods.map((period, index) => (
                          <option key={index} value={period.linker}>
                            {period.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <hr />
                  </>
                ) : null}
                {Faculties.length > 0 ? (
                  <>
                    {" "}
                    <div>
                      <label>Select Faculty</label>
                      <select
                        className="form-control rounded"
                        onChange={(e) =>
                          props.setSubject({
                            ...props.subject,
                            facultyLinker: e.target.value,
                          })
                        }
                        readOnly={props.type === "delete" ? true : false}
                        value={props.subject.facultyLinker}
                      >
                        {Faculties.map((faculty, index) => (
                          <option key={index} value={faculty.linker}>
                            {faculty.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <hr />
                  </>
                ) : null}
              </div>
            )}
            <div className="d-flex justify-content-around mb-2">
              {Loading ? (
                <Spinner variant="primary" />
              ) : props.type === "delete" ? (
                <Button variant="danger" type="submit">
                  Delete
                </Button>
              ) : (
                <Button variant="primary" type="submit">
                  Save
                </Button>
              )}
              <Button
                variant="secondary"
                type="button"
                onClick={() => {
                  setEntries([]);
                  setLoading(false);
                  props.setShowModal(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SubjectCrud;

import { createSlice } from "@reduxjs/toolkit";

export const LibCatSlice = createSlice({
  name: "libCat",
  initialState: {
    libCats: [],
  },
  reducers: {
    fetchLibCats: (state, action) => {
      state.libCats = action.payload;
    },
    addLibCat: (state, action) => {
      state.libCats = [action.payload, ...state.libCats];
    },
    updateLibCat: (state, action) => {
      let index = state.libCats.findIndex(
        (libCat) => parseInt(libCat.linker) === parseInt(action.payload.linker)
      );

      let prevLibCats = [...state.libCats];

      prevLibCats.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.libCats = prevLibCats;
    },
    deleteLibCat: (state, action) => {
      let newLibCats = state.libCats.filter(
        (libCat) => parseInt(libCat.linker) !== parseInt(action.payload.linker)
      );
      state.libCats = newLibCats;
    },
  },
});

export const { fetchLibCats, addLibCat, updateLibCat, deleteLibCat } =
  LibCatSlice.actions;

export default LibCatSlice.reducer;

import React from "react";

const NoItems = () => {
  return (
    <tbody>
      <tr>
        <th>No Items</th>

        <td>Here you can add items stored in the store</td>
      </tr>
    </tbody>
  );
};

export default NoItems;

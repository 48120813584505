import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addLiveInvoice } from "../../../Network/InvoiceApi";

const BulkInvoice = (props) => {
  const [Level, setLevel] = useState("");
  const [Period, setPeriod] = useState("");
  const [Faculty, setFaculty] = useState("");
  const [Year, setYear] = useState("");
  const [Name, setName] = useState("");
  const [Amount, setAmount] = useState("");
  const [Details, setDetails] = useState("");

  const online = useSelector((state) => state.online.online);
  const User = useSelector((state) => state.auth.user);
  const Structures = useSelector((state) => state.structure.structures);
  const [Loading, setLoading] = useState(false);

  const Students = useSelector((state) => state.cred.creds).filter(
    (student) =>
      student.deleted == "0" &&
      student.student == 1 &&
      (parseInt(student.level) === parseInt(Level) || Level === "") &&
      (parseInt(student.period) === parseInt(Period) || Period === "") &&
      (parseInt(student.faculty) === parseInt(Faculty) || Faculty === "") &&
      (parseInt(student.year) === parseInt(Year) || Year === "")
  );

  const Levels = Structures.filter(
    (structure) =>
      structure.type === "level" && parseInt(structure.deleted) === 0
  );

  const Periods = Structures.filter(
    (structure) =>
      structure.type === "period" && parseInt(structure.deleted) === 0
  );

  const Faculties = Structures.filter(
    (structure) =>
      structure.type === "faculty" && parseInt(structure.deleted) === 0
  );

  const Years = Structures.filter(
    (structure) =>
      structure.type === "academic-year" && parseInt(structure.deleted) === 0
  );
  //redux dispatch
  const dispatch = useDispatch();

  const ActiveCred = useSelector((state) => state.cred.activeCred);

  const ResetInputs = () => {
    setFaculty("");
    setLevel("");
    setYear("");
    setPeriod("");
    setName("");
    setAmount("");
    setDetails("");
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let linker = Date.now();
    await Students.map(async (student, index) => {
      await addLiveInvoice(
        {
          name: Name,
          details: Details,
          amount: Amount,
          studentLinker: student.linker,
          instLinker: ActiveCred.linker,
          credLinker: ActiveCred.linker,
          live: 1,
          status: 0,
          trace: linker,
          deleted: 0,
          linker: linker + index,
        },
        ActiveCred.instLinker,
        User.token,
        dispatch
      );
    });
    setLoading(false);
    props.setInvoiceModal(false);
    ResetInputs();
  };

  return (
    <Modal
      show={props.InvoiceModal}
      onHide={() => {
        props.setInvoiceModal(false);
        ResetInputs();
      }}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Invoice Students</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleSubmit}>
          <div className="row">
            {Years.length > 0 ? (
              <div className="col-md-12">
                <label>Academic Year : </label>
                <select
                  className="form-control rounded"
                  onChange={(e) => setYear(e.target.value)}
                  required
                  value={Year}
                >
                  <option value={""}>Select AcYear</option>
                  {Years.map((Year, index) => (
                    <option key={index} value={Year.linker}>
                      {Year.name}
                    </option>
                  ))}
                </select>
                <hr />
              </div>
            ) : null}
            {Faculties.length > 0 ? (
              <div className="col-md-12">
                <label> Faculty : </label>
                <select
                  className="form-control rounded"
                  onChange={(e) => setFaculty(e.target.value)}
                  value={Faculty}
                  required
                >
                  <option value={""}>Select Faculty</option>
                  {Faculties.map((faculty, index) => (
                    <option key={index} value={faculty.linker}>
                      {faculty.name}
                    </option>
                  ))}
                </select>
                <hr />
              </div>
            ) : null}

            {Levels.length > 0 ? (
              <div className="col-md-12">
                <label> Level : </label>
                <select
                  className="form-control rounded"
                  onChange={(e) => setLevel(e.target.value)}
                  value={Level}
                  required
                >
                  <option value={""}>Select Level</option>
                  {Levels.map((level, index) => (
                    <option key={index} value={level.linker}>
                      {level.name}
                    </option>
                  ))}
                </select>
                <hr />
              </div>
            ) : null}

            {Periods.length > 0 ? (
              <div className="col-md-12">
                <label> Period : </label>
                <select
                  className="form-control rounded"
                  onChange={(e) => setPeriod(e.target.value)}
                  value={Period}
                  required
                >
                  <option value={""}>Select Period</option>
                  {Periods.map((period, index) => (
                    <option key={index} value={period.linker}>
                      {period.name}
                    </option>
                  ))}
                </select>
                <hr />
              </div>
            ) : null}
            <div className="col-md-12">
              <label> Invoice For : </label>
              <input
                className="form-control rounded"
                onChange={(e) => setName(e.target.value)}
                value={Name}
                placeholder="Enter Invoice Name"
                required
              />

              <hr />
            </div>
            <div className="col-md-12">
              <label>Invoice Amount : </label>
              <input
                className="form-control rounded"
                onChange={(e) => setAmount(e.target.value)}
                value={Amount}
                placeholder="Enter Invoice Amount"
                required
                type="number"
              />

              <hr />
            </div>
            <div className="col-md-12">
              <label>Invoice Details : </label>
              <textarea
                className="form-control rounded"
                onChange={(e) => setDetails(e.target.value)}
                value={Details}
                placeholder="Enter Invoice Details"
                required
              />

              <hr />
            </div>
          </div>

          <div className="d-flex justify-content-around mb-2">
            {Loading ? (
              <span className="spinner-border text-primary"></span>
            ) : (
              <Button variant="primary" type="submit">
                Save
              </Button>
            )}
            <Button
              variant="secondary"
              type="button"
              onClick={() => {
                props.setInvoiceModal(false);
                ResetInputs();
              }}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default BulkInvoice;

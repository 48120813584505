import { createSlice } from "@reduxjs/toolkit";

export const SubjectSlice = createSlice({
  name: "subject",
  initialState: {
    subjects: [],
  },
  reducers: {
    fetchSubjects: (state, action) => {
      state.subjects = action.payload;
    },
    addSubject: (state, action) => {
      state.subjects = [action.payload, ...state.subjects];
    },
    updateSubject: (state, action) => {
      let index = state.subjects.findIndex(
        (subject) =>
          parseInt(subject.linker) === parseInt(action.payload.linker)
      );

      let prevSubjects = [...state.subjects];

      prevSubjects.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.subjects = prevSubjects;
    },
    deleteSubject: (state, action) => {
      let newSubjects = state.subjects.filter(
        (subject) =>
          parseInt(subject.linker) !== parseInt(action.payload.linker)
      );
      state.subjects = newSubjects;
    },
  },
});

export const { fetchSubjects, addSubject, updateSubject, deleteSubject } =
  SubjectSlice.actions;

export default SubjectSlice.reducer;

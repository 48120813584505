import React, { useState, useEffect } from "react";
import ActivityCrud from "./ActivityCrud";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircle, Trash, PencilSquare } from "react-bootstrap-icons";
import NoActivity from "./NoActivities";
import { FetchActivities } from "../../../Network/ActivityApi";
import { PlainList } from "flatlist-react";
import GeneratePdf from "../../../Utils/GeneratePdf";

const Activities = () => {
  //redux dispatch
  const dispatch = useDispatch();

  const online = useSelector((state) => state.online.online);
  const User = useSelector((state) => state.auth.user);
  const ActiveCred = useSelector((state) => state.cred.activeCred);

  useEffect(() => {
    FetchActivities(ActiveCred.instLinker, User.token, dispatch, online);
  }, []);

  const [ShowModal, setShowModal] = useState(false);
  const [Activity, setActivity] = useState({ name: "" });
  const [Search, setSearch] = useState("");
  const [Type, setType] = useState("add");

  const [Level, setLevel] = useState("all");
  const [Period, setPeriod] = useState("all");
  const [Faculty, setFaculty] = useState("all");
  const [Subject, setSubject] = useState("all");
  const Activities = useSelector((state) => state.activity.activities);
  const Structures = useSelector((state) => state.structure.structures);
  const Levels = Structures.filter(
    (structure) =>
      structure.type === "level" && parseInt(structure.deleted) === 0
  );
  const Periods = Structures.filter(
    (structure) =>
      structure.type === "period" && parseInt(structure.deleted) === 0
  );
  const Faculties = Structures.filter(
    (structure) =>
      structure.type === "faculty" && parseInt(structure.deleted) === 0
  );

  const Subjects = useSelector((state) => state.subject.subjects).filter(
    (subject) =>
      (parseInt(subject.levelLinker) == Level || Level == "all") &&
      (parseInt(subject.periodLinker) == Period || Period == "all") &&
      (parseInt(subject.facultyLinker) == Faculty || Faculty == "all")
  );

  const SearchActivity = Activities.filter(
    (activity) =>
      (activity.name.toLowerCase().search(Search.toLowerCase()) > -1 ||
        Search === "") &&
      parseInt(activity.deleted) === 0 &&
      (parseInt(activity.levelLinker) === parseInt(Level) || Level === "all") &&
      (parseInt(activity.periodLinker) === parseInt(Period) ||
        Period === "all") &&
      (parseInt(activity.facultyLinker) === parseInt(Faculty) ||
        Faculty === "all") &&
      (parseInt(activity.subjectLinker) === parseInt(Subject) ||
        Subject === "all")
  ).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

  return (
    <div>
      <p className="text-center h6 text-primary text-capitalize">
        {SearchActivity.length} Subjects/Units Assessments
      </p>
      <table className="table table-sm" id="activities">
        <thead className="thead-dark">
          <tr>
            <th scope="column">
              <input
                type={"text"}
                placeholder={`Search Activities`}
                value={Search}
                onChange={(e) => setSearch(e.target.value)}
                className="rounded form-control"
              />
            </th>

            {Levels.length > 0 ? (
              <td>
                <select
                  className="form-control rounded"
                  onChange={(e) => setLevel(e.target.value)}
                >
                  <option value={"all"}>All Levels</option>
                  {Levels.map((level, index) => (
                    <option key={index} value={level.linker}>
                      {level.name}
                    </option>
                  ))}
                </select>
              </td>
            ) : null}
            {Periods.length > 0 ? (
              <td>
                <select
                  className="form-control rounded"
                  onChange={(e) => setPeriod(e.target.value)}
                >
                  <option value={"all"}>All Periods</option>
                  {Periods.map((period, index) => (
                    <option key={index} value={period.linker}>
                      {period.name}
                    </option>
                  ))}
                </select>
              </td>
            ) : null}
            {Faculties.length > 0 ? (
              <td>
                <select
                  className="form-control rounded"
                  onChange={(e) => setFaculty(e.target.value)}
                >
                  <option value={"all"}>All Faculties</option>
                  {Faculties.map((faculty, index) => (
                    <option key={index} value={faculty.linker}>
                      {faculty.name}
                    </option>
                  ))}
                </select>
              </td>
            ) : null}
            {Subjects.length > 0 ? (
              <td>
                <select
                  className="form-control rounded"
                  onChange={(e) => setSubject(e.target.value)}
                >
                  <option value={"all"}>All Subjects</option>
                  <PlainList
                    list={Subjects}
                    renderOnScroll
                    renderItem={(subject) => (
                      <option key={subject.linker} value={subject.linker}>
                        {subject.name}
                      </option>
                    )}
                  />
                </select>
              </td>
            ) : null}
            <td>
              <button
                className="btn btn-sm btn-primary"
                onClick={() => {
                  setShowModal(true);
                  setType("add");
                  setActivity({ name: "" });
                }}
              >
                <PlusCircle /> Add Assessment
              </button>
            </td>
          </tr>
        </thead>
        {SearchActivity.length > 0 ? (
          <tbody>
            <PlainList
              list={SearchActivity}
              renderOnScroll
              renderItem={(activity, index) => (
                <tr key={index} className="item">
                  <td>{activity.name}</td>

                  {Levels.length > 0 ? (
                    <td>
                      {
                        (
                          Levels.find(
                            (level) => level.linker == activity.levelLinker
                          ) || { name: "none" }
                        ).name
                      }
                    </td>
                  ) : null}
                  {Periods.length > 0 ? (
                    <td>
                      {
                        (
                          Periods.find(
                            (period) => period.linker == activity.periodLinker
                          ) || { name: "none" }
                        ).name
                      }
                    </td>
                  ) : null}
                  {Faculties.length > 0 ? (
                    <td>
                      {
                        (
                          Faculties.find(
                            (faculty) =>
                              faculty.linker == activity.facultyLinker
                          ) || { name: "none" }
                        ).name
                      }
                    </td>
                  ) : null}
                  {Subjects.length > 0 ? (
                    <td>
                      {
                        (
                          Subjects.find(
                            (subject) =>
                              subject.linker == activity.subjectLinker
                          ) || { name: "none" }
                        ).name
                      }
                    </td>
                  ) : null}
                  <td>
                    <button
                      className="btn btn-xs btn-outline-info mx-1"
                      onClick={() => {
                        setShowModal(true);
                        setType("edit");
                        setActivity({ ...activity });
                      }}
                    >
                      {" "}
                      <PencilSquare style={{ cursor: "pointer" }} />
                    </button>
                    <button
                      className="btn btn-xs btn-outline-danger mx-1"
                      onClick={() => {
                        setShowModal(true);
                        setType("delete");
                        setActivity({ ...activity });
                      }}
                    >
                      {" "}
                      <Trash style={{ cursor: "pointer" }} />
                    </button>
                  </td>
                </tr>
              )}
            />
          </tbody>
        ) : (
          <NoActivity></NoActivity>
        )}
      </table>
      <GeneratePdf
        id={"activities"}
        name={"units-or-subjects-activities"}
      ></GeneratePdf>
      <ActivityCrud
        setShowModal={setShowModal}
        ShowModal={ShowModal}
        activity={Activity}
        setActivity={setActivity}
        type={Type}
      ></ActivityCrud>
    </div>
  );
};

export default Activities;

import React from "react";
import { ApiUrl } from "../Network/Urls";
import { useSelector } from "react-redux";

const LetterHead = () => {
	const Inst = useSelector((state) => state.inst.activeInst);
	return (
		<div className="d-flex justify-content-between m-2">
			<div>
				<h5 style={{ margin: 0 }} className="text-decoration-underline">
					<strong>{Inst.name}</strong>
				</h5>
				<p style={{ margin: 0 }} className="text-decoration-underline">
					<strong>
						<em>{Inst.motto}</em>
					</strong>
				</p>
				<p style={{ margin: 0 }} className="text-decoration-underline">
					<em>{Inst.contact}</em>
				</p>
				<p style={{ margin: 0 }} className="text-decoration-underline">
					<em>{Inst.email}</em>
				</p>
			</div>
			<div className="d-flex align-content-center justify-content-start">
				<img
					src={
						Inst.logo?.length > 500
							? `${Inst.logo}`
							: `${ApiUrl}/uploads/${Inst.logo}`
					}
					alt=""
					className="img-thumbnail"
					style={{ height: 100, width: "auto" }}
				/>
			</div>
		</div>
	);
};

export default LetterHead;

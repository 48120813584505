import React, { useState, useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import { PurchaseMessages } from "../../../Network/MessageApi";
import { useSelector } from "react-redux";

const PurchaseModal = ({ ShowModal, setShowModal }) => {
	useEffect(() => {
		setAmount("");
		setPhone("");
	}, [ShowModal]);

	const User = useSelector((state) => state.auth.user);
	const ActiveCred = useSelector((state) => state.cred.activeCred);

	const [Amount, setAmount] = useState("");
	const [Phone, setPhone] = useState("");
	const [Loading, setLoading] = useState(false);

	const HandleSubmit = (e) => {
		e.preventDefault();
		PurchaseMessages(
			User.token,
			ActiveCred.instLinker,
			Phone.toString().replace(/^\+/, "").replace(/^0/, "254"),
			Amount,
			setLoading
		);
	};

	return (
		<Modal
			show={ShowModal}
			onHide={() => setShowModal(false)}
			backdrop="static"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<p className="text-capitalize text-center">
						Purchase SMS At Only 0.5 Per Message Through Mpesa
					</p>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={HandleSubmit}>
					<div className="form-group">
						<label className="mb-2 text-capitalize">
							Enter Amount:<em>{`{${Amount * 2} SMS}`}</em>
						</label>
						<input
							className="rounded form-control"
							placeholder={`Amount To Pay`}
							value={Amount}
							onChange={(e) => setAmount(e.target.value)}
							required
							type={"number"}
							min={1}
						/>
						<hr />
					</div>

					<div className="form-group">
						<label className="mb-2 text-capitalize">
							M-pesa Phone Number To Pay From:
						</label>
						<input
							className="rounded form-control"
							placeholder={`enter mpesa phone number`}
							value={Phone}
							onChange={(e) => setPhone(e.target.value)}
							type="number"
							required
							min={9999999}
						/>
						<hr />
					</div>
					<div className="d-flex justify-content-around mb-2">
						{Loading ? (
							<span className="spinner-border text-primary"></span>
						) : (
							<button type="submit" className="btn btn-success rounded-pill">
								Purchase
							</button>
						)}
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default PurchaseModal;

import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addLiveBook, updateLiveBook } from "../../../Network/BookApi";

const BookCrud = (props) => {
  //redux dispatch
  const dispatch = useDispatch();

  const online = useSelector((state) => state.online.online);
  const User = useSelector((state) => state.auth.user);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const [Loading, setLoading] = useState(false);

  //add book
  const AddBook = async () => {
    let linker = Date.now();
    await addLiveBook(
      {
        linker,
        bookStatus: 0,
        issuedOn: 0,
        returnOn: 0,
        studentLinker: 0,
        bookCode: props.book.bookCode,
        catLinker: props.book.catLinker,
        name: props.book.name,
        details: props.book.details,
        author: props.book.author,
        instLinker: ActiveCred.linker,
        credLinker: ActiveCred.linker,
        live: 1,
        status: 0,
        trace: linker,
        deleted: 0,
      },
      ActiveCred.instLinker,
      User.token,
      dispatch
    );

    props.setBook({
      ...props.book,
      name: "",
      details: "",
      author: "",
    });
  };

  //edit book
  const EditBook = async () => {
    let {
      credLinker,

      trace,

      deleted,
    } = props.book;

    trace = Date.now();
    credLinker = ActiveCred.linker;
    deleted = props.type === "delete" ? 1 : deleted;

    await updateLiveBook(
      { ...props.book, trace, credLinker, deleted },
      User.token,
      dispatch
    );
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (props.type === "add") {
      await AddBook();
    } else {
      await EditBook();
    }
    setLoading(false);
    props.setShowModal(false);
  };

  return (
    <Modal
      show={props.ShowModal}
      onHide={() => props.setShowModal(false)}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="text-capitalize text-center">{props.type} Book</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleSubmit}>
          <div className="form-group">
            <label className="mb-2 text-capitalize"> Book Code:</label>
            <input
              className="rounded form-control"
              placeholder={`enter book code`}
              value={props.book.bookCode}
              onChange={(e) =>
                props.setBook({
                  ...props.book,
                  bookCode: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>
          <div className="form-group">
            <label className="mb-2 text-capitalize"> Book Title:</label>
            <input
              className="rounded form-control"
              placeholder={`enter book title`}
              value={props.book.name}
              onChange={(e) =>
                props.setBook({
                  ...props.book,
                  name: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>

          <div className="form-group">
            <label className="mb-2 text-capitalize"> Book Author:</label>
            <input
              className="rounded form-control"
              placeholder={`enter book author`}
              value={props.book.author}
              onChange={(e) =>
                props.setBook({
                  ...props.book,
                  author: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>

          {props.Cats.length > 0 ? (
            <div className="form-group">
              <label> Book Category:</label>
              <select
                className="rounded form-control"
                value={props.book.catLinker}
                onChange={(e) =>
                  props.setBook({ ...props.book, catLinker: e.target.value })
                }
                required
              >
                <option value={""}>Select Category</option>
                {props.Cats.map((cat, index) => (
                  <option key={index} value={cat.linker}>
                    {cat.name}
                  </option>
                ))}
              </select>
              <hr />
            </div>
          ) : null}
          <div className="form-group">
            <label className="mb-2 text-capitalize">Book Details:</label>
            <textarea
              className="rounded form-control"
              placeholder={`enter book details`}
              value={props.book.details}
              onChange={(e) =>
                props.setBook({
                  ...props.book,
                  details: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>
          <div className="d-flex justify-content-around mb-2">
            {Loading ? (
              <span className="spinner-border text-primary"></span>
            ) : props.type === "delete" ? (
              <Button variant="danger" type="submit">
                Delete
              </Button>
            ) : (
              <Button variant="primary" type="submit">
                Save
              </Button>
            )}
            <Button
              variant="secondary"
              type="button"
              onClick={() => props.setShowModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default BookCrud;

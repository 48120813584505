import swal from "sweetalert";
import {
  addSubject,
  fetchSubjects,
  updateSubject,
} from "../reducers/SubjectSlice";
import { ApiUrl } from "./Urls";

export const FetchSubjects = async (
  instLinker,
  token,
  dispatch,
  online,
  from = "fetch",
  payload = {}
) => {
  if (from === "fetch") {
    FetchLiveSubjects(token, instLinker, online, dispatch);
  } else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
    dispatch(updateSubject(payload));
  }
};

export const addLiveSubject = (subject, instLinker, token, dispatch) => {
  return fetch(`${ApiUrl}/subject/add`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...subject,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 201) {
        dispatch(updateSubject(res.subject));
      } else {
        swal({
          title: "Failed to connect",
          icon: "warning",
          timer: 3000,
        });
        return 0;
      }
    })
    .catch((err) => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
      return { status: "error" };
    });
};

export const updateLiveSubject = (subject, token, dispatch) => {
  return fetch(`${ApiUrl}/subject/edit`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...subject,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(updateSubject(res.subject));
      } else {
        swal({
          title: "Failed to connect",
          icon: "warning",
          timer: 3000,
        });
      }
    })
    .catch((err) => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
      return { status: "error" };
    });
};

export const FetchLiveSubjects = (token, instLinker, online, dispatch) => {
  fetch(`${ApiUrl}/subject/get`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      online,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(fetchSubjects(res.subjects));
      }
    })
    .catch(() => {});
};

import React from "react";

const NoSubject = () => {
  return (
    <tbody>
      <tr>
        <td>No Subjects/Units</td>

        <td>
          Subjects/Units are various classes being taught in your institution eg
          maths, physics, company law.
        </td>
      </tr>
    </tbody>
  );
};

export default NoSubject;

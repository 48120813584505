import { createSlice } from "@reduxjs/toolkit";

export const BookSlice = createSlice({
  name: "book",
  initialState: {
    books: [],
  },
  reducers: {
    fetchBooks: (state, action) => {
      state.books = action.payload;
    },
    addBook: (state, action) => {
      state.books = [action.payload, ...state.books];
    },
    updateBook: (state, action) => {
      let index = state.books.findIndex(
        (book) => parseInt(book.linker) === parseInt(action.payload.linker)
      );

      let prevBooks = [...state.books];

      prevBooks.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.books = prevBooks;
    },
    deleteBook: (state, action) => {
      let newBooks = state.books.filter(
        (book) => parseInt(book.linker) !== parseInt(action.payload.linker)
      );
      state.books = newBooks;
    },
  },
});

export const { fetchBooks, addBook, updateBook, deleteBook } =
  BookSlice.actions;

export default BookSlice.reducer;

import React from "react";
import { useParams } from "react-router-dom";

const NoStructure = () => {
  const params = useParams();
  return (
    <tbody>
      <tr>
        <td>No {`${params.structureType}s`}</td>
        {params.structureType === "level" ? (
          <td>
            Levels are various classes or ranks that students move through in
            your institution eg classes, grades, 1st year. <br /> You can add
            them for ease of student classification. <br /> If you don't use
            levels in your institution you can add one or none
          </td>
        ) : params.structureType === "period" ? (
          <td>
            Periods are various learning and examinable time intervals in a year
            or per level eg terms, semesters. <br /> If you don't use periods in
            your institution you can add one or none
          </td>
        ) : params.structureType === "faculty" ? (
          <td>
            Faculties are various students departments in your institution
            mostly for higher learning eg accounting, finance, law, medicine.{" "}
            <br /> If you don't use faculties in your institution you can add
            one or none
          </td>
        ) : params.structureType === "academic-year" ? (
          <td>
            Academic Years are various learning periods in your institution eg
            2023, 2023-2024
            <br /> If you don't use academic years in your institution you can
            add one or none
          </td>
        ) : (
          ""
        )}
      </tr>
    </tbody>
  );
};

export default NoStructure;

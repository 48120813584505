import React from "react";
import { Book, CashCoin, Mortarboard } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ClientNav = ({ setDrawer }) => {
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const Navigate = useNavigate();
	return (
		<>
			<p
				onClick={() => {
					window.scroll(0, 0);
					setDrawer(false);
					Navigate(
						`students/student/academics/${
							parseInt(ActiveCred.guardian) === 1
								? ActiveCred.refLinker
								: ActiveCred.linker
						}`
					);
				}}
			>
				<strong className="nav-link text-truncate">
					<Mortarboard /> Academics
				</strong>
			</p>
			<hr />{" "}
			<p
				onClick={() => {
					window.scroll(0, 0);
					setDrawer(false);
					Navigate(
						`students/student/finance/${
							parseInt(ActiveCred.guardian) === 1
								? ActiveCred.refLinker
								: ActiveCred.linker
						}`
					);
				}}
			>
				<strong className="nav-link text-truncate">
					<CashCoin /> Finances
				</strong>
			</p>
			<hr />{" "}
			<p
				onClick={() => {
					window.scroll(0, 0);
					setDrawer(false);
					Navigate(
						`students/student/books/${
							parseInt(ActiveCred.guardian) === 1
								? ActiveCred.refLinker
								: ActiveCred.linker
						}`
					);
				}}
			>
				<strong className="nav-link text-truncate">
					<Book /> Books
				</strong>
			</p>
			<hr />
		</>
	);
};

export default ClientNav;

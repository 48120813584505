import { createSlice } from "@reduxjs/toolkit";

export const StructureSlice = createSlice({
  name: "structure",
  initialState: {
    structures: [],
  },
  reducers: {
    fetchStructures: (state, action) => {
      state.structures = action.payload;
    },
    addStructure: (state, action) => {
      state.structures = [action.payload, ...state.structures];
    },
    updateStructure: (state, action) => {
      let index = state.structures.findIndex(
        (structure) =>
          parseInt(structure.linker) === parseInt(action.payload.linker)
      );

      let prevStructures = [...state.structures];

      prevStructures.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.structures = prevStructures;
    },
    deleteStructure: (state, action) => {
      let newStructures = state.structures.filter(
        (structure) =>
          parseInt(structure.linker) !== parseInt(action.payload.linker)
      );
      state.structures = newStructures;
    },
  },
});

export const {
  fetchStructures,
  addStructure,
  updateStructure,
  deleteStructure,
} = StructureSlice.actions;

export default StructureSlice.reducer;

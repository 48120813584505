import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addLiveStructure,
  updateLiveStructure,
} from "../../../Network/StructureApi";

const StructureCrud = (props) => {
  const params = useParams();
  //redux dispatch
  const dispatch = useDispatch();

  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const User = useSelector((state) => state.auth.user);
  const [Loading, setLoading] = useState(false);

  //add structure
  const AddStructure = async () => {
    setLoading(true);
    let linker = Date.now();

    await addLiveStructure(
      {
        type: params.structureType,
        linker,
        name: props.structure.name,
        instLinker: ActiveCred.linker,
        credLinker: ActiveCred.linker,
        live: 1,
        status: 0,
        trace: linker,
        deleted: 0,
      },
      ActiveCred.instLinker,
      User.token,
      dispatch
    );

    setLoading(false);

    props.setStructure({
      ...props.structure,
      name: "",
    });
  };

  //edit structure
  const EditStructure = async () => {
    setLoading(true);

    let { credLinker, trace, deleted } = props.structure;

    trace = Date.now();
    credLinker = ActiveCred.linker;
    deleted = props.type === "delete" ? 1 : deleted;

    await updateLiveStructure(
      { ...props.structure, trace, credLinker, deleted },
      User.token,
      dispatch
    );

    setLoading(false);
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    if (props.type === "add") {
      await AddStructure();
    } else {
      await EditStructure();
    }
    props.setShowModal(false);
  };

  return (
    <div>
      <Modal
        show={props.ShowModal}
        onHide={() => props.setShowModal(false)}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <p className="text-capitalize text-center">
              {props.type} {params.structureType}
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={HandleSubmit}>
            <div className="form-group">
              <label className="mb-2 text-capitalize">
                {params.structureType} Name:
              </label>
              <input
                className="rounded form-control"
                placeholder={`${params.structureType} name`}
                value={props.structure.name}
                onChange={(e) =>
                  props.setStructure({
                    ...props.structure,
                    name: e.target.value,
                  })
                }
                required
                readOnly={props.type === "delete" ? true : false}
              />
              <hr />
            </div>
            <div className="d-flex justify-content-around mb-2">
              {Loading ? (
                <span className="spinner-border text-primary"></span>
              ) : props.type === "delete" ? (
                <Button variant="danger" type="submit">
                  Delete
                </Button>
              ) : (
                <Button variant="primary" type="submit">
                  Save
                </Button>
              )}
              <Button
                variant="secondary"
                type="button"
                onClick={() => props.setShowModal(false)}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default StructureCrud;

import { createSlice } from "@reduxjs/toolkit";

export const ExamSlice = createSlice({
  name: "exam",
  initialState: {
    exams: [],
  },
  reducers: {
    fetchExams: (state, action) => {
      state.exams = action.payload;
    },
    addExam: (state, action) => {
      state.exams = [action.payload, ...state.exams];
    },
    updateExam: (state, action) => {
      let index = state.exams.findIndex(
        (exam) => parseInt(exam.linker) === parseInt(action.payload.linker)
      );

      let prevExams = [...state.exams];

      prevExams.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.exams = prevExams;
    },
    deleteExam: (state, action) => {
      let newExams = state.exams.filter(
        (exam) => parseInt(exam.linker) !== parseInt(action.payload.linker)
      );
      state.exams = newExams;
    },
  },
});

export const { fetchExams, addExam, updateExam, deleteExam } =
  ExamSlice.actions;

export default ExamSlice.reducer;

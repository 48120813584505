import React from "react";

const NoExam = () => {
  return (
    <tbody>
      <tr>
        <th>No Scores</th>
      </tr>
    </tbody>
  );
};

export default NoExam;

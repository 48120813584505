import swal from "sweetalert";
import {
  addExpenseCat,
  fetchExpenseCats,
  updateExpenseCat,
} from "../reducers/ExpenseCatSlice";
import { ApiUrl } from "./Urls";

export const FetchExpenseCats = async (
  token,
  instLinker,
  dispatch,
  online,
  from = "fetch",
  payload = {}
) => {
  if (from === "fetch") {
    FetchLiveExpenseCats(token, instLinker, online, dispatch);
  } else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
    dispatch(updateExpenseCat(payload));
  }
};

export const addLiveExpenseCat = (expenseCat, instLinker, token, dispatch) => {
  return fetch(`${ApiUrl}/expense-categories/add`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...expenseCat,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 201) {
        dispatch(updateExpenseCat(res.expenseCat));
      }
    })
    .catch(() => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};

export const updateLiveExpenseCat = (expenseCat, token, dispatch) => {
  return fetch(`${ApiUrl}/expense-categories/edit`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...expenseCat,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(updateExpenseCat(res.expenseCat));
      }
    })
    .catch((err) => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
      return { status: "error" };
    });
};

export const FetchLiveExpenseCats = (token, instLinker, online, dispatch) => {
  fetch(`${ApiUrl}/expense-categories/get`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      online,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(fetchExpenseCats(res.expenseCats));
      }
    })
    .catch(() => {});
};

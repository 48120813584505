import React from "react";
import { useSelector } from "react-redux";
import { Trash, PencilSquare } from "react-bootstrap-icons";

const InvoiceDisp = ({ invoice, setShowModal, setType, setInvoice }) => {
  const Creds = useSelector((state) => state.cred.creds);

  const FindUser = (linker) => {
    let cred = Creds.find((cred) => parseInt(cred.linker) == parseInt(linker));

    return cred || { firstname: "unknown" };
  };

  return (
    <tr className="item">
      <td>{invoice.name}</td>
      <td>{invoice.details}</td>
      <td>{invoice.amount}</td>
      <td>
        {FindUser(invoice.studentLinker).studentReg}{" "}
        {FindUser(invoice.studentLinker).firstname}{" "}
        {FindUser(invoice.studentLinker).lastname}
      </td>
      <td>{new Date(parseInt(invoice.linker)).toLocaleDateString("en-US")}</td>
      <td>{FindUser(invoice.credLinker).email}</td>
      <td>
        <PencilSquare
          onClick={() => {
            setShowModal(true);
            setType("edit");
            setInvoice({ ...invoice });
          }}
          style={{ cursor: "pointer" }}
          className="text-info mx-2"
        />

        <Trash
          onClick={() => {
            setShowModal(true);
            setType("delete");
            setInvoice({ ...invoice });
          }}
          style={{ cursor: "pointer" }}
          className="text-danger mx-2"
        />
      </td>
    </tr>
  );
};

export default InvoiceDisp;

import React from "react";

const NoSubject = () => {
  return (
    <tbody>
      <tr>
        <td>No Assesments</td>

        <td>
          Assesments are various examinable or score related students activities
          eg Exams, Continous Assesment Tests, Assignments, Practicals
        </td>
      </tr>
    </tbody>
  );
};

export default NoSubject;

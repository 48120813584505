import { createSlice } from "@reduxjs/toolkit";

export const StoreEntrySlice = createSlice({
  name: "storeEntry",
  initialState: {
    storeEntries: [],
  },
  reducers: {
    fetchStoreEntries: (state, action) => {
      state.storeEntries = action.payload;
    },
    addStoreEntry: (state, action) => {
      state.storeEntries = [action.payload, ...state.storeEntries];
    },
    updateStoreEntry: (state, action) => {
      let index = state.storeEntries.findIndex(
        (storeEntry) =>
          parseInt(storeEntry.linker) === parseInt(action.payload.linker)
      );

      let prevStoreEntries = [...state.storeEntries];

      prevStoreEntries.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.storeEntries = prevStoreEntries;
    },
    deleteStoreEntry: (state, action) => {
      let newStoreEntries = state.storeEntries.filter(
        (storeEntry) =>
          parseInt(storeEntry.linker) !== parseInt(action.payload.linker)
      );
      state.storeEntries = newStoreEntries;
    },
  },
});

export const {
  fetchStoreEntries,
  addStoreEntry,
  updateStoreEntry,
  deleteStoreEntry,
} = StoreEntrySlice.actions;

export default StoreEntrySlice.reducer;

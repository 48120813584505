import { createSlice } from "@reduxjs/toolkit";

export const ActivitySlice = createSlice({
  name: "activity",
  initialState: {
    activities: [],
  },
  reducers: {
    fetchActivities: (state, action) => {
      state.activities = action.payload;
    },
    addActivity: (state, action) => {
      state.activities = [action.payload, ...state.activities];
    },
    updateActivity: (state, action) => {
      let index = state.activities.findIndex(
        (activity) =>
          parseInt(activity.linker) === parseInt(action.payload.linker)
      );

      let prevActivities = [...state.activities];

      prevActivities.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.activities = prevActivities;
    },
    deleteActivity: (state, action) => {
      let newActivities = state.activities.filter(
        (activity) =>
          parseInt(activity.linker) !== parseInt(action.payload.linker)
      );
      state.activities = newActivities;
    },
  },
});

export const { fetchActivities, addActivity, updateActivity, deleteActivity } =
  ActivitySlice.actions;

export default ActivitySlice.reducer;

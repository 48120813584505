import swal from "sweetalert";
import { addSub, fetchSubs, updateSub } from "../reducers/SubSlice";
import { ApiUrl } from "./Urls";

export const FetchSubs = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	if (from === "fetch") {
		FetchLiveSubs(token, instLinker, online, dispatch);
	} else if (
		parseInt(instLinker) === parseInt(payload.instLinker) ||
		parseInt(payload.softwareId) ===
			parseInt(parseInt(`${instLinker}`.split("").reverse().join("")) / 1000)
	) {
		dispatch(updateSub(payload));
	}
};

export const addLiveSub = (sub, instLinker, token, dispatch) => {
	return fetch(`${ApiUrl}/sub/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...sub,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateSub(res.sub));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const updateLiveSub = (sub, token, dispatch) => {
	return fetch(`${ApiUrl}/sub/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...sub,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateSub(res.sub));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const FetchLiveSubs = (token, instLinker, online, dispatch) => {
	fetch(`${ApiUrl}/sub/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(fetchSubs(res.subs));
			}
		})
		.catch(() => {});
};

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SendMessages } from "../../../Network/MessageApi";
import PurchaseModal from "./PurchaseModal";

const BulkSms = () => {
	//redux dispatch
	const dispatch = useDispatch();

	const User = useSelector((state) => state.auth.user);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const inst = useSelector((state) => state.inst.activeInst);
	const messages = useSelector((state) => state.message.messages)
		.filter((message) => message.id)
		.sort((a, b) => a.id - b.id);

	const [Level, setLevel] = useState("all");
	const [Period, setPeriod] = useState("all");
	const [Faculty, setFaculty] = useState("all");
	const [Year, setYear] = useState("all");
	const [Message, setMessage] = useState("");
	const [Loading, setLoading] = useState(false);
	const [ShowModal, setShowModal] = useState(false);
	const [Student, setStudent] = useState(1);
	const [Guardian, setGuardian] = useState(1);

	const Structures = useSelector((state) => state.structure.structures);

	const Levels = Structures.filter(
		(structure) =>
			structure.type === "level" && parseInt(structure.deleted) === 0
	);

	const Periods = Structures.filter(
		(structure) =>
			structure.type === "period" && parseInt(structure.deleted) === 0
	);

	const Faculties = Structures.filter(
		(structure) =>
			structure.type === "faculty" && parseInt(structure.deleted) === 0
	);

	const Years = Structures.filter(
		(structure) =>
			structure.type === "academic-year" && parseInt(structure.deleted) === 0
	);

	const Students = useSelector((state) => state.cred.creds)
		.filter(
			(student) =>
				student.deleted == "0" &&
				student.student == 1 &&
				(parseInt(student.level) === parseInt(Level) || Level === "all") &&
				(parseInt(student.period) === parseInt(Period) || Period === "all") &&
				(parseInt(student.faculty) === parseInt(Faculty) ||
					Faculty === "all") &&
				(parseInt(student.year) === parseInt(Year) || Year === "all")
		)
		.sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

	const Guardians = useSelector((state) => state.cred.creds).filter(
		(guardian) =>
			Students.some(
				(student) => parseInt(student.linker) === parseInt(guardian.refLinker)
			)
	);

	const Recipients = [
		...(Student === 1 ? Students : []),
		...(Guardian === 1 ? Guardians : []),
	];

	const HandleSend = (e) => {
		e.preventDefault();
		//ensure tenat has contact then create message object
		let Messages = Recipients.filter(
			(recipient) => !/^$/.test(recipient.contact) && recipient.contact
		).map((recipient) => ({
			mobile: recipient.contact
				.toString()
				.replace(/^\+/, "")
				.replace(/^0/, "254"),
			message: `${inst.name
				.split(" ")
				.map((w) => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
				.join(" ")}: ${Message}`,
		}));

		// group messages in chunks of 20
		let Chunks = Messages.reduce(
			(acc, _, i) => (i % 20 ? acc : [...acc, Messages.slice(i, i + 20)]),
			[]
		);

		//maps chunks and send each chunk
		Chunks.map((chunk) =>
			SendMessages(
				User.token,
				ActiveCred.instLinker,
				chunk,
				dispatch,
				setLoading
			)
		);
	};

	return (
		<>
			<div className="row justify-content-around">
				<div className="col-md-12 col-lg-12 d-flex justify-content-around align-items-center">
					<p className="h6">
						You Have{" "}
						{messages.length > 0 ? messages[messages.length - 1].cf : 0}{" "}
						Messages
					</p>
					<button
						className="btn btn-outline-success rounded"
						onClick={() => setShowModal(true)}
					>
						Purchase Messages
					</button>
				</div>
			</div>{" "}
			<div className="row justify-content-around">
				<div className="col-md-12 col-lg-8">
					<div className="card">
						<div className="card-header">
							<p className="card-title">
								Message {Recipients.length} Recipients :{" "}
								<em className="text-danger">
									{Recipients.length >
									(messages.length > 0 ? messages[messages.length - 1].cf : 0)
										? `{No Enough Messages; Purchase!!}`
										: null}
								</em>
							</p>
						</div>
						<form onSubmit={HandleSend}>
							<div className="card-body">
								{Years.length > 0 ? (
									<div className="form-group">
										<label>Select Academic Year:</label>
										<select
											className="form-control rounded"
											onChange={(e) => setYear(e.target.value)}
										>
											<option value={"all"}>All AcYears</option>
											{Years.map((Year, index) => (
												<option key={index} value={Year.linker}>
													{Year.name}
												</option>
											))}
										</select>
										<hr />
									</div>
								) : null}
								{Faculties.length > 0 ? (
									<div className="form-group">
										<label>Select Faculty/Department:</label>
										<select
											className="form-control rounded"
											onChange={(e) => setFaculty(e.target.value)}
										>
											<option value={"all"}>All Faculties</option>
											{Faculties.map((faculty, index) => (
												<option key={index} value={faculty.linker}>
													{faculty.name}
												</option>
											))}
										</select>
										<hr />
									</div>
								) : null}

								{Levels.length > 0 ? (
									<div className="form-group">
										<label>Select Level/Class:</label>
										<select
											className="form-control rounded"
											onChange={(e) => setLevel(e.target.value)}
										>
											<option value={"all"}>All Levels</option>
											{Levels.map((level, index) => (
												<option key={index} value={level.linker}>
													{level.name}
												</option>
											))}
										</select>
										<hr />
									</div>
								) : null}
								{Periods.length > 0 ? (
									<div className="form-group">
										<label>Select Period/Term/Semester:</label>
										<select
											className="form-control rounded"
											onChange={(e) => setPeriod(e.target.value)}
										>
											<option value={"all"}>All Periods</option>
											{Periods.map((period, index) => (
												<option key={index} value={period.linker}>
													{period.name}
												</option>
											))}
										</select>
										<hr />
									</div>
								) : null}

								<div className="form-group">
									<label>Message:</label>
									<textarea
										required
										placeholder="Type message..."
										value={Message}
										onChange={(e) => setMessage(e.target.value)}
										className="form-control rounded"
									/>
								</div>
								<div className="form-group">
									<label className="mb-2 text-capitalize">
										Select Recipients:
									</label>
									<div className="row">
										<div className="col-md-4 d-flex justify-content-around">
											<span>Students : </span>{" "}
											<input
												type="checkbox"
												onChange={() => setStudent(Student === 1 ? 0 : 1)}
												checked={Student === 1 ? true : false}
											/>
										</div>
										<div className="col-md-4 d-flex justify-content-around">
											<span>Guardians :</span>{" "}
											<input
												type="checkbox"
												onChange={() => setGuardian(Guardian === 1 ? 0 : 1)}
												checked={Guardian === 1 ? true : false}
											/>
										</div>
									</div>

									<hr />
								</div>
							</div>
							<div className="card-footer d-flex justify-content-around">
								{Loading ? (
									<span className="text-success spinner-border"></span>
								) : Recipients.length >
								  (messages.length > 0
										? messages[messages.length - 1].cf
										: 0) ? (
									<span className="text-center text-danger h6">
										{" "}
										{`{No Enough Messages To Send; Purchase Above}`}
									</span>
								) : (
									<button type="submit" className="btn btn-success">
										Send Sms
									</button>
								)}
							</div>
						</form>
					</div>
				</div>
			</div>
			<PurchaseModal
				ShowModal={ShowModal}
				setShowModal={setShowModal}
			></PurchaseModal>
		</>
	);
};

export default BulkSms;

import React, { useEffect } from "react";
import { socket } from "./SockectConnect";

const Online = () => {
  useEffect(() => {
    socket.on("connect", () => {
      console.log("connected");
    });

    socket.on("disconnect", () => {});
  }, []);

  return <></>;
};

export default Online;

import React from "react";
import { useParams } from "react-router-dom";

const NoLibraryCats = () => {
  const params = useParams();
  return (
    <tbody>
      <tr>
        <td>No Library Books Categories</td>

        <td>Books categories are various books groupings in the library</td>
      </tr>
    </tbody>
  );
};

export default NoLibraryCats;

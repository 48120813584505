import React, { useEffect } from "react";
import { FetchBooks } from "../Network/BookApi";
import { socket } from "./SockectConnect";
import { useSelector, useDispatch } from "react-redux";
import { FetchActivities } from "../Network/ActivityApi";
import { FetchLocalStuctures } from "../Network/StructureApi";
import { FetchSubjects } from "../Network/SubjectApi";
import { FetchStaffs, FetchStudents } from "../Network/CredApi";
import { FetchModes } from "../Network/ModeApi";
import { FetchInvoices } from "../Network/InvoiceApi";
import { FetchPayments } from "../Network/PaymentApi";
import { FetchExpenseCats } from "../Network/ExpenseCatApi";
import { FetchExpenses } from "../Network/ExpenseApi";
import { FetchExams } from "../Network/ExamApi";
import { FetchIntervals } from "../Network/IntervalApi";
import { FetchLessons } from "../Network/LessonApi";
import { FetchLibraryCats } from "../Network/LibraryCatApi";
import { FetchStoreCats } from "../Network/StoreCatApi";
import { FetchStoreEntries } from "../Network/StoreEntryApi";
import { FetchItems } from "../Network/ItemApi";
import { FetchSubs } from "../Network/SubApi";
import { FetchGrades } from "../Network/GradeApi";
import { FetchNotifications } from "../Network/NotificationApi";
import { FetchMpesaPayments } from "../Network/MpesaApi";
import { FetchMessages } from "../Network/MessageApi";

const SocketMessages = () => {
	//redux dispatch
	const dispatch = useDispatch();

	const User = useSelector((state) => state.auth.user);
	const online = useSelector((state) => state.online.online);
	const ActiveCred = useSelector((state) => state.cred.activeCred);

	useEffect(() => {
		socket.on("message", async (message) => {
			UpdateMessage(message);
		});
	}, []);

	const UpdateMessage = (message) => {
		console.log(message);
		if (message.messageType === "notification") {
			if ("serviceWorker" in navigator && "PushManager" in window) {
				navigator.serviceWorker.ready
					.then((registration) => {
						registration.showNotification(message.dataValues.title, {
							body: message.dataValues.note,
							icon: "",
						});
					})
					.catch((err) => console.log(err));
			}
			FetchNotifications(
				User.token,
				ActiveCred.instLinker,
				dispatch,
				online,
				"socket",
				message.dataValues
			);
		}
		if (message.messageType === "structure") {
			FetchLocalStuctures(
				ActiveCred.instLinker,
				User.token,
				dispatch,
				online,
				"socket",
				message.dataValues
			);
		}
		if (message.messageType === "subject") {
			FetchSubjects(
				ActiveCred.instLinker,
				User.token,
				dispatch,
				online,
				"socket",
				message.dataValues
			);
		}
		if (message.messageType === "activity") {
			FetchActivities(
				ActiveCred.instLinker,
				User.token,
				dispatch,
				online,
				"socket",
				message.dataValues
			);
		}
		if (message.messageType === "grade") {
			FetchGrades(
				User.token,
				ActiveCred.instLinker,
				dispatch,
				online,
				"socket",
				message.dataValues
			);
		}
		if (message.messageType === "staff") {
			FetchStaffs(
				ActiveCred.instLinker,
				User.token,
				dispatch,
				online,
				"socket",
				message.dataValues
			);
		}
		if (message.messageType === "mode") {
			FetchModes(
				User.token,
				ActiveCred.instLinker,
				dispatch,
				online,
				"socket",
				message.dataValues
			);
		}
		if (message.messageType === "invoice") {
			FetchInvoices(
				User.token,
				ActiveCred.instLinker,
				dispatch,
				online,
				"socket",
				message.dataValues
			);
		}
		if (message.messageType === "payment") {
			FetchPayments(
				User.token,
				ActiveCred.instLinker,
				dispatch,
				online,
				"socket",
				message.dataValues
			);
		}
		if (message.messageType === "online-payment") {
			FetchMpesaPayments(
				User.token,
				ActiveCred.instLinker,
				dispatch,
				online,
				"socket",
				message.dataValues
			);
		}
		if (message.messageType === "expenseCat") {
			FetchExpenseCats(
				User.token,
				ActiveCred.instLinker,
				dispatch,
				online,
				"socket",
				message.dataValues
			);
		}
		if (message.messageType === "expense") {
			FetchExpenses(
				User.token,
				ActiveCred.instLinker,
				dispatch,
				online,
				"socket",
				message.dataValues
			);
		}
		if (message.messageType === "exam") {
			FetchExams(
				User.token,
				ActiveCred.instLinker,
				dispatch,
				online,
				"socket",
				message.dataValues
			);
		}
		if (message.messageType === "interval") {
			FetchIntervals(
				User.token,
				ActiveCred.instLinker,
				dispatch,
				online,
				"socket",
				message.dataValues
			);
		}
		if (message.messageType === "lesson") {
			FetchLessons(
				User.token,
				ActiveCred.instLinker,
				dispatch,
				online,
				"socket",
				message.dataValues
			);
		}
		if (message.messageType === "libraryCat") {
			FetchLibraryCats(
				User.token,
				ActiveCred.instLinker,
				dispatch,
				online,
				"socket",
				message.dataValues
			);
		}
		if (message.messageType === "book") {
			FetchBooks(
				User.token,
				ActiveCred.instLinker,
				dispatch,
				online,
				"socket",
				message.dataValues
			);
		}
		if (message.messageType === "student") {
			FetchStudents(
				ActiveCred.instLinker,
				User.token,
				dispatch,
				online,
				"socket",
				message.dataValues
			);
		}
		if (message.messageType === "student-transition") {
			FetchStudents(
				ActiveCred.instLinker,
				User.token,
				dispatch,
				online,
				"fetch"
			);
		}
		if (message.messageType === "guardian") {
			FetchStudents(
				ActiveCred.instLinker,
				User.token,
				dispatch,
				online,
				"socket",
				message.dataValues
			);
		}
		if (message.messageType === "storeCat") {
			FetchStoreCats(
				User.token,
				ActiveCred.instLinker,
				dispatch,
				online,
				"socket",
				message.dataValues
			);
		}
		if (message.messageType === "storeEntry") {
			FetchStoreEntries(
				User.token,
				ActiveCred.instLinker,
				dispatch,
				online,
				"socket",
				message.dataValues
			);
		}
		if (message.messageType === "storeItem") {
			FetchItems(
				User.token,
				ActiveCred.instLinker,
				dispatch,
				online,
				"socket",
				message.dataValues
			);
		}

		if (message.messageType === "sub") {
			console.log(`sub %${message}`);
			FetchSubs(
				User.token,
				ActiveCred.instLinker,
				dispatch,
				online,
				"socket",
				message.dataValues
			);
		}
		if (message.messageType === "sms") {
			FetchMessages(
				User.token,
				ActiveCred.instLinker,
				dispatch,
				online,
				"socket",
				message.dataValues
			);
		}
	};

	return <></>;
};

export default SocketMessages;

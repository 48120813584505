import { createSlice } from "@reduxjs/toolkit";

export const IntervalSlice = createSlice({
  name: "interval",
  initialState: {
    intervals: [],
  },
  reducers: {
    fetchIntervals: (state, action) => {
      state.intervals = action.payload;
    },
    addInterval: (state, action) => {
      state.intervals = [action.payload, ...state.intervals];
    },
    updateInterval: (state, action) => {
      let index = state.intervals.findIndex(
        (interval) =>
          parseInt(interval.linker) === parseInt(action.payload.linker)
      );

      let prevIntervals = [...state.intervals];

      prevIntervals.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.intervals = prevIntervals;
    },
    deleteInterval: (state, action) => {
      let newIntervals = state.intervals.filter(
        (interval) =>
          parseInt(interval.linker) !== parseInt(action.payload.linker)
      );
      state.intervals = newIntervals;
    },
  },
});

export const { fetchIntervals, addInterval, updateInterval, deleteInterval } =
  IntervalSlice.actions;

export default IntervalSlice.reducer;

import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateLiveBook } from "../../../Network/BookApi";
import { PlainList } from "flatlist-react";

const IssueBook = ({
  book,
  students,
  setBook,
  BookModal,
  setBookModal,
  GetStudent,
}) => {
  useEffect(() => {
    setSearch("");
  }, [BookModal]);

  //redux dispatch
  const dispatch = useDispatch();

  const [Search, setSearch] = useState("");
  const [Error, setError] = useState(false);

  const online = useSelector((state) => state.online.online);
  const User = useSelector((state) => state.auth.user);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const [Loading, setLoading] = useState(false);

  const SearchedStudents = students.filter(
    (student) =>
      (student.studentReg.toLowerCase().search(Search.toLowerCase()) > -1 ||
        student.firstname.toLowerCase().search(Search.toLowerCase()) > -1 ||
        student.lastname.toLowerCase().search(Search.toLowerCase()) > -1 ||
        student.surname.toLowerCase().search(Search.toLowerCase()) > -1 ||
        Search === "") &&
      parseInt(student.deleted) === 0
  );

  //edit book
  const EditBook = async () => {
    let { credLinker, bookStatus, trace, deleted } = book;

    trace = Date.now();
    credLinker = ActiveCred.linker;
    bookStatus = 1;

    await updateLiveBook(
      { ...book, trace, credLinker, deleted, bookStatus },
      User.token,
      dispatch
    );
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (book.studentLinker === "") {
      setError(true);
      return;
    }

    await EditBook();
    setLoading(false);

    setBookModal(false);
  };

  return (
    <Modal
      show={BookModal}
      onHide={() => setBookModal(false)}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="text-capitalize text-center">
            Issue {book.bookCode} {book.name} Book
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleSubmit}>
          <label className="mb-2 text-capitalize">Student To Issue: </label>
          <small>
            {GetStudent(book.studentLinker).studentReg}{" "}
            {GetStudent(book.studentLinker).firstname}{" "}
            {GetStudent(book.studentLinker).lastname}{" "}
            {GetStudent(book.studentLinker).surname}
          </small>
          {Error ? (
            <small className="text-danger">No Student Selected</small>
          ) : null}
          <div className="d-flex justify-contect-center">
            <Dropdown>
              <Dropdown.Toggle variant="transparent">
                <input
                  className="rounded mb-0"
                  placeholder={`Search Student`}
                  value={Search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <PlainList
                  list={SearchedStudents}
                  renderOnScroll
                  renderItem={(student, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => {
                        setBook({ ...book, studentLinker: student.linker });
                        setError(false);
                        setSearch(
                          `${student.studentReg} ${student.firstname} ${student.lastname} ${student.surname}`
                        );
                      }}
                    >
                      {student.studentReg} {student.firstname}{" "}
                      {student.lastname} {student.surname}
                    </Dropdown.Item>
                  )}
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className="d-flex justify-content-around mb-2">
            {Loading ? (
              <span className="spinner-border text-primary"></span>
            ) : (
              <Button variant="primary" type="submit">
                Save
              </Button>
            )}
            <Button
              variant="secondary"
              type="button"
              onClick={() => setBookModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default IssueBook;

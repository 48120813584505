import swal from "sweetalert";
import {
  addActivity,
  fetchActivities,
  updateActivity,
} from "../reducers/ActivitySlice";
import { ApiUrl } from "./Urls";

export const FetchActivities = async (
  instLinker,
  token,
  dispatch,
  lastonline,
  from = "fetch",
  payload = {}
) => {
  if (from === "fetch") {
    FetchLiveActivities(token, instLinker, lastonline, dispatch);
  } else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
    dispatch(updateActivity(payload));
  }
};

export const addLiveActivity = (activity, instLinker, token, dispatch) => {
  return fetch(`${ApiUrl}/activity/add`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...activity,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 201) {
        dispatch(updateActivity(res.activity));
      } else {
        swal({
          title: "Failed to connect",
          icon: "warning",
          timer: 3000,
        });
      }
    })
    .catch(() => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};

export const updateLiveActivity = (activity, token, dispatch) => {
  return fetch(`${ApiUrl}/activity/edit`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...activity,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(updateActivity(res.activity));
      } else {
        swal({
          title: "Failed to connect",
          icon: "warning",
          timer: 3000,
        });
      }
    })
    .catch(() => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};

export const FetchLiveActivities = (token, instLinker, online, dispatch) => {
  fetch(`${ApiUrl}/activity/get`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      online,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(fetchActivities(res.activities));
      }
    })
    .catch(() => {});
};

import React from "react";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";

const StudentDetails = ({ student, DetailsModal, setDetailsModal }) => {
  const Structures = useSelector((state) => state.structure.structures);
  const Levels = Structures.filter(
    (structure) =>
      structure.type === "level" && parseInt(structure.deleted) === 0
  );

  const Periods = Structures.filter(
    (structure) =>
      structure.type === "period" && parseInt(structure.deleted) === 0
  );

  const Faculties = Structures.filter(
    (structure) =>
      structure.type === "faculty" && parseInt(structure.deleted) === 0
  );

  const Years = Structures.filter(
    (structure) =>
      structure.type === "academic-year" && parseInt(structure.deleted) === 0
  );

  return (
    <Modal
      show={DetailsModal}
      onHide={() => {
        setDetailsModal(false);
      }}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {student.studentReg} {student.firstname} {student.lastname}{" "}
          {student.surname}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {Years.length > 0 ? (
          <p>
            <strong>Ac Year :</strong>{" "}
            <em>
              {
                (
                  Years.find((year) => year.linker == student.year) || {
                    name: "none",
                  }
                ).name
              }
            </em>
          </p>
        ) : null}
        {Faculties.length > 0 ? (
          <p>
            <strong>Faculty :</strong>{" "}
            <em>
              {" "}
              {
                (
                  Faculties.find(
                    (faculty) => faculty.linker == student.faculty
                  ) || {
                    name: "none",
                  }
                ).name
              }
            </em>
          </p>
        ) : null}
        {Levels.length > 0 ? (
          <p>
            <strong>Level/Class :</strong>{" "}
            <em>
              {" "}
              {
                (
                  Levels.find((level) => level.linker == student.level) || {
                    name: "none",
                  }
                ).name
              }
            </em>
          </p>
        ) : null}
        {Periods.length > 0 ? (
          <p>
            <strong>Period :</strong>{" "}
            <em>
              {" "}
              {
                (
                  Periods.find((period) => period.linker == student.period) || {
                    name: "none",
                  }
                ).name
              }
            </em>
          </p>
        ) : null}
        <p>
          <strong>Email :</strong> <em>{student.email}</em>
        </p>
        <p>
          <strong>Contact :</strong> <em>{student.contact}</em>
        </p>
        <p>
          <strong>Guardian One Name :</strong>{" "}
          <em>{student.guardianOneName}</em>
        </p>{" "}
        <p>
          <strong>Guardian One Contact :</strong>{" "}
          <em>{student.guardianOneContact}</em>
        </p>{" "}
        <p>
          <strong>Guardian One Email :</strong>{" "}
          <em>{student.guardianOneEmail}</em>
        </p>
        <p>
          <strong>Guardian Two Name :</strong>{" "}
          <em>{student.guardianTwoName}</em>
        </p>{" "}
        <p>
          <strong>Guardian Two Contact :</strong>{" "}
          <em>{student.guardianTwoContact}</em>
        </p>{" "}
        <p>
          <strong>Guardian Two Email :</strong>{" "}
          <em>{student.guardianTwoEmail}</em>
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default StudentDetails;

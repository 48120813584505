import swal from "sweetalert";
import {
  addInterval,
  fetchIntervals,
  updateInterval,
} from "../reducers/IntervalSlice";
import { ApiUrl } from "./Urls";

export const FetchIntervals = async (
  token,
  instLinker,
  dispatch,
  online,
  from = "fetch",
  payload = {}
) => {
  if (from === "fetch") {
    FetchLiveIntervals(token, instLinker, online, dispatch);
  } else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
    dispatch(updateInterval(payload));
  }
};

export const addLiveInterval = (interval, instLinker, token, dispatch) => {
  return fetch(`${ApiUrl}/interval/add`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...interval,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 201) {
        dispatch(updateInterval(res.interval));
      }
    })
    .catch(() => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};

export const updateLiveInterval = (interval, token, dispatch) => {
  return fetch(`${ApiUrl}/interval/edit`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...interval,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(updateInterval(res.interval));
      }
    })
    .catch(() => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};

export const FetchLiveIntervals = (token, instLinker, online, dispatch) => {
  fetch(`${ApiUrl}/interval/get`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      online,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(fetchIntervals(res.intervals));
      }
    })
    .catch(() => {});
};

import { fetchBooks } from "../reducers/BookSlice";
import { fetchExams } from "../reducers/ExamSlice";
import { fetchInvoices } from "../reducers/InvoiceSlice";
import { fetchPayments } from "../reducers/PaymentSlice";
import { ApiUrl } from "./Urls";

export const FetchClientData = (token, instLinker, dispatch, link) => {
  fetch(`${ApiUrl}/client/${link}`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        if (link === "payments") {
          dispatch(fetchPayments(res.payments));
        }
        if (link === "invoices") {
          dispatch(fetchInvoices(res.invoices));
        }
        if (link === "books") {
          dispatch(fetchBooks(res.books));
        }
        if (link === "exams") {
          dispatch(fetchExams(res.exams));
        }
      }
    })
    .catch(() => {});
};

import React from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
const MpesaNav = ({ setDrawer, navigate }) => {
	const inst = useSelector((state) => state.inst.activeInst);
	return inst.sub === "Kenya" ? (
		<>
			<Dropdown.Item>
				<p
					className="nav-link"
					onClick={() => {
						window.scroll(0, 0);
						setDrawer(false);
						navigate(`modes/mpesa-setup`);
					}}
				>
					Mpesa SetUp
				</p>
			</Dropdown.Item>
			<Dropdown.Item>
				<p
					className="nav-link"
					onClick={() => {
						window.scroll(0, 0);
						setDrawer(false);
						navigate(`mpesa-payments`);
					}}
				>
					Mpesa Payments
				</p>
			</Dropdown.Item>
		</>
	) : (
		<></>
	);
};

export default MpesaNav;

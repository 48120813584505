import React, { useState } from "react";
import { useSelector } from "react-redux";
import ExamCrud from "./ExamCrud";
import ExamDisp from "./ExamDisp";
import NoExam from "./NoExam";
import { PlainList } from "flatlist-react";
import GeneratePdf from "../../../Utils/GeneratePdf";

const ViewExam = ({
	Periods,
	Levels,
	Faculties,
	Years,
	Subjects,
	Activities,
	exam,
	setExam,
}) => {
	const AllStudents = useSelector((state) => state.cred.creds);
	const [ExamCrudModal, setExamCrudModal] = useState(false);
	const [Type, setType] = useState("add");

	const [Search, setSearch] = useState("");
	const [ActiveExam, setActiveExam] = useState({ student: {} });

	const GetStudent = (linker) => {
		let student = AllStudents.find(
			(student) => parseInt(student.linker) === parseInt(linker)
		);

		return (
			student || {
				studentReg: "unknown",
				firstname: "",
				lastname: "",
				surname: "",
			}
		);
	};

	const Exams = useSelector((state) => state.exam.exams)
		.map((Exam) => ({ ...Exam, student: GetStudent(Exam.studentLinker) }))
		.filter(
			(Exam) =>
				Exam.deleted == "0" &&
				(parseInt(Exam.levelLinker) === parseInt(exam.level) ||
					exam.level === "") &&
				(parseInt(Exam.periodLinker) === parseInt(exam.period) ||
					exam.period === "") &&
				(parseInt(Exam.facultyLinker) === parseInt(exam.faculty) ||
					exam.faculty === "") &&
				(parseInt(Exam.yearLinker) === parseInt(exam.year) ||
					exam.year === "") &&
				(parseInt(Exam.subjectLinker) === parseInt(exam.subject) ||
					exam.subject === "") &&
				(parseInt(Exam.activityLinker) === parseInt(exam.activity) ||
					exam.activity === "") &&
				(Exam.student.studentReg.toLowerCase().search(Search.toLowerCase()) >
					-1 ||
					Exam.student.firstname.toLowerCase().search(Search.toLowerCase()) >
						-1 ||
					Exam.student.lastname.toLowerCase().search(Search.toLowerCase()) >
						-1 ||
					Exam.student.surname.toLowerCase().search(Search.toLowerCase()) >
						-1 ||
					Search === "")
		)
		.sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

	return (
		<div>
			{" "}
			<table className="table table-sm" id="exams">
				<thead>
					<tr>
						<td>
							<input
								placeholder="search student"
								className="form-control rounded"
								value={Search}
								onChange={(e) => setSearch(e.target.value)}
							/>
						</td>
						{Years.length > 0 ? (
							<th>
								<select
									className="form-control rounded"
									onChange={(e) =>
										setExam({
											...exam,
											year: e.target.value,
										})
									}
								>
									<option value={""}>All AcYears</option>
									{Years.map((Year, index) => (
										<option key={index} value={Year.linker}>
											{Year.name}
										</option>
									))}
								</select>
							</th>
						) : null}
						{Faculties.length > 0 ? (
							<td>
								<select
									className="form-control rounded"
									onChange={(e) =>
										setExam({
											...exam,
											faculty: e.target.value,
										})
									}
								>
									<option value={""}>All Faculties</option>
									{Faculties.map((faculty, index) => (
										<option key={index} value={faculty.linker}>
											{faculty.name}
										</option>
									))}
								</select>
							</td>
						) : null}

						{Levels.length > 0 ? (
							<td>
								<select
									className="form-control rounded"
									onChange={(e) =>
										setExam({
											...exam,
											level: e.target.value,
										})
									}
								>
									<option value={""}>All Levels</option>
									{Levels.map((level, index) => (
										<option key={index} value={level.linker}>
											{level.name}
										</option>
									))}
								</select>
							</td>
						) : null}
						{Periods.length > 0 ? (
							<td>
								<select
									className="form-control rounded"
									onChange={(e) =>
										setExam({
											...exam,
											period: e.target.value,
										})
									}
								>
									<option value={""}>All Periods</option>
									{Periods.map((period, index) => (
										<option key={index} value={period.linker}>
											{period.name}
										</option>
									))}
								</select>
							</td>
						) : null}
						{Subjects.length > 0 ? (
							<td>
								<select
									className="form-control rounded"
									onChange={(e) =>
										setExam({
											...exam,
											subject: e.target.value,
										})
									}
								>
									<option value={""}>All Subjects</option>
									{Subjects.map((Subject, index) => (
										<option key={index} value={Subject.linker}>
											{Subject.name}
										</option>
									))}
								</select>
							</td>
						) : null}
						{Activities.length > 0 ? (
							<td>
								<select
									className="form-control rounded"
									onChange={(e) =>
										setExam({
											...exam,
											activity: e.target.value,
										})
									}
								>
									<option value={""}>All Activities</option>
									{Activities.map((Activity, index) => (
										<option key={index} value={Activity.linker}>
											{Activity.name}
										</option>
									))}
								</select>
							</td>
						) : null}
					</tr>
					<tr>
						<th>RegNo/AdmNo</th>
						<th>Name</th>

						<th>Score</th>
						<th>MaxScore</th>
						<th>Score%</th>
						<th>Grade</th>
						<th>Comment</th>
					</tr>
				</thead>
				{Exams.length > 0 ? (
					<tbody>
						<PlainList
							list={Exams}
							renderItem={(exam) => (
								<ExamDisp
									key={exam.linker}
									exam={exam}
									type={Type}
									setType={setType}
									ExamCrudModal={ExamCrudModal}
									setExamCrudModal={setExamCrudModal}
									setActiveExam={setActiveExam}
								></ExamDisp>
							)}
							renderOnScroll
						/>
					</tbody>
				) : (
					<NoExam></NoExam>
				)}
			</table>
			<GeneratePdf id={"exams"} name={"exams"}></GeneratePdf>
			<ExamCrud
				type={Type}
				ExamCrudModal={ExamCrudModal}
				setExamCrudModal={setExamCrudModal}
				Exam={ActiveExam}
				setExam={setActiveExam}
			></ExamCrud>
		</div>
	);
};

export default ViewExam;

import swal from "sweetalert";
import { addLibCat, fetchLibCats, updateLibCat } from "../reducers/LibCatSlice";
import { ApiUrl } from "./Urls";

export const FetchLibraryCats = async (
  token,
  instLinker,
  dispatch,
  online,
  from = "fetch",
  payload = {}
) => {
  if (from === "fetch") {
    FetchLiveLibraryCats(token, instLinker, online, dispatch);
  } else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
    dispatch(updateLibCat(payload));
  }
};

export const addLiveLibraryCat = (libraryCat, instLinker, token, dispatch) => {
  return fetch(`${ApiUrl}/library-categories/add`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...libraryCat,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 201) {
        dispatch(updateLibCat(res.libraryCat));
      }
    })
    .catch(() => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};

export const updateLiveLibraryCat = (libraryCat, token, dispatch) => {
  return fetch(`${ApiUrl}/library-categories/edit`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...libraryCat,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(updateLibCat(res.libraryCat));
      }
    })
    .catch(() => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};

export const FetchLiveLibraryCats = (token, instLinker, online, dispatch) => {
  fetch(`${ApiUrl}/library-categories/get`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      online,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(fetchLibCats(res.libraryCats));
      }
    })
    .catch(() => {});
};

import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addLiveInvoice } from "../../Network/InvoiceApi";

const StudentInvoice = (props) => {
  const [Name, setName] = useState("");
  const [Amount, setAmount] = useState("");
  const [Details, setDetails] = useState("");

  //redux dispatch
  const dispatch = useDispatch();

  const online = useSelector((state) => state.online.online);
  const User = useSelector((state) => state.auth.user);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const [Loading, setLoading] = useState(false);

  const ResetInputs = () => {
    setName("");
    setAmount("");
    setDetails("");
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let linker = Date.now();

    props.setInvoiceModal(false);

    await addLiveInvoice(
      {
        details: Details,
        linker,
        name: Name,
        amount: Amount,
        studentLinker: props.student.linker,
        instLinker: ActiveCred.linker,
        credLinker: ActiveCred.linker,
        live: 1,
        status: 0,
        trace: linker,
        deleted: 0,
      },
      ActiveCred.instLinker,
      User.token,
      dispatch
    );
    setLoading(false);

    ResetInputs();
  };

  return (
    <Modal
      show={props.InvoiceModal}
      onHide={() => {
        props.setInvoiceModal(false);
        ResetInputs();
      }}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Invoice {props.student.studentReg} {props.student.firstname}{" "}
          {props.student.lastname} {props.student.surname}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleSubmit}>
          <div className="row">
            <div className="col-md-12">
              <label> Invoice For : </label>
              <input
                className="form-control rounded"
                onChange={(e) => setName(e.target.value)}
                value={Name}
                placeholder="Enter Invoice Name"
                required
              />

              <hr />
            </div>
            <div className="col-md-12">
              <label>Invoice Amount : </label>
              <input
                className="form-control rounded"
                onChange={(e) => setAmount(e.target.value)}
                value={Amount}
                placeholder="Enter Invoice Amount"
                required
                type="number"
              />

              <hr />
            </div>
            <div className="col-md-12">
              <label>Invoice Details : </label>
              <textarea
                className="form-control rounded"
                onChange={(e) => setDetails(e.target.value)}
                value={Details}
                placeholder="Enter Invoice Details"
                required
              />

              <hr />
            </div>
          </div>

          <div className="d-flex justify-content-around mb-2">
            {Loading ? (
              <span className="spinner-border text-primary"></span>
            ) : (
              <Button variant="primary" type="submit">
                Save
              </Button>
            )}

            <Button
              variant="secondary"
              type="button"
              onClick={() => {
                props.setInvoiceModal(false);
                ResetInputs();
              }}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default StudentInvoice;
